import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { PageLayout } from '../../../mini-lib/layouts/PageLayout'
import { PageHeader } from '../../../mini-lib/page-header/PageHeader'
import React, { useEffect, useState } from 'react'
import { UseBuildSignupQueryParams, UseCanSeeScales, UseInitializeSignupState } from '../../signup/v1/signupHooks'
import {
  Box,
  Button,
  Divider,
  Flex,
  NumberDecrementStepper,
  NumberIncrementStepper,
  NumberInput,
  NumberInputField,
  NumberInputStepper,
  Text,
} from '@chakra-ui/react'
import { UseViewSize } from '../../../core/UseViewSize'
import { Gap } from '../../../mini-lib/gap/Gap'
import { BLACK_MINI_SCALE } from '../../../data/signup/constants'
import { selectScaleQuantities, selectScaleQuantitiesInStockForCountryById } from '../../../data/signup/slice'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../hooks'
import { dispatchListScaleQuantitiesInStock } from '../../../data/signup/api'
import { Loading } from '../../../mini-lib/loading/Loading'
import { EmptyBox } from '../../../mini-lib/empty/EmptyBox'
import { BlackMiniScaleAvailable } from '../../../mini-lib/flags/PermanentFlags'
import { generatePath, Link } from 'react-router-dom'
import { ROUTES } from '../../../appRoutes'
import { COLORS } from '../../../mini-lib/theme/colors'

export const CheckoutPaidScalesPage = () => {
  const { isMobile } = UseViewSize()
  const breadcrumbs = []
  const dispatch = useDispatch()
  const { user, salonId } = UseBaseApiParams()
  UseInitializeSignupState()

  useEffect(() => {
    dispatch(dispatchListScaleQuantitiesInStock({ token: user.token }))
  }, [dispatch, user.token])

  const scaleQuantitiesInStockById = useAppSelector(selectScaleQuantitiesInStockForCountryById)
  const blackMiniScalesAvailable = BlackMiniScaleAvailable()
  const hasBlackMiniScales = scaleQuantitiesInStockById
    ? blackMiniScalesAvailable && scaleQuantitiesInStockById[BLACK_MINI_SCALE.id]?.quantity > 0
    : null
  const canSeeScales = UseCanSeeScales()
  return (
    <>
      <PageLayout
        variant="full"
        header={<PageHeader title="" breadcrumbs={breadcrumbs} />}
        content={
          <Flex justify="space-around" direction="column" align="center">
            <Flex justify="start" w="100%">
              <Link
                to={{
                  pathname: generatePath(ROUTES.checkoutV1FreeScale, { salonId }),
                  search: UseBuildSignupQueryParams(),
                }}
              >
                <Button variant="round-outline" colorScheme="brand.midnight">
                  Back
                </Button>
              </Link>
            </Flex>
            {isMobile && <Gap/>}
            <Text align="center" variant={isMobile ? 'largetitle' : 'gigantictext'} color="brand.midnight.900">
              NEED ADDITIONAL SCALES?
            </Text>
            <Text>Purchase additional scales for your team</Text>
            <Flex
              justify={isMobile ? 'center' : 'space-between'}
              gridGap="24px"
              w={isMobile ? '100%' : ''}
              wrap={isMobile ? 'wrap' : 'nowrap'}
            >
              {!canSeeScales && (
                <EmptyBox title="No Scales Available" content="Scales are unavailable in your country" />
              )}
              {!scaleQuantitiesInStockById && <Loading />}
              {scaleQuantitiesInStockById && hasBlackMiniScales && <MiniScaleOption />}
            </Flex>
            <Gap />
            <Link
              to={{ pathname: generatePath(ROUTES.checkoutV1Cart, { salonId }), search: UseBuildSignupQueryParams() }}
            >
              <Button variant="round" w="100%" colorScheme="brand.midnight">
                Skip & Go to Review Order
              </Button>
            </Link>
          </Flex>
        }
      />
    </>
  )
}
export const MiniScaleOption = () => {
  const { salonId } = UseBaseApiParams()
  const scaleQuantities = useAppSelector(selectScaleQuantities)
  const scale = BLACK_MINI_SCALE
  const { isMobile } = UseViewSize()

  const initialQuantity = scaleQuantities[scale.id] || 0
  const [localQuantity, setLocalQuantity] = useState(initialQuantity)
  const scaleChange = (updatedQuantity: string) => {
    const quantityNumber = parseInt(updatedQuantity)
    if (!isNaN(quantityNumber)) {
      setLocalQuantity(quantityNumber)
    }
  }

  // const releaseSpecialPricing = ReleaseSpecialPricing()
  // const price = getScalePrice(releaseSpecialPricing)
  const [showProductDescription, setShowProductDescription] = useState(false)
  return (
    <Flex justify="center" mt="24px" wrap="wrap" w={isMobile ? '100%' : '1000px'}>
      <Flex direction="column" justify="center" border={`1px solid ${COLORS.shades_neutral_300}`} borderRadius="16px">
        <ScaleImg heightPx={'300px'} widthPx={'300px'} imgUrl={scale.imgUrl} />
        <Divider />
        <Box p="24px">
          <Box>
            <Text fontWeight="bold">Bluetooth {scale.label} Scale</Text>
            <Text fontSize='14px' color={COLORS.text_secondary}>$60.00</Text>
            <Box p="12px" fontSize='14px'>
              <Box>• Power supply - 2*AAAM</Box>
              <Box>• Graduation - 1gM</Box>
              <Box>• Scale Size - 200*140*20.5mmM</Box>
              {showProductDescription && (
                <>
                  <Box>• Display - LCDM</Box>
                  <Box>• Connection - BluetoothM</Box>
                  <Box>• Updated 2.0 sensorM</Box>
                </>
              )}
              {showProductDescription ? (
                <Text cursor='pointer' onClick={() => setShowProductDescription(false)} color={COLORS.lavender_500}>Hide details</Text>
              ) : (
                <Text cursor='pointer' onClick={() => setShowProductDescription(true)} color={COLORS.lavender_500}>View more details</Text>
              )}
            </Box>
            {/*<Text variant="callout-desktop-regular">Quantity</Text>*/}
            <NumberInput maxW={32} value={localQuantity} min={0} onChange={scaleChange}>
              <NumberInputField />
              <NumberInputStepper>
                <NumberIncrementStepper />
                <NumberDecrementStepper />
              </NumberInputStepper>
            </NumberInput>
          </Box>
          <Gap/>
          {/*<Divider />*/}
          {/*<Gap />*/}
          {/*<Flex align="center" justify="space-between">*/}
          {/*  <Text variant="title3">Order subtotal</Text>*/}
          {/*  <Text variant="title3">${localQuantity * price}</Text>*/}
          {/*</Flex>*/}
          {/*<Gap />*/}
          {/*<Divider />*/}
          {/*<Gap />*/}
          <Link
            to={{
              pathname: generatePath(ROUTES.checkoutV1Cart, { salonId }),
              search: UseBuildSignupQueryParams({ scaleQuantities: { blackmini: localQuantity } }),
            }}
          >
            <Button variant="round-outline" w="100%" colorScheme="brand.midnight">
              Add To Order
            </Button>
          </Link>
        </Box>
      </Flex>
    </Flex>
  )
}

export const ScaleImg = (props: { imgUrl: string; heightPx?: string; widthPx?: string }) => {
  const { isMobile } = UseViewSize()
  const { heightPx = isMobile ? '350px' : '500px', widthPx = '100%', imgUrl } = props
  return (
    <Box
      m="12px"
      h={heightPx}
      w={widthPx}
      maxW={'500px'}
      borderRadius="15px"
      backgroundSize="contain"
      backgroundPosition="center"
      backgroundRepeat="no-repeat"
      backgroundImage={`url(${imgUrl})`}
    />
  )
}
