import { GetServerBaseUrl } from '../../env'
import axios from 'axios'
import {APICreateUserLara, APIUser, LoggedInUser, LoggedInUserSalonPermissions, SalonRole} from '../user/interfaces'
import { mapAPIUserToLoggedInUser } from '../user/mappers'
import { APICreateUser, ScaleQuantity } from './interfaces'
import { Dispatch } from '@reduxjs/toolkit'
import { reduceLoggedInUser, reduceLoggedInUserSalonPermissions } from '../user/slice'
import { setLocalStorageItem } from '../../core/localStorage'
import { APICreateSalon } from '../salon/interfaces'
import { apiCreateSalon } from '../salon/api'
import { toast } from 'react-toastify'
import { extractErrorFromResponse } from '../../core/errors/utils'
import { reduceCreateSalon } from '../salon/slice'
import { launchChargebeePortal } from '../../integrations/chargebee/service'
import { APISubscriptionRequest } from '../../integrations/chargebee/interfaces'
import {
  apiAddRoleFromInvitationToken,
  apiCreateUserLara,
  apiUpdateSalonMemberRole,
  dispatchGetLoggedInUser, dispatchGetUserLara
} from '../user/api'
import { USER_ROLES } from '../user/constants'
import { UtmParams } from '../../integrations/utm'
import { GrinParams } from '../../integrations/grin/utils'
import { dispatchSetLoadingType } from "../../core/loading/api";
import { LOADING_SIGNUP, MOCK_SCALE_QUANTITIES } from "./constants";
import { createUpdateActiveCampaignContact } from "../../integrations/activecampagin/activecampagin";
import { reduceSetScaleQuantitiesInStock } from "./slice";

// note - when creating a user there will not be a saloncontext yet
export const apiCreateUser = (params: { request: APICreateUser }): Promise<LoggedInUser> => {
  const { request } = params
  const url = `${GetServerBaseUrl()}/sign-up/`
  return axios
    .post(url, request)
    .then((response: { data: { data: { token: string; user: APIUser } } }) => {
      return mapAPIUserToLoggedInUser(response.data.data.token, response.data.data.user)
    })
    .catch((error) => {
      throw error
    })
}

// note - when creating a user there will not be a saloncontext yet
export const apiInitializeSalonPermissions = (params: {
  token: string
  userId: number
  salonId: number
}): Promise<{}> => {
  const { token, userId, salonId } = params
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/users/${userId}?token=${token}`
  const body = {}
  return axios
    .post(url, body)
    .then((resp: any) => {
      return 'success'
    })
    .catch((error) => {
      return 'fail'
    })
}

export const apiListScaleQuantitiesInStock = (params: {
  token: string
}): Promise<ScaleQuantity[]> => {
  // const { token } = params
  // const config = buildLaraConfig({token})
  // const url = `${GetServerBaseUrl('v3', 'lara')}/scale-stock`
  return Promise.resolve(MOCK_SCALE_QUANTITIES)
}

export const dispatchStylistInviteSignup = (params: { request: APICreateUser, releaseStripe: boolean }) => {
  return (dispatch: Dispatch | any) => {
    if (params.releaseStripe) {
      const normalizedParams: APICreateUserLara = {
        email_address: params.request.email,
        first_name: params.request.first_name,
        last_name: params.request.last_name,
        lara_password: params.request.password,
        phone: params.request.phone,
      }
      return apiCreateUserLara({body: normalizedParams})
        .then((user) => {
          setLocalStorageItem('auth-token', user.token)
          setLocalStorageItem('user-id', user.userId)
          const invitationToken = params.request.invitation_token
          if (invitationToken) {
            apiAddRoleFromInvitationToken({userId: user.userId, token: user.token, invitationToken})
              .then(() => {
              // re-get the user with the new salon context
              dispatch(dispatchGetUserLara({token: user.token, userId: user.userId}))
            })
          }
        })
        .catch((err: any) => {
          let errorText = ''
          if (err?.response?.data?.error?.details === 'Email already exists') {
            errorText = 'Email already exists, try logging in instead!'
          } else if (err?.response?.data?.error?.details === 'Please enter a valid email address.') {
            errorText = 'Please enter a valid email address.'
          } else if (err?.response?.data?.error?.details?.invitation_token === 'Token has been expired or invalid') {
            errorText = 'Your invitation has expired or is invalid.'
          } else {
            errorText = 'Something went wrong, please try again or contact support'
          }
          toast.error(errorText)
          return err
        })
    } else {
      return apiCreateUser(params)
        .then((user) => {
          setLocalStorageItem('auth-token', user.token)
          setLocalStorageItem('user-id', user.userId)
          const salonId = user.currentSalonContext
            ? user.currentSalonContext.salonId
            : user.salonContexts && user.salonContexts.length > 0
            ? user.salonContexts[0].salonId
            : null
          if (salonId) {
            return apiUpdateSalonMemberRole({
              token: user.token,
              salonId,
              userToUpdateId: user.userId,
              roleName: USER_ROLES.stylist,
            }).then((resp: SalonRole) => {
              const permissions: LoggedInUserSalonPermissions = {
                id: resp.role.roleId,
                salonId: salonId,
                role: resp.role.roleName,
                permissions: resp.role.permissions,
              }
              dispatch(reduceLoggedInUser(user))
              dispatch(reduceLoggedInUserSalonPermissions(permissions))
            })
          }
        })
        .catch((err: any) => {
          let errorText = ''
          if (err?.response?.data?.error?.details === 'Email already exists') {
            errorText = 'Email already exists, try logging in instead!'
          } else if (err?.response?.data?.error?.details === 'Please enter a valid email address.') {
            errorText = 'Please enter a valid email address.'
          } else if (err?.response?.data?.error?.details?.invitation_token === 'Token has been expired or invalid') {
            errorText = 'Your invitation has expired or is invalid.'
          } else {
            errorText = 'Something went wrong, please try again or contact support'
          }
          toast.error(errorText)
          return err
        })
    }
  }
}
export const dispatchCreateUserAndSalonAndSubscription = (params: {
  userRequest: APICreateUser
  salonRequest: Partial<APICreateSalon>
  subscriptionRequest: APISubscriptionRequest
  utmParams: UtmParams
  grinParams: GrinParams
  allowSms: boolean
  isSpecialPricing: boolean
  canSeeScales: boolean
}) => {
  const { userRequest, salonRequest, subscriptionRequest, allowSms, utmParams, grinParams, isSpecialPricing, canSeeScales } = params
  return (dispatch: Dispatch | any) => {
    dispatch(dispatchSetLoadingType({name: LOADING_SIGNUP, state: true}))
    return apiCreateUser({ request: userRequest })
      .then((user: LoggedInUser) => {
        // create user
        dispatch(reduceLoggedInUser(user))
        setLocalStorageItem('auth-token', user.token)
        setLocalStorageItem('user-id', user.userId)

        createUpdateActiveCampaignContact({
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          phone: user.phone,
          utmParams,
          allowSms,
        }).then()
        // create salon
        const fullSalonRequest: APICreateSalon = {
          user_id: user.userId,
          salon_name: salonRequest.salon_name || '',
          salon_phone: salonRequest.salon_phone || '',
          instagram_handle: salonRequest.instagram_handle,
          booking_software: salonRequest.booking_software,
          timezone: salonRequest.timezone,
        }
        return apiCreateSalon({ token: user.token, request: fullSalonRequest })
          .then((salon) => {
            dispatch(reduceCreateSalon(salon))
            // after creating a salon call the user api again to get a user with a salon context
            // todo: replace with lara api
            dispatch(dispatchGetLoggedInUser({ token: user.token, userId: user.userId, salonId: salon.id }))
            setLocalStorageItem('auth-token', user.token)
            setLocalStorageItem('user-id', user.userId)
            // todo: once replaced with lara api this probably doesnt need to be called
            return apiInitializeSalonPermissions({ token: user.token, userId: user.userId, salonId: salon.id })
              .then(() => {
                launchChargebeePortal({
                  salonId: salon.id,
                  userId: user.userId,
                  token: user.token,
                  email: user.email,
                  firstName: user.firstName,
                  lastName: user.lastName,
                  phone: user.phone ? user.phone : '', // todo: make sure they have phone
                  salonscaleCustomerId: salon.customerId ? salon.customerId : -1, // todo: make sure they have customerId
                  salonType: subscriptionRequest.salonType,
                  subscriptionType: subscriptionRequest.subscriptionType,
                  freeScale: subscriptionRequest.selectedFreeScale,
                  scaleQuantities: subscriptionRequest.scaleQuantities,
                  heardAboutSalonscale: subscriptionRequest.heardAboutSalonscale,
                  heardAboutSalonscaleExtraDetails: subscriptionRequest.heardAboutSalonscaleExtraDetails,
                  utmParams,
                  grinParams,
                  isSpecialPricing,
                  canSeeScales
                })
                dispatch(dispatchSetLoadingType({name: LOADING_SIGNUP, state: false}))
                // todo: dispatch create hubspot contact
              })
              .catch((error) => {
                throw error
              })
          })
          .catch((error) => {
            throw error
          })

        // todo: dispatch create hubspot contact
      })
      .catch((error) => {
        toast.error(extractErrorFromResponse(error.response, 'Unable to create stylist'))
        throw error
      })
  }
}

export const dispatchCreateSalonAndSubscription = (params: {
  user: LoggedInUser
  salonRequest: Partial<APICreateSalon>
  subscriptionRequest: APISubscriptionRequest
  utmParams: UtmParams
  grinParams: GrinParams
  allowSms: boolean
  isSpecialPricing: boolean
  canSeeScales: boolean
}) => {
  const { user, salonRequest, subscriptionRequest, utmParams, grinParams, allowSms, isSpecialPricing, canSeeScales } = params
  return (dispatch: Dispatch | any) => {
    dispatch(dispatchSetLoadingType({name: LOADING_SIGNUP, state: true}))
    setLocalStorageItem('auth-token', user.token)
    setLocalStorageItem('user-id', user.userId)

    createUpdateActiveCampaignContact({
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      phone: user.phone,
      utmParams,
      allowSms
    }).then()
    // create salon
    const fullSalonRequest: APICreateSalon = {
      user_id: user.userId,
      salon_name: salonRequest.salon_name || '',
      salon_phone: salonRequest.salon_phone || '',
      instagram_handle: salonRequest.instagram_handle,
      booking_software: salonRequest.booking_software,
      timezone: salonRequest.timezone,
    }
    return apiCreateSalon({ token: user.token, request: fullSalonRequest })
      .then((salon) => {
        dispatch(reduceCreateSalon(salon))
        // after creating a salon call the user api again to get a user with a salon context
        // todo: replace with lara api
        dispatch(dispatchGetLoggedInUser({ token: user.token, userId: user.userId, salonId: salon.id }))
        setLocalStorageItem('auth-token', user.token)
        setLocalStorageItem('user-id', user.userId)
        // todo: once replaced with lara api this probably doesnt need to be called
        return apiInitializeSalonPermissions({ token: user.token, userId: user.userId, salonId: salon.id })
          .then(() => {
            launchChargebeePortal({
              salonId: salon.id,
              userId: user.userId,
              token: user.token,
              email: user.email,
              firstName: user.firstName,
              lastName: user.lastName,
              phone: user.phone ? user.phone : '', // todo: make sure they have phone
              salonscaleCustomerId: salon.customerId ? salon.customerId : -1, // todo: make sure they have customerId
              salonType: subscriptionRequest.salonType,
              subscriptionType: subscriptionRequest.subscriptionType,
              freeScale: subscriptionRequest.selectedFreeScale,
              scaleQuantities: subscriptionRequest.scaleQuantities,
              heardAboutSalonscale: subscriptionRequest.heardAboutSalonscale,
              heardAboutSalonscaleExtraDetails: subscriptionRequest.heardAboutSalonscaleExtraDetails,
              utmParams,
              grinParams,
              isSpecialPricing,
              canSeeScales
            })
            dispatch(dispatchSetLoadingType({name: LOADING_SIGNUP, state: false}))
          })
          .catch((error) => {
            throw error
          })
      })
      .catch((error) => {
        throw error
      })
  }
}


export const dispatchListScaleQuantitiesInStock = (params: {token: string}) => {
  return (dispatch: Dispatch) => {
    return apiListScaleQuantitiesInStock(params).then((resp) => {
      dispatch(reduceSetScaleQuantitiesInStock(resp))
    })
  }
}