import {Box, Flex, Tooltip} from '@chakra-ui/react'
import { MaterialIcon } from '../icons/MaterialIcon'
import {COLORS} from "../theme/colors";
import {ReleaseStripe} from "../flags/Release";
import {UseBaseApiParams} from "../../core/UseBaseApiParams";
import {getCurrentPaymentStatus} from "../../data/user/utils";
import {useEffect} from "react";
import {useDispatch} from "react-redux";
import {dispatchListSalonUsers} from "../../data/salon-user/api";
import {useAppSelector} from "../../hooks";
import {selectSalonUsers} from "../../data/salon-user/slice";

export const FeatureLocked = (props: {colorhex?: string}) => {
  const {colorhex = 'white'} = props
  const releasePaymentStatus = ReleaseStripe()
  if (!releasePaymentStatus) {
    return <></>
  }
  return (
    <Tooltip label="please upgrade your subscription to access this feature">
      <Box>
        <MaterialIcon size="16px" name={'lock'} colorhex={colorhex} />
      </Box>
    </Tooltip>
  )
}

export const SessionLimitBanner = () => {

  const {user} = UseBaseApiParams()
  const paymentStatus = getCurrentPaymentStatus(user)

  const sessionsInCurrentPaymentPeriod = paymentStatus?.limitIntervalSessionCount || 0
  const isCloseToLimit = paymentStatus && paymentStatus.sessionLimit !== null
    ? paymentStatus.sessionLimit - sessionsInCurrentPaymentPeriod <= 5
    : false

  const releasePaymentStatus = ReleaseStripe()

  if (!releasePaymentStatus) {
    return <></>
  }

  return (
    <>
      {isCloseToLimit && paymentStatus && (
        <Flex align='center' gridGap='4px' borderRadius='8px' bg={COLORS.lavender_100} p='12px'>
          <MaterialIcon name='warning' />
          {sessionsInCurrentPaymentPeriod}/{paymentStatus.sessionLimit} sessions created
        </Flex>
      )}
    </>
  )
}

export const TeamLimitBanner = () => {

  const {user, salonId} = UseBaseApiParams()
  const paymentStatus = getCurrentPaymentStatus(user)

  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(dispatchListSalonUsers({ token: user.token, salonId }))
  }, [dispatch, user.token, salonId])

  const salonUsers = useAppSelector(selectSalonUsers)

  const isCloseToLimit = salonUsers && paymentStatus && paymentStatus.teamLimit !== null
    ? paymentStatus.teamLimit - salonUsers.length <= 1
    : false

  const releasePaymentStatus = ReleaseStripe()

  if (!releasePaymentStatus) {
    return <></>
  }

  return (
    <>
      {isCloseToLimit && paymentStatus && salonUsers && (
        <Flex align='center' gridGap='4px' borderRadius='8px' bg={COLORS.lavender_100} p='12px'>
          <MaterialIcon name='warning' />
          {salonUsers.length}/{paymentStatus.teamLimit} team members created
        </Flex>
      )}
    </>
  )
}