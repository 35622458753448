import { Box, Button, Flex, Text } from "@chakra-ui/react";
import { SignupFormLayout } from "./SignupFormLayout";
import { Gap } from "../../../mini-lib/gap/Gap";
import { PasswordInput, PhoneInput, TextInput } from "../../../mini-lib";
import { Formik } from "formik";
import { buildFormikProps } from "../../../mini-lib/form/utils";
import React, { useState } from "react";
import * as Yup from "yup";
import { UseViewSize } from "../../../core/UseViewSize";
import { dispatchCreateUserLara } from "../../../data/user/api";
import { useDispatch } from "react-redux";
import { APICreateUserLara } from "../../../data/user/interfaces";
import { UseQueryParams } from "../../../mini-lib/utils/basic";
import { Redirect } from "react-router-dom";

import { ROUTES } from "../../../appRoutes";
import { UseUtmQueryParams } from "../../../integrations/utm";
import { stripNone } from "../../../core/collections/collections";
import { keys } from "lodash";
import { UseGeneratePathWithQPS } from "../../../mini-lib/router/router-utils";
import { Loading } from "../../../mini-lib/loading/Loading";
import { UseCurrentUser } from "../../../core/hooks/UseCurrentUser";
import {useAppSelector} from "../../../hooks";
import {selectLoadingState} from "../../../core/loading/slice";
import {CREATING_USER} from "../../../data/user/constants";

export const SignupCreateUserPage = () => {
  const queryParams = UseQueryParams()
  const subscriptionOption = queryParams.get('type')
  return (
    <SignupFormLayout>
      <Flex justify='center' align='center' direction='column'>
        <Gap/>
        <Text variant='title2-desktop-bold'>{subscriptionOption === 'trial' ? 'Try SalonScale for free' : 'Set up your SalonScale account'}</Text>
        <Gap s='6px'/>
        <Text variant='callout-desktop-regular'>{subscriptionOption === 'trial' ? 'No credit card required.' : ''}</Text>
        <Gap s='32px'/>
        <CreateUserForm/>
      </Flex>
    </SignupFormLayout>
  )
}

interface FormProps {
  firstName: string
  lastName: string
  email: string
  password: string
  phone: string
}

const CreateUserForm = () => {
  const dispatch = useDispatch()
  // todo-future: ensure that whatever navigates to the SignupCreateUserPage does so by preserving the utm query params
  const utmParams = UseUtmQueryParams()
  UseCurrentUser()
  const { isMobile } = UseViewSize()
  const queryParams = UseQueryParams()
  const dev = queryParams.get('dev')
  const {user, loadingUser} = UseCurrentUser()
  const isCreating = useAppSelector(state => selectLoadingState(state, CREATING_USER))
  const [submitted, setSubmitted] = useState(false)
  const today = new Date().toISOString().slice(0, 10)
  const redirectUrl = UseGeneratePathWithQPS({ pathname: ROUTES.signupV2Salon })
  const initialValues: FormProps = dev ? {
    firstName: 'dev',
    lastName: 'test',
    email: `devtest+${today}@salonscale.com`,
    password: 'Staging1234',
    phone: '3069004372',
  } : {
    firstName: '',
    lastName: '',
    email: '',
    password: '',
    phone: '',
  }
  const validationSchema = Yup.object({
    firstName: Yup.string().required('First name is required').min(2, 'You need at least 2 characters'),
    lastName: Yup.string().required('Last name is required').min(2, 'You need at least 2 characters'),
    email: Yup.string().email('Invalid email format').required('Email is required'),
    password: Yup.string().required('Password is required').min(8, 'You need at least 8 characters'),
    phone: Yup.string().required('Phone is required'),
  })
  const submitClicked = ( values: FormProps ) => {
    if (!submitted && !isCreating) {
      setSubmitted(true)
      const body: APICreateUserLara = {
        email_address: values.email,
        first_name: values.firstName,
        last_name: values.lastName,
        lara_password: values.password,
        phone: values.phone,
      }
      if (keys(stripNone(utmParams)).length > 0) {
        body['utm'] = stripNone(utmParams)
      }
      dispatch(dispatchCreateUserLara({ body }))
      setSubmitted(false)
    }

  }
  // todo: consider having a submit clicked state in addition to this
  if (user) {
    return <Redirect to={redirectUrl}/>
  }
  return (
    <>
      <Box w='100%'>
        <Formik onSubmit={submitClicked} initialValues={initialValues} validationSchema={validationSchema}>
          {( { handleSubmit, resetForm, ...formikParams } ) => {

            const firstNameParams = buildFormikProps({ name: 'firstName', formikParams })
            const lastNameParams = buildFormikProps({ name: 'lastName', formikParams })
            const emailParams = buildFormikProps({ name: 'email', formikParams })
            const phoneParams = buildFormikProps({ name: 'phone', formikParams })
            const passwordParams = buildFormikProps({ name: 'password', formikParams })
            return (
              <>
                <Box p='0 24px'>
                  <Flex justify='space-between' gridGap='24px'>
                    <TextInput
                      w={isMobile ? '100%' : '50%'}
                      variant='material'
                      label="First Name"
                      value={firstNameParams.value}
                      errorText={firstNameParams.errorText}
                      onBlur={firstNameParams.onBlur}
                      onChange={firstNameParams.onChange}
                    />
                    <TextInput
                      w={isMobile ? '100%' : '50%'}
                      variant='material'
                      label="Last Name"
                      value={lastNameParams.value}
                      errorText={lastNameParams.errorText}
                      onBlur={lastNameParams.onBlur}
                      onChange={lastNameParams.onChange}
                    />
                  </Flex>
                  <Gap/>
                  <PhoneInput
                    variant='material'
                    label='Mobile Phone Number'
                    value={phoneParams.value}
                    errorText={phoneParams.errorText}
                    onBlur={phoneParams.onBlur}
                    onChange={phoneParams.onChange}
                    onError={phoneParams.onError}
                  />
                  <Gap/>

                  <TextInput
                    variant='material'
                    label="Email"
                    value={emailParams.value}
                    errorText={emailParams.errorText}
                    onBlur={emailParams.onBlur}
                    onChange={emailParams.onChange}
                  />
                  <Gap/>


                  <PasswordInput
                    variant='material'
                    label="Password"
                    value={passwordParams.value}
                    onBlur={passwordParams.onBlur}
                    onChange={passwordParams.onChange}
                    errorText={passwordParams.errorText}
                  />
                </Box>
                <Gap/>


                <Box p='0 12px'>
                  <Button variant='round-large' colorScheme='brand.midnight' w='100%' onClick={() => handleSubmit()} cursor={loadingUser ? 'auto' : 'pointer'}>
                    {loadingUser || isCreating ? <Loading variant='clear'/> : 'Continue'}
                  </Button>
                </Box>
                <Gap s='32px'/>

              </>

            )
          }}
        </Formik>

      </Box>
    </>
  )
}
