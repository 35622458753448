import {COLORS} from "../theme/colors";
import React from "react";
import {Box} from "@chakra-ui/react";

export const BorderedBox = (props: {children: any}) => {
  return (
    <Box border={`1px solid ${COLORS.shades_neutral_300}`} borderRadius='8px' p='12px'>
      {props.children}
    </Box>
  )
}