import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '../../store'
import {PaymentFeature, PaymentStatus} from './interfaces'
import {orderBy} from "lodash";


interface PaymentStatusState {
  paymentStatus: PaymentStatus | null
  paymentFeatures: PaymentFeature[] | null
}

const initialState: PaymentStatusState = {
  paymentStatus: null,
  paymentFeatures: null,
}

// reducers
//
export const PaymentStatusSlice = createSlice({
  name: 'paymentStatus',
  initialState,
  reducers: {
    reducePaymentStatus: (state, action: PayloadAction<PaymentStatus>) => {
      state.paymentStatus = action.payload
    },
    reducePaymentFeatures: (state, action: PayloadAction<PaymentFeature[]>) => {
      state.paymentFeatures = action.payload
    },
  },
})

// actions
//
export const { reducePaymentStatus, reducePaymentFeatures } = PaymentStatusSlice.actions

// selectors
//
// note: moved to user.saloncontext
export const selectPaymentStatus = (state: RootState): PaymentStatus | null => {
  return state.paymentStatus.paymentStatus
}

export const selectPaymentFeatures = (state: RootState): PaymentFeature[] | null => {
  const property1Sorter = (item) => item.costCents
  const property2Sorter = (item) => item.name.toLowerCase()
  return state.paymentStatus.paymentFeatures
    ? orderBy(
        state.paymentStatus.paymentFeatures,
        [property1Sorter, property2Sorter],
        ['desc', 'asc'],
      )
    : null
}

export default PaymentStatusSlice.reducer
