import React, {ReactNode} from "react";
import {ThemeType} from "../../../theme";
import {StripeBillingInterval, StripePlanType} from "../../../integrations/stripe/constants";
import {useDispatch} from "react-redux";
import {UseViewSize} from "../../../core/UseViewSize";
import {UseBaseApiParams} from "../../../core/UseBaseApiParams";
import {getEnvConfig} from "../../../config";
import {APIStripeCheckout} from "../../../integrations/stripe/interfaces";
import {dispatchStripeCheckout} from "../../../integrations/stripe/api";
import {COLORS} from "../../../mini-lib/theme/colors";
import {Box, Button, Divider, Flex, Text} from "@chakra-ui/react";
import {Gap} from "../../../mini-lib/gap/Gap";
import {MaterialIcon} from "../../../mini-lib/icons/MaterialIcon";
import {getCountryCode} from "../../../mini-lib/time/utils";
import {ROUTES} from "../../../appRoutes";
import {generatePath} from "react-router-dom";
import {useAppSelector} from "../../../hooks";
import {selectLoadingState} from "../../../core/loading/slice";
import {Loading} from "../../../mini-lib/loading/Loading";

export const CheckoutSubscriptionCard = (props: {
  topTitle: string
  title: string
  bottomTitle?: string
  bottomSubtitle?: string
  price: ReactNode | string
  priceSubtitle: string | any
  bulletPoints: any[]
  priceBulletPoints: any[]
  theme: ThemeType | string
  isSelected: boolean
  billingInterval: StripeBillingInterval
  planType: StripePlanType
  minH: string
  showButton: boolean
  isMostPopular: boolean
}) => {
  const dispatch = useDispatch()
  const { isMobile } = UseViewSize()
  const {
    minH = 'auto',
    topTitle,
    title,
    bottomTitle,
    bottomSubtitle,
    price,
    priceSubtitle,
    bulletPoints,
    priceBulletPoints,
    theme,
    isSelected,
    billingInterval,
    planType,
    showButton = true,
    isMostPopular = false,
  } = props

  const { user, salonId } = UseBaseApiParams()
  const config = getEnvConfig()
  const countryCode = getCountryCode()
  const isCad = countryCode === 'CA'
  const loadingId = `loading-${title}`
  const loadingStripe = useAppSelector(state => selectLoadingState(state, loadingId))
  const onSubscriptionSelect = () => {
    // call stripe portal from here
    //
    const body: APIStripeCheckout = {
      plan: planType,
      billing_frequency: billingInterval,
      currency: isCad ? 'cad' : 'usd',
      success_url: `${config.reactHost}/signup-success`,
      cancel_url: `${config.reactHost}${generatePath(ROUTES.checkoutV2Subscription, { salonId })}`,
    }
    dispatch(dispatchStripeCheckout({ token: user.token, salonId, userId: user.userId, body, loadingId }))
  }
  const themeColor = theme === 'midnight' ? COLORS.midnight_900 : COLORS.lavender_500
  return (
    <Box w={isMobile ? '100%' : '270px'} position="relative">
      {topTitle !== '' && <TopText text={topTitle} theme={theme} />}

      <Flex
        bg={COLORS.shades_neutral_0}
        w="100%"
        direction="column"
        borderRadius={'15px'}
        borderWidth={'1px'}
        color={COLORS.midnight_900}
        borderColor={isSelected ? themeColor : `shades.neutral.500`}
        minH={minH}
      >
        <Box p="24px" borderRadius={'15px 15px 0 0'} bg={isMostPopular ? COLORS.lavender_50 : ''}>
          <Gap s="12px" />
          <Text variant={isMobile ? 'title2' : 'title1'}>
            {title}
          </Text>

          <Gap />

          <Text variant="largetitle">{price}</Text>

          <Text h="24px" color={COLORS.text_secondary} fontSize="11px">
            {priceSubtitle}
          </Text>

          <Gap s="12px" />

          <Box variant={isMobile ? 'footnote' : ''} w="90%">
            {priceBulletPoints.map((point: any, index) => {
              return <Box key={index}>{point}</Box>
            })}
          </Box>

          <Gap s="12px" />

          {showButton && (
            <>
              <Gap s="12px" />
              <Button width="100%" variant="round" colorScheme="brand.lavender" onClick={onSubscriptionSelect}>
                {loadingStripe ? <Loading/> : `choose ${title}`}
              </Button>
            </>
          )}

          <Gap s="12px" />

        </Box>

        <Divider />

        <Box p='24px'>

          <Box variant={isMobile ? 'footnote' : ''} w="90%">
            {bulletPoints.map((point: any, index) => {
              return <Box key={index}>{point}</Box>
            })}
          </Box>
        </Box>

        {bottomTitle && (
          <Box bg={COLORS.lavender_50} w="100%" borderRadius="0 0 15px 15px" textAlign="center" p="24px">
            <Text fontWeight="bold">{bottomTitle}</Text>
            <Text fontStyle="italic" fontSize="11px">
              {bottomSubtitle}
            </Text>
          </Box>
        )}
      </Flex>
    </Box>
  )
}

export const Bullet = (props: { text: string; bold?: boolean; iconName?: string | null; textColor?: string }) => {
  const { text, bold, iconName = 'check', textColor = 'black' } = props
  return (
    <Flex gridGap="8px" align="center" p="2px 0" fontSize="11px" fontWeight={bold ? 'bold' : ''} color={textColor}>
      {iconName && iconName === 'dot' && '• '}
      {iconName && iconName !== 'dot' && <MaterialIcon size="16px" name={iconName} />}
      {text}
    </Flex>
  )
}
export const TopText = (props: { text: string; theme: ThemeType | string }) => {
  const { theme, text } = props
  const themeColor = theme === 'midnight' ? COLORS.midnight_900 : COLORS.lavender_500
  return (
    <Flex position="absolute" top="20px" w="100%" justify='flex-end'>
      <Flex
        h="32px"
        w="110px"
        bg={themeColor}
        color={'white'}
        justify="center"
        align="center"
        borderRadius="4px 0 0 4px"
        fontWeight="bold"
        fontSize="12px"
      >
        {text}
      </Flex>
    </Flex>
  )
}
