import {APISessionMetaPatchLara, SessionMetaLara} from "../../../data/sessions/interfaces";
import React, {useState} from "react";
import {
  Drawer, DrawerBody,
  DrawerCloseButton,
  DrawerContent, DrawerHeader,
  DrawerOverlay,
  Flex,
  Text,
  Textarea
} from "@chakra-ui/react";
import {MaterialIcon} from "../../../mini-lib/icons/MaterialIcon";
import {COLORS} from "../../../mini-lib/theme/colors";
import {useDispatch} from "react-redux";
import {UseViewSize} from "../../../core/UseViewSize";
import {useAppSelector} from "../../../hooks";
import {
  reduceIsNoteSheetVisible,
  selectIsNoteSheetVisible
} from "../../../data/sessions/slice";
import {Gap} from "../../../mini-lib/gap/Gap";
import {DisableAutofocus} from "../../../mini-lib/disable-autofocus/DisableAutofocus";
import {dispatchUpdateClient} from "../../../data/clients/api";
import {UseBaseApiParams} from "../../../core/UseBaseApiParams";


export const SessionNotesSheet = (props: {sessionMeta: SessionMetaLara, patchSessionMeta: (props: APISessionMetaPatchLara) => void }) => {
  const {sessionMeta, patchSessionMeta} = props
  const dispatch = useDispatch()
  const { isMobile } = UseViewSize()

  const isVisible = useAppSelector(selectIsNoteSheetVisible)

  const onClose = () => {
    dispatch(reduceIsNoteSheetVisible(false))
  }

  return (
    <Drawer
      size={isMobile ? 'xs' : 'md'}
      isOpen={isVisible}
      placement="right"
      onClose={onClose}
    >
      <DrawerOverlay />
      <DrawerCloseButton />
      <DrawerContent>
        <DrawerHeader>
          <Flex justify="space-between" align="center" pr='12px'>
            <MaterialIcon
              cursor="pointer"
              colorhex={COLORS.lavender_500}
              size="36px"
              name="chevron_left"
              onClick={onClose}
            />
            <Text fontWeight='bold' fontSize='20px'>Edit Notes</Text>
          </Flex>
        </DrawerHeader>
        <DrawerBody>
          <DisableAutofocus />
          <SessionNotes sessionMeta={sessionMeta} patchSessionMeta={patchSessionMeta} variant='drawer'/>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export const SessionNotes = (props: {
  sessionMeta: SessionMetaLara | null,
  patchSessionMeta: (props: APISessionMetaPatchLara) => void,
  variant: 'box' | 'drawer'
}) => {
  const { sessionMeta, patchSessionMeta, variant } = props
  const dispatch = useDispatch()
  const {salonId, user} = UseBaseApiParams()
  const [notes, setNotes] = useState(sessionMeta?.notes || '')
  const [clientNotes, setClientNotes] = useState(sessionMeta?.client.notes || '')
  const editable = variant === 'drawer'
  const updateClientNotes = (notes: string) => {
    if (sessionMeta) {
      const updatedModel = {
        id: sessionMeta.client.id,
        firstName: sessionMeta.client.firstName,
        lastName: sessionMeta.client.lastName,
        notes,
      }
      dispatch(dispatchUpdateClient({
        token: user.token,
        client_id: sessionMeta.client.id,
        salon_id: salonId,
        model: updatedModel
      }))
    }
  }
  return (
    <Flex direction="column" borderRadius="8px" border={variant === 'drawer' ? '' : `1px solid ${COLORS.shades_neutral_300}`} p="12px">
      <Flex justify="space-between" align="center">
          {!editable && <Text fontWeight="bold">All Notes</Text>}
          {variant === 'box' && (
            <Text
              color={COLORS.lavender_500}
              onClick={() => dispatch(reduceIsNoteSheetVisible(true))}
              cursor='pointer'
            >
              edit
            </Text>
          )}
        </Flex>

      <Gap s='12px'/>

      {editable && (
        <>
          <Text fontSize='14px' fontWeight='bold'>Session Notes</Text>
          <Text fontSize='14px' color={COLORS.text_secondary}>notes for just this session</Text>
          <Gap s='12px'/>
          <Textarea
            colorScheme="brand.lavender"
            value={notes}
            onChange={(e) => setNotes(e.target.value)}
            onBlur={ () => patchSessionMeta({ notes })}
          />

          <Gap s='32px'/>

          <Text fontSize='14px' fontWeight='bold'>Client Notes</Text>
          <Text fontSize='14px' color={COLORS.text_secondary}>notes that appear across all sessions with this client</Text>
          <Gap s='12px'/>
          <Textarea
            colorScheme="brand.lavender"
            value={clientNotes}
            onChange={(e) => setClientNotes(e.target.value)}
            onBlur={ () => updateClientNotes(clientNotes)}
          />
        </>
      )}
      {!editable && (
        <>
          {sessionMeta?.notes && (
            <>
              <Gap s='12px'/>
              <Text fontSize='14px' fontWeight='bold'>Session Notes</Text>
              <Text>{sessionMeta?.notes}</Text>
            </>
          )}

          <Gap/>


          {sessionMeta?.client?.notes && (
            <>
              <Gap s='12px'/>
              <Text fontSize='14px' fontWeight='bold'>Client Notes</Text>
              {sessionMeta?.client?.notes && <Text whiteSpace='pre-wrap'>{sessionMeta.client.notes}</Text>}
            </>
          )}
        </>
      )}
    </Flex>
  )
}
