import { Dispatch } from '@reduxjs/toolkit'

import {
  reducePaymentFeatures,
  reducePaymentStatus
} from './slice'

import {APIPaymentStatus, APIStripeOrder, PaymentFeature, PaymentStatus} from "./interfaces";
import {buildLaraConfig} from "../../mini-lib/lara/lara-utils";
import {GetServerBaseUrl} from "../../env";
import axios, {AxiosResponse} from "axios";
import {mapAPIPaymentStatusToPaymentStatus, mapAPIStripeOrdersToPaymentFeatures} from "./mappers";


export const apiGetPaymentStatus = (params: {
  token: string,
  salonId: number,
}): Promise<PaymentStatus> => {
  const {token, salonId } = params
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/payment-status`
  return axios
    .get(url, config)
    .then((response: AxiosResponse<{ data: APIPaymentStatus }>) => {
      return mapAPIPaymentStatusToPaymentStatus(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const dispatchGetPaymentStatus = (params: {
  token: string,
  salonId: number,
}) => {
  return (dispatch: Dispatch) => {
    return apiGetPaymentStatus(params).then((resp) => {
      dispatch(reducePaymentStatus(resp))
    })
  }
}


export const apiListPaymentFeatures = (params: {
  token: string,
  userId: number,
}): Promise<PaymentFeature[]> => {

  // todo: attach this once server apis are out
  const {token, userId } = params
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl('v3', 'lara')}/users/${userId}/stripe-orders`
  return axios
    .get(url, config)
    .then((response: AxiosResponse<{ data: APIStripeOrder[] }>) => {
      return mapAPIStripeOrdersToPaymentFeatures(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const dispatchListPaymentFeatures = (params: {
  token: string,
  userId: number,
}) => {
  // todo: attach this once server apis are out
  return (dispatch: Dispatch) => {
    return apiListPaymentFeatures(params).then((resp) => {
      dispatch(reducePaymentFeatures(resp))
    })
  }
}
