

export const getPaymentFeatureIconName = (name: string): string | null => {
  const normalizedName = name.toLowerCase()
  if (normalizedName.includes('scale')) {
    return 'scale'
  }
  if (normalizedName.includes('implementation')) {
    return 'implementationService'
  }
  return null
}

export const getLogoIconNameFromPaymentStatus = (name: string): string | null  => {
  const normalizedName = name.toLowerCase()
  if (normalizedName === 'luxe') {return 'logoLuxe'}
  if (normalizedName === 'signature') {return 'logoSignature'}
  if (normalizedName === 'essentials') {return 'logoEssentials'}
  return 'logoSignature'
}