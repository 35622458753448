import { Dispatch } from '@reduxjs/toolkit'
import {
  APICreateSalon,
  APICreateSalonLara,
  APILaraPatchSalon,
  APILaraSalon,
  APIUpdateSalon,
  Salon
} from './interfaces'
import { GetServerBaseUrl } from '../../env'
import axios from 'axios'
import { mapAPILaraSalonsToSalons, mapAPILaraSalonToSalon, mapAPISalonToSalon } from './mappers'
import { reduceListSalons, reduceSalonContext, reduceUpdateSalon } from './slice'
import { stripNone } from '../../core/collections/collections'
import { LoggedInUserSalonContext } from '../user/interfaces'
import { reduceUpdateLoggedInUserCurrentSalonContext } from '../user/slice'
import { assign } from 'lodash'
import { buildLaraConfig } from '../../mini-lib/lara/lara-utils'
import { reduceSetLoadingState } from "../../core/loading/slice";
import { LOADING_SALON } from "./constants";
import { dispatchGetUserLara } from '../user/api'
import { dispatchSyncUserSalonToPipedrive } from "../../integrations/pipedrive/api";

export const apiCreateSalon = (params: { token: string; request: APICreateSalon }): Promise<Salon> => {
  const { token, request } = params
  const url = `${GetServerBaseUrl()}/salons/`
  const body = {
    token,
    ...request,
  }
  return axios
    .post(url, body)
    .then((response: any) => {
      return mapAPISalonToSalon(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiUpdateSalon = (params: {
  token: string
  userId: number
  salonId: number
  request: APIUpdateSalon
}): Promise<Salon> => {
  const { token, userId, salonId, request } = params
  const url = `${GetServerBaseUrl()}/salons/${salonId}/?token=${token}&user_id=${userId}`
  const body = stripNone(request)
  return axios
    .put(url, body)
    .then((response: any) => {
      return mapAPISalonToSalon(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiCreateSalonLara = (params: { token: string, request: APICreateSalonLara }): Promise<Salon> => {
  const { token, request } = params
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/`
  const body = {
    token,
    ...request,
  }
  const config = buildLaraConfig({ token })
  return axios
    .post(url, body, config)
    .then((resp: {data: {data: APILaraSalon}}) => {
      return mapAPILaraSalonToSalon(resp.data.data)
    })
    .catch((error) => {
      throw error
    })
}
export const apiLaraPatchSalon = (params: {
  token: string
  userId: number
  salonId: number
  request: APILaraPatchSalon
}): Promise<Salon> => {
  const { token, salonId, request } = params
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/`
  const body = stripNone(request)
  return axios
    .patch(url, body, config)
    .then((response: any) => {
      return mapAPILaraSalonToSalon(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiLaraGetSalon = (params: { token: string; userId: number; salonId: number }): Promise<Salon> => {
  const { token, salonId } = params
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/`
  return axios
    .get(url, config)
    .then((response: any) => {
      return mapAPILaraSalonToSalon(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiLaraListSalons = (params: { token: string; userId: number }): Promise<Salon[]> => {
  const { token, userId } = params
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl('v3', 'lara')}/users/${userId}/salons`
  return axios
    .get(url, config)
    .then((response: any) => {
      return mapAPILaraSalonsToSalons(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const dispatchUpdateSalon = (params: {
  token: string
  userId: number
  salonId: number
  currentSalonContext: LoggedInUserSalonContext | null
  request: APIUpdateSalon
}) => {
  return (dispatch: Dispatch | any) => {
    return apiUpdateSalon(params)
      .then((resp) => {
        const updatedSalonContext = assign({}, params.currentSalonContext, {
          timezone: resp.timezone,
          salonName: resp.name,
        })
        dispatch(reduceUpdateLoggedInUserCurrentSalonContext(updatedSalonContext))
        dispatch(reduceUpdateSalon(resp))
      })
      .catch((error) => {
        throw error
      })
  }
}

export const dispatchLaraPatchSalon = (params: {
  token: string
  userId: number
  salonId: number
  currentSalonContext: LoggedInUserSalonContext | null
  request: APILaraPatchSalon,
  syncToPipedrive: boolean
}) => {
  return (dispatch: Dispatch | any) => {
    return apiLaraPatchSalon(params)
      .then((resp) => {
        const updatedSalonContext = assign({}, params.currentSalonContext, {
          timezone: resp.timezone,
          salonName: resp.name,
          photoUrl: resp.photoUrl,
        })
        dispatch(reduceUpdateLoggedInUserCurrentSalonContext(updatedSalonContext))
        dispatch(reduceUpdateSalon(resp))
        if (params.syncToPipedrive) {
          // call pipedrive to sync user and salon
          dispatch(dispatchSyncUserSalonToPipedrive({token: params.token, userId: params.userId, salonId: params.salonId}))
        }
      })
      .catch((error) => {
        throw error
      })
  }
}

export const dispatchLaraGetSalon = (params: { token: string; userId: number; salonId: number }) => {
  return (dispatch: Dispatch | any) => {
    dispatch(reduceSetLoadingState({ name: LOADING_SALON, state: true }))
    return apiLaraGetSalon(params)
      .then((resp) => {
        dispatch(reduceSalonContext(resp))
        dispatch(reduceSetLoadingState({ name: LOADING_SALON, state: false }))
      })
      .catch((error) => {
        throw error
      })
  }
}
export const dispatchLaraListSalons = (params: { token: string; userId: number }) => {
  return (dispatch: Dispatch | any) => {
    return apiLaraListSalons(params)
      .then((resp) => {
        dispatch(reduceListSalons(resp))
      })
      .catch((error) => {
        throw error
      })
  }
}

export const dispatchLaraCreateSalon = (params: { token: string, request: APICreateSalonLara }) => {
  return (dispatch: Dispatch | any) => {
    dispatch(reduceSetLoadingState({ name: LOADING_SALON, state: true }))
    return apiCreateSalonLara(params)
      .then((resp) => {
        // the create salon api call also creates the permissions and updates the user context
        // re-get the user to get all of the changes
        dispatch(dispatchGetUserLara({ token: params.token, userId: params.request.user_id, salonId: resp.id }))
        dispatch(reduceSetLoadingState({ name: LOADING_SALON, state: false }))
      })
      .catch((error) => {
        throw error
      })
  }
}
