import { map } from 'lodash'
import { APIClient, APIClientPhoto, APIMergeableClient, Client, ClientPhoto } from './interfaces'

export const mapAPIClientToClient = (api: APIClient): Client => {
  return {
    id: api.id || -1,
    firstName: api.first_name,
    lastName: api.last_name,
    phone: api.phone,
    notes: api.notes
  }
}
export const mapAPIClientsToClients = (apiModels: APIClient[]): Client[] => {
  return map(apiModels, mapAPIClientToClient)
}

export const mapClientToAPIClient = (model: Partial<Client>): APIClient => {
  const api = {
    first_name: model.firstName || '',
    middle_name: null,
    last_name: model.lastName || '',
    phone: model.phone || '',
    notes: model.notes || ''
  }
  if (model.id !== undefined) {
    api['id'] = model.id
  }
  return api
}
export const mapClientsToAPIClients = (models: Partial<Client>[]): APIClient[] => {
  return map(models, mapClientToAPIClient)
}

export const mapAPIMeargeableClientToClient = (api: APIMergeableClient): Client => {
  return {
    id: api.id,
    firstName: api.first_name,
    lastName: api.last_name,
    phone: api.phone,
  }
}
export const mapAPIMergeableClientsToClients = (apiModels: APIMergeableClient[]): Client[] => {
  return map(apiModels, mapAPIMeargeableClientToClient)
}
export const mapAPIClientPhotoToClientPhoto = (api: APIClientPhoto): ClientPhoto => {
  return {
    id: api.id,
    photoUrl: api.photo_url,
    photoTag: api.photo_tag,
    sessionId: api.session_id,
    lastUpdatedDate: api.last_updated_date,
  }
}
export const mapAPIClientPhotosToClientPhotos = (models: APIClientPhoto[]): ClientPhoto[] => {
  return map(models, mapAPIClientPhotoToClientPhoto)
}
