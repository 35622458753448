import { SessionMetaLara } from '../../../data/sessions/interfaces'
import { useDispatch } from 'react-redux'
import React from 'react'
import { useAppSelector } from '../../../hooks'
import {
  reduceIsSupplySheetVisible,
  reduceSelectedLaborId,
  selectActiveSessionSupplies,
} from '../../../data/sessions/slice'
import {Box, Button, Divider, Flex, Text} from '@chakra-ui/react'
import { COLORS } from '../../../mini-lib/theme/colors'
import { SESSION_STATUS_OPTIONS } from '../../../data/sessions/constants'

import { formatCentsToDollars } from '../../../mini-lib/units/money'
import {Gap} from "../../../mini-lib/gap/Gap";
import {Loading} from "../../../mini-lib/loading/Loading";
import {getSessionSuppliesLaraTotalCost} from "../../../data/sessions/utils";

export const SessionSupplies = (props: { sessionMeta: SessionMetaLara; laborId: number | null, isClickable?: false }) => {
  const dispatch = useDispatch()
  const { sessionMeta, laborId, isClickable } = props

  // lara session apis
  //
  //
  const sessionSupplies = useAppSelector((state) => selectActiveSessionSupplies(state, laborId))

  //
  //
  //////

  const supplyTotal = sessionSupplies ? getSessionSuppliesLaraTotalCost(sessionSupplies) : null
  return (
    <>
      {sessionSupplies && sessionSupplies.length > 0 && (
        <>
          <Gap s="12px" />
          <Flex
            cursor={sessionMeta.status === 'open' ? 'pointer' : ''}
            direction="column"
            borderRadius="8px"
            border={`1px solid ${COLORS.shades_neutral_300}`}
            onClick={() => {
              if (sessionMeta.status === 'open') {
                dispatch(reduceIsSupplySheetVisible(true))
                dispatch(reduceSelectedLaborId(laborId))
              }
            }}
          >
            <Flex p="12px 12px 0 12px" justify="space-between">
              <Text fontWeight="bold">
                Add-Ons
              </Text>
              <Text fontWeight="bold">{supplyTotal ? '$' + formatCentsToDollars(supplyTotal) : ''}</Text>
            </Flex>
            <Gap s='8px'/>
            <Box p='0 12px'><Divider /></Box>
            <Gap s='12px'/>
            {sessionSupplies?.map((supply) => {
              return (
                <Box
                  p="0 12px 0 12px"
                  key={supply.id}
                  onClick={() => {
                    if (isClickable && sessionMeta.status === SESSION_STATUS_OPTIONS.open) {
                      dispatch(reduceIsSupplySheetVisible(true))
                      dispatch(reduceSelectedLaborId(laborId))
                    }
                  }}
                  cursor={isClickable && sessionMeta.status === SESSION_STATUS_OPTIONS.open ? 'pointer' : ''}
                >
                  <Text color="text.secondary" fontSize='12px'>
                    {supply.name} (qty {supply.quantity})
                  </Text>
                </Box>
              )
            })}
            <Gap s="12px" />
          </Flex>
          <Gap s="12px" />
        </>
      )}
      {!sessionSupplies && <Loading />}
    </>
  )
}

export const AddSessionSupplies = (props: { sessionMeta: SessionMetaLara; laborId: number | null }) => {
  const dispatch = useDispatch()
  const { sessionMeta, laborId } = props
  return (
    <>
      {sessionMeta.status === SESSION_STATUS_OPTIONS.open && (
        <Button
          variant='grey'
          onClick={() => {
            dispatch(reduceIsSupplySheetVisible(true))
            dispatch(reduceSelectedLaborId(laborId))
          }}
        >
          + Add Add-ons
        </Button>
      )}
    </>
  )
}
