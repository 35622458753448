import React from 'react'
import { Box, Divider, Flex, Text } from '@chakra-ui/react'
import { useAppSelector } from '../../../hooks'
import { selectSessionCostSummary } from '../../../data/sessions/slice'
import { COLORS } from '../../../mini-lib/theme/colors'
import { Gap } from '../../../mini-lib/gap/Gap'
import { convertDisplayUnits } from '../../../data/sessions/utils'
import { formatCentsToDollars } from '../../../mini-lib/units/money'
import { reduce } from 'lodash'

export const SessionCostSummary = () => {
  const summary = useAppSelector(selectSessionCostSummary)
  const hasWaste = reduce(summary?.wastes, (total, waste) => total + (waste?.wasteTotalGrams || 0), 0)
  return (
    <>
      <Flex direction="column" borderRadius="8px" border={`1px solid ${COLORS.shades_neutral_300}`} p="12px">
        <Text fontWeight="bold" fontSize="20px">
          Cost Summary
        </Text>
        {summary?.labors.map((labor, index) => {
          return (
            <Box key={index}>
              <Gap />
              <Text fontWeight="bold">{labor.name}</Text>
              <Gap s="12px" />
              {labor.colorTotalCents !== null && (
                <Flex align="center" justify="space-between" p="4px 0">
                  <Text color={COLORS.text_secondary}>
                    Color({convertDisplayUnits(labor.colorGrams || 0, 'g', labor.colorUnits || 'g')}
                    {labor.colorUnits})
                  </Text>
                  <Text>${formatCentsToDollars(labor.colorTotalCents)}</Text>
                </Flex>
              )}

              {labor.supplyTotalCents !== null && (
                <Flex align="center" justify="space-between" p="4px 0">
                  <Text color={COLORS.text_secondary}>Add-Ons</Text>
                  <Text>${formatCentsToDollars(labor.supplyTotalCents)}</Text>
                </Flex>
              )}

              {labor.extensionsTotalCents !== null && (
                <Flex align="center" justify="space-between" p="4px 0">
                  <Text color={COLORS.text_secondary}>Extensions</Text>
                  <Text>${formatCentsToDollars(labor.extensionsTotalCents)}</Text>
                </Flex>
              )}
            </Box>
          )
        })}
        <Gap />
        <Divider />
        <Gap />
        <Flex align="center" justify="space-between" p="4px 0">
          <Text color={COLORS.text_secondary}>Total color used</Text>
          <Text>${summary ? formatCentsToDollars(summary.sessionColorTotalCents) : ''}</Text>
        </Flex>

        <Flex align="center" justify="space-between" p="4px 0">
          <Text color={COLORS.text_secondary}>Total supplies used</Text>
          <Text>${summary ? formatCentsToDollars(summary.sessionSupplyTotalCents) : ''}</Text>
        </Flex>

        <Flex align="center" justify="space-between" p="4px 0">
          <Text color={COLORS.text_secondary}>Total extensions used</Text>
          <Text>${summary ? formatCentsToDollars(summary.sessionExtensionTotalCents) : ''}</Text>
        </Flex>

        <Gap />

        <Flex align="center" justify="space-between" p="4px 0">
          <Text fontWeight="bold">Total Costs</Text>
          <Text fontWeight="bold">${summary ? formatCentsToDollars(summary.sessionTotalCents) : ''}</Text>
        </Flex>
      </Flex>

      <Gap />

      {!!hasWaste && (
        <Flex direction="column" borderRadius="8px" border={`1px solid ${COLORS.shades_neutral_300}`} p="12px">
          <Text fontWeight="bold" fontSize="20px">
            Waste Summary
          </Text>
          {summary?.wastes.map((labor, index) => {
            return (
              <Box key={index}>
                <Gap />
                <Text fontWeight="bold">{labor.name}</Text>
                <Gap s="12px" />
                {labor.wasteTotalCents !== null && (
                  <Flex align="center" justify="space-between" p="4px 0">
                    <Text color={COLORS.text_secondary}>
                      Color({convertDisplayUnits(labor.wasteTotalGrams || 0, 'g', labor.wasteUnits || 'g')}
                      {labor.wasteUnits})
                    </Text>
                    <Text>${formatCentsToDollars(labor.wasteTotalCents)}</Text>
                  </Flex>
                )}
              </Box>
            )
          })}
        </Flex>
      )}
    </>
  )
}
