import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Text,
} from '@chakra-ui/react'
import React, { useEffect, useState } from 'react'
import { useAppSelector } from '../../../hooks'
import { useDispatch } from 'react-redux'
import { UseViewSize } from '../../../core/UseViewSize'
import {
  reduceIsLaborsSheetVisible,
  selectActiveSessionLabors,
  selectIsLaborsSheetVisible,
} from '../../../data/sessions/slice'
import { MaterialIcon } from '../../../mini-lib/icons/MaterialIcon'
import { DisableAutofocus } from '../../../mini-lib/disable-autofocus/DisableAutofocus'
import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { useParams } from 'react-router-dom'
import { EmptyBox } from '../../../mini-lib/empty/EmptyBox'
import {find, orderBy} from 'lodash'
import { Loading } from '../../../mini-lib/loading/Loading'
import {ConfirmPopover} from '../../../mini-lib'
import { convertSecondsToHoursMinutes } from '../../../mini-lib/form/TimeInput'
import { LaborSelectSheet } from './LaborSelectSheet'
import { APIUpsertSessionLabor, SessionLabor } from '../../../data/sessions/session-labor/interfaces'
import { dispatchDeleteSessionLabors, dispatchUpsertSessionLabors } from '../../../data/sessions/session-labor/api'
import { Labor } from '../../../data/labor/interfaces'
import {selectLoadingState} from "../../../core/loading/slice";
import {
  LOADING_SESSION_LABOR_DELETE,
  LOADING_SESSION_LABOR_INDIVIDUAL_UPSERT,
  LOADING_SESSION_LABOR_UPSERT
} from "../../../data/sessions/constants";

export const SessionLaborSheet = () => {
  const params: any = useParams()
  const { sessionId } = params
  const dispatch = useDispatch()
  const { isMobile } = UseViewSize()

  const isLaborSheetVisible = useAppSelector(selectIsLaborsSheetVisible)
  const sessionLabors = useAppSelector(selectActiveSessionLabors)

  const { user, salonId } = UseBaseApiParams()

  const [showLaborSheet, setShowLaborSheet] = useState(false)

  const updateLabor = (sessionLabor: SessionLabor, durationSeconds: number) => {
    const updatedSessionLabor: APIUpsertSessionLabor = {
      id: sessionLabor.id,
      labor_id: sessionLabor.laborId,
      stamped_duration_seconds: durationSeconds,
    }
    dispatch(dispatchUpsertSessionLabors({ token: user.token, salonId, sessionId, models: [updatedSessionLabor], loadingName: LOADING_SESSION_LABOR_INDIVIDUAL_UPSERT }))
  }

  const addSelectedLabors = (itemsToAdd: Labor[]) => {
    const laborsToUpsert: APIUpsertSessionLabor[] = []
    itemsToAdd.forEach((labor) => {
      // don't re add any labors that already exist
      const alreadyExists: SessionLabor | undefined = find(sessionLabors, (item) => item?.laborId === labor.id)
      if (!alreadyExists) {
        const newSessionLabor: APIUpsertSessionLabor = {
          labor_id: labor.id,
          stamped_duration_seconds: 0,
        }
        laborsToUpsert.push(newSessionLabor)
      }
    })
    const laborsToDelete: SessionLabor[] = []
    sessionLabors?.forEach((sessionLabors) => {
      const LaborsToAdd = find(itemsToAdd, (labor) => labor.id === sessionLabors.laborId)
      if (!LaborsToAdd) {
        laborsToDelete.push(sessionLabors)
      }
    })
    if (laborsToDelete.length > 0) {
      dispatch(dispatchDeleteSessionLabors({ token: user.token, salonId, sessionId, models: laborsToDelete }))
    }
    if (laborsToUpsert.length > 0) {
      dispatch(dispatchUpsertSessionLabors({ token: user.token, salonId, sessionId, models: laborsToUpsert }))
    }


  }

  const onClose = () => {
    dispatch(reduceIsLaborsSheetVisible(false))
  }
  const selectedLaborIds: number[] = sessionLabors ? sessionLabors.map((item) => item.laborId) : []
  const sortedSessionLabors = orderBy(sessionLabors, 'stampedName')

  const isLoadingUpsert = useAppSelector(state => selectLoadingState(state, LOADING_SESSION_LABOR_UPSERT))
  const isLoadingDelete = useAppSelector(state => selectLoadingState(state, LOADING_SESSION_LABOR_DELETE))
  const isLoading = isLoadingUpsert || isLoadingDelete
  return (
    <Drawer
      size={isMobile ? 'xs' : 'md'}
      isOpen={isLaborSheetVisible}
      placement="right"
      onClose={() => dispatch(reduceIsLaborsSheetVisible(false))}
    >
      {showLaborSheet && (
        <LaborSelectSheet
          selectedLaborIds={selectedLaborIds}
          show={showLaborSheet}
          onHide={() => setShowLaborSheet(false)}
          onLaborsSelected={(labors) => {
            addSelectedLabors(labors)
            setShowLaborSheet(false)
          }}
        />
      )}
      <DrawerOverlay />
      <DrawerCloseButton />
      <DrawerContent>
        <DrawerHeader>
          <Flex justify="space-between" align="center">
            <Text variant="title3">Select Services</Text>
            <Flex align="center" gridGap="12px">
              <Button colorScheme="brand.skylight" variant="round" onClick={onClose} minW="100px">
                Done
              </Button>
            </Flex>
          </Flex>
        </DrawerHeader>
        <DrawerBody>
          <>
            <DisableAutofocus />
            <Box h="24px" />
            {isLoading && <Loading/>}
            {!isLoading && sortedSessionLabors &&
              sortedSessionLabors.length > 0 &&
              sortedSessionLabors.map((sessionLabor, index) => {
                return <LaborRow key={index} sessionLabor={sessionLabor} updateLabor={updateLabor} />
              })}
            {sessionLabors && sessionLabors.length === 0 && <EmptyBox h="200px" />}
            {!sessionLabors && <Loading />}
            <Box h="12px" />
            <Flex justify="flex-end">
              <Button variant="round-outline" colorScheme="brand.skylight" onClick={() => setShowLaborSheet(true)}>
                <MaterialIcon name="add" style={{ marginRight: '4px' }} /> Services
              </Button>
            </Flex>
          </>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export const LaborRow = (props: {
  sessionLabor: SessionLabor
  updateLabor: (sessionLabor: SessionLabor, durationSeconds: number) => void
}) => {
  const dispatch = useDispatch()
  const { sessionLabor } = props
  const params: any = useParams()
  const { sessionId } = params
  const { user, salonId } = UseBaseApiParams()

  const [localTimeHours, localSetTimeHours] = useState<number | null>(null)
  const [localTimeMinutes, localSetTimeMinutes] = useState<number | null>(null)

  const onDelete = () => {
    dispatch(dispatchDeleteSessionLabors({ token: user.token, salonId, sessionId, models: [sessionLabor], loadingName: LOADING_SESSION_LABOR_INDIVIDUAL_UPSERT }))
  }
  useEffect(() => {
    const hoursAndMinutes = convertSecondsToHoursMinutes(sessionLabor.stampedDurationSeconds)

    // to prevent race conditions when they set hours and then quickly set minutes we dont want to overwrite the local time
    //
    // eg they set hours to 1, it calls update, then they sey minutes to 30 and before they leave the input the update comes back and
    // overwrites the input to the updated sessionLabor value
    // if we always use the local time it will fire the updates in sequence and always have the correct value
    //
    if (!localTimeHours) {
      localSetTimeHours(hoursAndMinutes.hours ?? null)
    }
    //
    if (!localTimeMinutes) {
      localSetTimeMinutes(hoursAndMinutes.minutes ?? null)
    }
    ////
  }, [sessionLabor.stampedDurationSeconds, localTimeMinutes, localTimeHours])



  return (
    <Flex key={sessionLabor.id} align="center" justify="space-between" p="0 0 24px 0" cursor="pointer">
      <Box>
        <Text variant="title3">{sessionLabor?.stampedName}</Text>
        <Text color="text.secondary">{sessionLabor?.stampedCategory}</Text>
      </Box>
      {/*<Flex align="center" gridGap="12px">*/}
      {/*  <TimeInput*/}
      {/*    label="Service Time"*/}
      {/*    value={{ hours: localTimeHours, minutes: localTimeMinutes }}*/}
      {/*    onChange={(val) => {*/}
      {/*      if (val) {*/}
      {/*        localSetTimeHours(val.hours)*/}
      {/*        localSetTimeMinutes(val.minutes)*/}
      {/*        const seconds = convertHoursMinutesToSeconds(val)*/}
      {/*        updateLabor(sessionLabor, seconds)*/}
      {/*      }*/}
      {/*    }}*/}
      {/*  />*/}
      <ConfirmPopover
        title={`This Action is Permanent`}
        subtitle={`This will also remove all colors, add-ons, and extensions associated with this service`}
        onConfirm={() => {
          onDelete()
        }}
      >
        <Box>
          <MaterialIcon size='18px' name="delete"/>
        </Box>
      </ConfirmPopover>
      {/*</Flex>*/}
    </Flex>
  )
}
