// external interfaces
//
import { ProductOnHandFilter, ProductPricingFilter, ProductTargetFilter } from '../sessions/interfaces'

export interface APIProduct {
  id: number
  master_product_id: number
  category: string
  brand_name: string
  user_defined_category: null
  user_defined_type: null
  parent_brand_name: string
  parent_brand_logo_url: string
  type: string
  inventory: {
    min_stock_level: number | null
    max_stock_level: number | null
    amount_grams_in_stock: number
    quantity_on_hand: number
    cost: number // cost per bottle
    cost_per_g: number
    cost_per_oz: number
  }
  pricing: {
    price: number // price per bottle
    price_per_g: number
    price_per_oz: number
  }
  vendor: {
    id: number
    name: string
    logo_url: string
  }
  line: {
    id: number
    name: string
    kind: 'extension' | 'color'
  }
  is_default_price: boolean
  size: number
  last_updated: string // looks like 2020-11-03T22:34:55.358774Z
  units: string | null
}

export interface APIUpdateProduct {
  product_id: number
  wholesale_price?: number // dollars
  mark_up?: number
  is_default_price?: boolean
  amount_grams_in_stock?: number
  max_stock_level?: number
  nickname?: string
}

export interface APILineOption {
  name: string
  brand_id: number
  parent_brand: string
  parent_brand_id: number
  parent_brand_logo_url: string | null
}

// internal interfaces
//
export interface Product {
  id: number
  masterProductId: number
  category: string // todo: this should be categoryName
  // lineName: string
  // vendorName: string
  // vendorLogoUrl: string
  type: string // todo: this could be renamed 'name'
  typeAlias: string | null
  inventory: {
    minStockLevel: number | null
    maxStockLevel: number | null
    amountGramsInStock: number
    quantityOnHand: number
    cost: number // costPerBottle
    costPerG: number
    costPerOz: number
    stockLevel: StockLevel
  }
  pricing: {
    price: number // pricePerBottle
    pricePerG: number
    pricePerOz: number
  }
  vendor: {
    name: string
    id: number
    logoUrl: string | null
  }
  line: {
    id: number
    name: string
  }
  isDefaultPrice: boolean
  size: number
  lastUpdated: string // looks_like_2020_11_03_t_22:34:55_358774_z
  units: string | null
}

export interface ProductFilters {
  searchText: string | null
  productName: string | null
  lineName: string | null
  vendorName: string | null
  categoryName: string | null
  productSize: number | null
  pricingFilter: ProductPricingFilter
  onHandFilter: ProductOnHandFilter
  targetFilter: ProductTargetFilter
  stockLevel: StockLevel | null
  lineOrType: string | null
}

// this is for lines that have not been added yet
export interface LineOption {
  lineName: string
  lineId: number
  vendorName: string
  vendorId: number
  vendorLogoUrl: string | null
}

export interface Vendor {
  id: number
  name: string
  logoUrl: string | null
}

export interface Line {
  id: number
  name: string
}

export interface ProductsByLine {
  [key: string]: Product[]
}

// vendor: ['lineName1', 'lineName2']
export interface LineNamesByVendor {
  [key: string]: string[]
}

export interface LinesByVendor {
  [key: string]: LineOption[] | LineLara[]
}

export interface LinesById {
  [key: number]: LineOption[] | LineLara[]
}

export interface VendorsById {
  [key: number]: Vendor
}

// this will look like
// {
//   category1: {
//     line1-size1: [{product1}, {product2}]
//     line1-size2: [{product1}, {product2}]
//     line2-size1: [{product1}, {product2}]
//   }
//   category1: {...}
// }
export interface ProductsByLineSize {
  [key: string]: Product[]
}

export interface ProductsByCategoryThenByLineSize {
  [key: string]: ProductsByLineSize
}

export type StockLevel = 'a-out' | 'b-over' | 'c-low' | 'd-normal' | string

export const STOCK_LEVEL_TYPES: { [key: string]: StockLevel } = {
  out: 'a-out',
  over: 'b-over',
  low: 'c-low',
  normal: 'd-normal',
}


// lara interfaces
//
//

export interface APIColorPatchLara {
  id: number
  nickname?: string
  mark_up?: number
  salon_purchase_price_cents?: number
  is_default_price?: boolean
  target?: number
  amount_grams_in_stock?: number
}

export interface APIColorLara {
  id: number
  master_product_is_deleted: boolean
  category: string
  brand: string
  type: string
  type_is_deleted: number
  size: number
  volume: number | null
  level: number | null
  nickname: string | null
  line: APILineLara
  vendor: APIVendorLara
  salon_purchase_price: number
  salon_purchase_price_per_gram: number
  client_purchase_price: number
  client_purchase_price_per_gram: number
  target: number | null
  amount_grams_in_stock: number
  is_default_price: boolean
  master_product_id: number
  mark_up: number
  is_deleted: boolean
  quantity_on_hand: number
  units: string | null
}

export interface APILineLara {
  id: number
  is_deleted: boolean
  name: string
  kind: string
  vendor: {
    id: number,
    name: string,
    logo_url: string,
    is_deleted: boolean
  }
}

export interface APIVendorLara {
  id: number
  is_deleted: boolean
  name: string
  logo_url: null | string
}

export interface ColorLara {
  id: number
  masterProductIsDeleted: boolean
  category: string
  brand: string
  type: string
  typeIsDeleted: number
  size: number
  volume: number | null
  level: number | null
  nickname: string | null
  line: LineLara
  vendor: VendorLara
  salonPurchasePriceCents: number
  salonPurchaseCentsPerGram: number
  clientPurchasePriceCents: number
  clientPurchaseCentsPerGram: number
  target: number | null
  amountGramsInStock: number
  isDefaultPrice: boolean
  masterProductId: number
  markUp: number
  isDeleted: boolean
  quantityOnHand: number
  stockLevel: StockLevel
}

export interface LineLara {
  id: number
  isDeleted: boolean
  name: string
  kind: string | 'color' | 'extension'

  // properties to match django v2 LineOption
  lineName: string
  lineId: number
  vendorName: string
  vendorId: number
  vendorLogoUrl: string | null
}

export interface VendorLara {
  id: number
  isDeleted: boolean
  name: string
  logoUrl: null | string
}

export interface ColorPatchLara {
  id: number
  nickname?: string
    markUp?: number
    isDefaultPrice?: boolean
    target?: number
    amountGramsInStock?: number
    clientPurchasePrice?: number // note: need the api field type to be fixed
    salonPurchasePrice?: number
}

export interface ColorLaraFilters {
  searchText: string | null
  productName: string | null
  lineName: string | null
  vendorName: string | null
  categoryName: string | null
  productSize: number | null
  pricingFilter: ProductPricingFilter
  stockLevel: StockLevel | null
}

export interface ColorsByLineSizeLara {
  [key: string]: ColorLara[]
}

export interface ColorsByCategoryThenByLineSizeLara {
  [key: string]: ColorsByLineSizeLara
}
