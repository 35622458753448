import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  NumberInput,
  NumberInputField,
  Text,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { APIUpdateSalon } from '../../../data/salon/interfaces'
import { dispatchUpdateSalon } from '../../../data/salon/api'
import { useDispatch } from 'react-redux'
import {dispatchBulkSetMarkup} from '../../../data/products/api'
import { useAppSelector } from '../../../hooks'
import { selectProductList } from '../../../data/products/slice'
import { selectLoadingState } from '../../../core/loading/slice'
import { Loading } from '../../../mini-lib/loading/Loading'
import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { HelpPopover } from '../../start-guide/common-components/HelpPopover'
import { CHECKLIST_CODES } from '../../../data/start-guide/constants'
import {LOADING_BULK_MARKUP} from "../../../data/products/constants";


export const GlobalMarkupModal = (props: {}) => {
  const dispatch = useDispatch()
  const { user, salonId } = UseBaseApiParams()
  const initialDefaultMarkup = user.currentSalonContext?.defaultMarkupPercentage || 0
  const products = useAppSelector(selectProductList)
  const [showMarkupModal, setShowMarkupModal] = useState(false)
  const [markup, setMarkup] = useState(initialDefaultMarkup)
  const addPercent = (val) => (val ? `${val}%` : 0)
  const removePercent = (val) => val.replace(/^%/, '')
  const isUpdatingMarkup = useAppSelector((state) => selectLoadingState(state, LOADING_BULK_MARKUP))
  const onSubmit = () => {
    if (markup !== initialDefaultMarkup && user.currentSalonContext && products) {
      const request: APIUpdateSalon = {
        name: user.currentSalonContext.salonName,
        default_markup_percentage: markup,
      }
      dispatch(
        dispatchUpdateSalon({
          token: user.token,
          userId: user.userId,
          salonId,
          currentSalonContext: user.currentSalonContext,
          request,
        }),
      )
      dispatch(dispatchBulkSetMarkup({token: user.token, salonId, markup}))
      // const updatedProducts: APIUpdateProduct[] = products.map((product) => {
      //   return {
      //     product_id: product.id,
      //     wholesale_price: product.inventory.cost,
      //     mark_up: markup,
      //   }
      // })
      //
      // const params = {
      //   token: user.token,
      //   user_id: user.userId,
      //   salon_id: salonId,
      //   models: updatedProducts,
      //   loadingName: GLOBAL_MARKUP,
      // }
      // dispatch(dispatchUpdateProducts(params))
    }
    setShowMarkupModal(false)
  }
  return (
    <>
      <Flex justify="center">
        <HelpPopover
          description="Add a markup! We recommend 100% to start."
          stepIndex={3}
          guideTypes={[CHECKLIST_CODES.autoPricing]}
        >
          <Box>
            <HelpPopover
              guideTypes={[CHECKLIST_CODES.addLines, CHECKLIST_CODES.addPricing]}
              children={
                <Button
                  colorScheme="brand.midnight"
                  w="120px"
                  onClick={() => setShowMarkupModal(true)}
                  variant="round-outline"
                >
                  {isUpdatingMarkup ? <Loading /> : 'Edit Markup'}
                </Button>
              }
              description="Add a markup to your wholesale cost to cover replenishment of products and additional costs. We recommend 100%."
              stepIndex={1}
              placement="left"
            />
          </Box>
        </HelpPopover>
      </Flex>

      <Modal isOpen={showMarkupModal} onClose={() => setShowMarkupModal(false)}>
        <ModalOverlay />
        <ModalContent>
          <ModalBody>
            <Box h="36px" />
            <Text variant="title2">SET A MARKUP</Text>
            <Box h="12px" />
            <Text variant="">This will update the markup for all of your products that currently have prices.</Text>
            <Box h="36px" />

            <NumberInput
              w="100%"
              value={addPercent(markup)}
              onChange={(updatedMarkup) => setMarkup(parseInt(removePercent(updatedMarkup)))}
              defaultValue={initialDefaultMarkup}
              colorScheme="brand.lavender"
            >
              <NumberInputField
                justifyContent="center"
                textAlign="center"
                alignContent="center"
                w="100%"
                borderRadius="50px"
                fontSize="26px"
                fontFamily="Oswald, sans-serif"
              />
            </NumberInput>

            <Box h="24px" />

            <Box>
              <span style={{ textDecoration: 'underline' }}>WARNING:</span> This will overwrite all your mark ups. Any
              specific products you have the mark up different will have to be readjusted.
            </Box>
          </ModalBody>

          <ModalFooter>
            <Flex gridGap="12px">
              <Button variant="round-outline" onClick={() => setShowMarkupModal(false)}>
                Close
              </Button>
              <Button variant="round" onClick={onSubmit}>
                Apply
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
