import { Labor } from '../../../data/labor/interfaces'
import { convertSecondsToHoursMinutes } from '../../../mini-lib/form/TimeInput'
import { Flex, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react'
import { COLORS } from '../../../mini-lib/theme/colors'
import { maybePluralize } from '../../../core/text'
import { useDispatch } from 'react-redux'
import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { MaterialIcon } from '../../../mini-lib/icons/MaterialIcon'
import { dispatchDeleteLabors } from '../../../data/labor/api'
import React from 'react'
import { buildLoadingLaborId } from '../../../data/labor/utils'
import { useAppSelector } from '../../../hooks'
import { selectLoadingState } from '../../../core/loading/slice'
import { Loading } from '../../../mini-lib/loading/Loading'
import {selectAssociatedLabors} from "../../../data/labor/slice";

export const LaborRow = (props: { labor: Labor; onClick: (labor: Labor) => void }) => {
  const { onClick, labor } = props
  const { hours, minutes } = convertSecondsToHoursMinutes(labor.durationSeconds)
  const loadingId = buildLoadingLaborId(labor.id)
  const loadingLabor = useAppSelector((state) => selectLoadingState(state, loadingId))
  return (
    <Flex
      onClick={() => onClick(labor)}
      cursor="pointer"
      align="center"
      minH="60px"
      borderColor={COLORS.shades_neutral_300}
      borderWidth="1px"
      borderRadius="8px"
      borderLeftColor={labor.colorhex || COLORS.skylight_500}
      borderLeftWidth="5px"
      p="6px 12px"
      mb="12px"
    >
      {loadingLabor ? (
        <Flex justify='center' align='center' w='100% !important'><Loading /></Flex>
      ) : (
        <>
          <Flex flex="1" direction="column" align="start" justify="center">
            <Text fontWeight="bold">{labor.name}</Text>
            <Text color={COLORS.text_secondary}>
              {hours && hours > 0 ? hours + ' ' + maybePluralize('hour', hours) : ''}{' '}
              {minutes && minutes > 0 ? minutes + ' ' + maybePluralize('minute', minutes) : ''}
            </Text>
          </Flex>
          <Flex justify="flex-end" align="center" gridGap="24px" onClick={(event) => event.stopPropagation()}>
            <Badge bgHex={COLORS.lavender_50} cHex={COLORS.lavender_500} label={'demo'} />
            <LaborRowMenu labor={labor} onEdit={() => onClick(labor)} />
          </Flex>
        </>
      )}
    </Flex>
  )
}

const Badge = (props: { bgHex: string; cHex: string; label: string; w?: string }) => {
  const { bgHex, cHex, label, w } = props
  return (
    <Flex w={w} h="30px" color={cHex} bg={bgHex} padding="4px 12px" borderRadius="15px" align="center" justify="center">
      {label}
    </Flex>
  )
}

export const LaborRowMenu = (props: { labor: Labor, onEdit: () => void }) => {
  const dispatch = useDispatch()
  const { labor, onEdit } = props
  const { user, salonId } = UseBaseApiParams()
  const loadingId = buildLoadingLaborId(labor.id)
  const associatedLabors = useAppSelector(state => selectAssociatedLabors(state, labor))
  return (
    <Menu>
      <MenuButton>
        <MaterialIcon name="more_vert" />
      </MenuButton>
      <MenuList>
        <MenuItem
          onClick={onEdit}
        >
          Edit Service
        </MenuItem>
        <MenuItem
          color="danger"
          onClick={() => {
            dispatch(
              dispatchDeleteLabors({
                token: user.token,
                loadingId,
                salonId,
                userId: user.userId,
                models: associatedLabors,
              }),
            )
          }}
        >
          Delete Service
        </MenuItem>
      </MenuList>
    </Menu>
  )
}
