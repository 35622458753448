import {
  Box, Button, Flex, Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Text
} from "@chakra-ui/react";
import React, { useState } from "react";
import { generatePath, Link } from "react-router-dom";
import { ROUTES } from "../../appRoutes";
import { UseBaseApiParams } from "../../core/UseBaseApiParams";
import { Gap } from "../../mini-lib/gap/Gap";
import { UseViewSize } from "../../core/UseViewSize";
import {ReleaseStripe} from "../../mini-lib/flags/Release";

export const FeatureNotAvailableButton = (props: {label: string}) => {
  const {label} = props
  const [isOpen, setIsOpen] = useState(false)
  return (
    <>
      {isOpen && (
        <FeatureNotAvailableInTrialModal isOpen={isOpen} setIsOpen={setIsOpen}/>
      )}
      <Button onClick={() => {setIsOpen(true)}} variant='round' colorScheme='brand.midnight'>
        {label}
      </Button>
    </>
  )
}

export const FeatureNotAvailableInTrialModal = (props: {isOpen: boolean, setIsOpen: (isOpen: boolean) => void}) => {
  const {isOpen, setIsOpen} = props
  const {salonId} = UseBaseApiParams()
  const {isMobile} = UseViewSize()
  const releaseStripe = ReleaseStripe()
  const url = releaseStripe
    ? generatePath(ROUTES.checkoutV2Subscription, { salonId })
    : generatePath(ROUTES.checkoutV1Subscription, { salonId })
  return (
    <Modal isOpen={isOpen} onClose={() => setIsOpen(false)}>
        <ModalOverlay />
        <ModalContent maxW={isMobile ? '90%' : '500px'}>
          <ModalBody >
            <Box p='24px' textAlign='center' >
              <Text variant='title1' fontWeight='bold'>Feature Not available in trial</Text>
              <Gap/>
              <Text>Sorry, this feature is only available in paid plans. Upgrade your subscription to unlock this feature.</Text>
              <Gap/>
              <Flex justify='center' gridGap='12px'>
                <Button variant='round-outline' colorScheme='brand.midnight' onClick={() => setIsOpen(false)}>Close</Button>
                <Link to={url}>
                  <Button variant='round' colorScheme='brand.midnight'>Upgrade Now</Button>
                </Link>
              </Flex>
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
  )
}