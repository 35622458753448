import React, { useEffect, useState } from 'react'
import { generatePath, useHistory } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import {
  Box,
  Button,
  Flex,
  Spinner,
  Text,
} from '@chakra-ui/react'
import { useAppSelector } from '../../hooks'

import { PageHeader } from '../../mini-lib/page-header/PageHeader'
import { PageLayout } from '../../mini-lib/layouts/PageLayout'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { ROUTES } from '../../appRoutes'
import { EmptyBox } from '../../mini-lib/empty/EmptyBox'
import { Gap } from '../../mini-lib/gap/Gap'

import { COLORS } from '../../mini-lib/theme/colors'
import { UseViewSize } from '../../core/UseViewSize'
import { dispatchListSalonUsers } from '../../data/salon-user/api'

import {
  reduceSelectedLabor,
  reduceSelectedLaborCategory,
  selectAllLaborCategoryAndColors,
  selectEditableLaborCategoryAndColor,
  selectLabors,
  selectSelectedLabor,
  selectSelectedLaborCategoryAndColor,
} from '../../data/labor/slice'
import {
  dispatchListLabors, dispatchUpsertLabors,
} from '../../data/labor/api'
import { uniqBy } from 'lodash'
import { Loading } from '../../mini-lib/loading/Loading'
import { selectLoadingState } from '../../core/loading/slice'
import { LIST_LABORS } from '../../data/labor/constants'
import {LaborSheet} from "./components/LaborSheet";
import {CategoryModal, LaborCategoryList} from "./components/LaborCategory";
import {LaborRow} from "./components/LaborRow";
import {buildDefaultLabors, buildLoadingLaborId} from "../../data/labor/utils";

export const LaborPage = () => {
  const { user, salonId } = UseBaseApiParams()
  const breadcrumbs = [{ label: 'Home', url: generatePath(ROUTES.home, { salonId }) }, { label: 'Labors' }]
  const dispatch = useDispatch()
  const history = useHistory()

  const labors = useAppSelector(selectLabors)
  const selectedCategory = useAppSelector(selectSelectedLaborCategoryAndColor)

  const itemsLoaded = !!labors

  useEffect(() => {
    dispatch(dispatchListLabors({ token: user.token, salonId }))
  }, [dispatch, user.token, salonId])

  useEffect(() => {
    dispatch(dispatchListSalonUsers({ token: user.token, salonId }))
  }, [dispatch, user.token, salonId])

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const initialCategory = labors && labors.length > 0 ? {category: labors[0].category, colorhex: labors[0].colorhex} : null

  useEffect(() => {
    if (selectedCategory === null && initialCategory) {
      dispatch(reduceSelectedLaborCategory(initialCategory))
    }

    // note: initialCategory should only be set once but it may render multiple times if
    // a category is created that is first alphabetically or something, thats ok, disabling the eslint
    //
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, selectedCategory, initialCategory])

  const goToOnboard = () => {
    history.push(generatePath(ROUTES.sgLaborIntro, { salonId }))
  }

  const { isMobile } = UseViewSize()
  const allCategoryAndColors = useAppSelector(selectAllLaborCategoryAndColors)
  const laborsLoading = useAppSelector((state) => selectLoadingState(state, LIST_LABORS))

  const editableLaborCategoryAndColor = useAppSelector(selectEditableLaborCategoryAndColor)
  const [isCategoryModalVisible, setIsCategoryModalVisible] = useState(false)

  useEffect(() => {
    if (!!editableLaborCategoryAndColor.category && !isCategoryModalVisible) {
      setIsCategoryModalVisible(true)
    }
  }, [editableLaborCategoryAndColor.category, isCategoryModalVisible]);

  const [isLaborSheetVisible, setIsLaborSheetVisible] = useState(false)

  const selectedLabor = useAppSelector(selectSelectedLabor)
  const laborsForSelectedCategory = labors ? labors.filter((labor) => labor.category === selectedCategory?.category) : null

  // note: there can be multiple labors associated with a selected labor template
  // one for each user associated with the labor
  //
  const laborsForSelectedLabor = laborsForSelectedCategory ? laborsForSelectedCategory.filter((labor) => labor.name === selectedLabor?.name) : null

  // note: only use uniq for the list, use laborsForSelectedCategory everywhere else
  const uniqLaborsForSelectedCategory = laborsForSelectedCategory ? uniqBy(laborsForSelectedCategory, 'name') : null

  const createCategoryAndColor =
    laborsForSelectedCategory && laborsForSelectedCategory.length > 0
      ? {category: laborsForSelectedCategory[0].category, colorhex: laborsForSelectedCategory[0].colorhex}
      : selectedCategory || {category: '', colorhex: COLORS.midnight_500}

  const onAddService = () => {
    setIsLaborSheetVisible(true)
  }

  const loadingId = buildLoadingLaborId(0)
  const creatingLabor = useAppSelector((state) => selectLoadingState(state, loadingId))

  const createDefaultLabors = () => {
    // todo: figure out if we want to add these to all stylists in the salon (probably not)
    const defaultLabors = buildDefaultLabors({salonId})
    dispatch(dispatchUpsertLabors({salonId, userId: user.userId, token: user.token, models: defaultLabors}))
  }
  return (
    <>
      {isLaborSheetVisible && (
        <LaborSheet
          laborTemplate={selectedLabor}
          labors={laborsForSelectedLabor}
          categoryAndColor={createCategoryAndColor}
          show={isLaborSheetVisible}
          setShow={setIsLaborSheetVisible}
        />
      )}
      {isCategoryModalVisible && <CategoryModal
        categoryAndColor={editableLaborCategoryAndColor}
        showModal={isCategoryModalVisible}
        setShowModal={setIsCategoryModalVisible}
      />}
      <PageLayout
        variant="full"
        header={<PageHeader title="Services" breadcrumbs={breadcrumbs} showHelp={false} />}
        content={
          <>
            <Gap/>
            <Flex w="100%" gridGap="24px" h={isMobile ? '100%' : '100vh'} wrap="wrap">
              <Flex w={isMobile ? '100%' : '240px'} justify={isMobile ? 'center' : 'start'}>
                <LaborCategoryList categoryAndColors={allCategoryAndColors} setIsCategoryModalVisible={setIsCategoryModalVisible} />
              </Flex>
              <Box flex="1" overflowX="scroll">
                <Flex w="100%" justify="center">
                  <Box maxW="500px" w="100%">
                    {/* category header */}
                    {/**/}
                    <Flex justify="space-between" align="center">
                      <Text variant="default/regular/title3">{selectedCategory?.category}</Text>
                      <Flex align="center">
                        {selectedCategory && (
                          <Button variant="round" colorScheme="brand.midnight" onClick={onAddService}>
                            {creatingLabor ? <Loading/> : 'Add Service'}
                          </Button>
                        )}
                      </Flex>
                    </Flex>

                    <Gap />
                    {laborsLoading && <Loading mb="24px" />}
                    {(!laborsLoading && (!uniqLaborsForSelectedCategory || uniqLaborsForSelectedCategory.length === 0)) && (
                      <EmptyBox
                        title="NO SERVICES SET"
                        content="Now you can account for your time with SalonScale’s Service Feature. No matter how you charge for your time, we
                  will make sure you charge your worth. Click below to get started."
                      >
                        <Box h="24px" />
                        <Button variant="round" colorScheme="brand.midnight" onClick={createDefaultLabors}>
                          {creatingLabor ? <Loading/> : 'Create Some Default Services'}
                        </Button>
                      </EmptyBox>
                    )}
                    {uniqLaborsForSelectedCategory &&
                      uniqLaborsForSelectedCategory.map((labor) => {
                        return (
                          <LaborRow
                            key={labor.id}
                            labor={labor}
                            onClick={(labor) => {
                              dispatch(reduceSelectedLabor(labor))
                              setIsLaborSheetVisible(true)
                            }}
                          />
                        )
                      })}
                  </Box>
                </Flex>
              </Box>
            </Flex>

            {/* empty state */}
            {itemsLoaded && labors.length === 0 && (
              <>
                <Gap s="48px" />
                <EmptyBox
                  title="No Services"
                  content="Now you can account for your time with SalonScale’s Service Feature. No matter how you charge for your time, we
          will make sure you charge your worth. Click below to get started."
                >
                  <Box h="24px" />
                  <Button variant="round" colorScheme="brand.midnight" onClick={goToOnboard}>
                    LET'S GO!
                  </Button>
                </EmptyBox>
              </>
            )}

            {!itemsLoaded && (
              <Flex h="500px" align="center" justify="center">
                <Spinner />
              </Flex>
            )}
          </>
        }
      />
    </>
  )
}

