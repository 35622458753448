import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Flex, Spinner } from '@chakra-ui/react'

import { useAppSelector } from '../../hooks'
import {
  reduceMemberViewDateSelected,
  reduceSessionsDateSelection,
  selectMemberViewDateSelected,
  selectSessionsLara,
  selectSessionsPaginationLara,
} from '../../data/sessions/slice'
import { dispatchListSessionsLara } from '../../data/sessions/api'

import { PageHeader } from '../../mini-lib/page-header/PageHeader'
import { PageLayout } from '../../mini-lib/layouts/PageLayout'
import { filterSessionsLara } from '../../data/sessions/utils'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { UseViewSize } from '../../core/UseViewSize'
import { selectLoadingState } from '../../core/loading/slice'
import { generatePath, Link } from 'react-router-dom'
import { SessionCreateButton } from './SessionCreateSheet'
import { ROUTES } from '../../appRoutes'
import { HelpDrawer } from '../start-guide/common-components/HelpDrawer'
import { ReleasePageHelp } from '../../mini-lib/flags/Release'
import { VIDEO_MAP } from '../../mini-lib/video/constants'
import { SessionListBySalonMember } from './SessionListBySalonMember'
import { MaterialIcon } from '../../mini-lib/icons/MaterialIcon'
import { COLORS } from '../../mini-lib/theme/colors'
import { Gap } from '../../mini-lib/gap/Gap'
import { selectUsersMeta } from '../../data/user/slice'
import {
  LOADING_SESSIONS,
  LOADING_SESSIONS_PAGE,
  SESSION_STYLIST_DATA_OPTIONS
} from '../../data/sessions/constants'
import { buildDateYYYYMMDD } from '../../core/dates'
import { dispatchListUsersMetaLara } from '../../data/user/api'
import { DateInput, FilterBar } from '../../mini-lib'
import { SessionStatusFilter, ShowStylistsWithNoSessions } from './SessionFilters'
import { addDays, endOfDay, format, startOfDay, subDays } from 'date-fns'
import {SessionLimitBanner} from "../../mini-lib/payment-status/PaymentStatus";

export const SessionsBySalonMemberPage = () => {
  const { user, salonId } = UseBaseApiParams()

  const dispatch = useDispatch()

  const breadcrumbs = [{ label: 'Home', url: generatePath(ROUTES.home, { salonId }) }, { label: 'Sessions' }]

  const releasePageHelp = ReleasePageHelp()
  const sessionsLoading = useAppSelector((state) => selectLoadingState(state, LOADING_SESSIONS))
  const loadingSessionsPage = useAppSelector((state) => selectLoadingState(state, LOADING_SESSIONS_PAGE))
  const paginationLara = useAppSelector(selectSessionsPaginationLara)
  const modelsLara = useAppSelector(selectSessionsLara)
  const salonMembers = useAppSelector(selectUsersMeta)
  const selectedDate = useAppSelector(selectMemberViewDateSelected)

  const [statusFilter, setStatusFilter] = useState<string | null>(null)
  // const [sortFilter, setSortFilter] = useState<string | null>(null)
  const [stylistDataFilter, setStylistDataFilter] = useState<string>(SESSION_STYLIST_DATA_OPTIONS.all)

  useEffect(() => {
    dispatch(dispatchListUsersMetaLara({ token: user.token, salonId }))
    dispatch(reduceSessionsDateSelection({ type: 'day', endDate: new Date(), startDate: new Date() }))
    // eslint-disable-next-line
  }, [])

  const filteredModelsLara = modelsLara
    ? filterSessionsLara({
        models: modelsLara,
        startDate: startOfDay(selectedDate),
        endDate: endOfDay(selectedDate),
        status: statusFilter || undefined,
      })
    : []

  const selectedDateString = selectedDate ? buildDateYYYYMMDD(selectedDate) : null

  useEffect(() => {
    dispatch(
      dispatchListSessionsLara({
        token: user.token,
        salonId,
        pageSize: 10000, // note: this is constrained to 1 day by the ui
        startDateTime: startOfDay(selectedDate),
        endDateTime: endOfDay(selectedDate),
        loadingName: LOADING_SESSIONS,
      }),
    )
    // note: disabling this because passing date objects breaks the use effect, i could memoize them but that feels worse than this
    // eslint-disable-next-line
  }, [dispatch, user.token, salonId, selectedDateString])

  return (
    <PageLayout
      variant="full"
      top={<SessionLimitBanner/>}
      header={
        <PageHeader
          title="Sessions"
          breadcrumbs={breadcrumbs}
          actions={
            <Flex justify="end" align="center">
              <>
                <MaterialIcon cursor="pointer" colorhex={COLORS.lavender_500} name="view_column" />
                <Gap s="6px" />
                <Link to={generatePath(ROUTES.sessions, { salonId })}>
                  <MaterialIcon cursor="pointer" colorhex={COLORS.shades_neutral_400} name="list" onClick={() => dispatch(reduceSessionsDateSelection({type: null, endDate: null, startDate: null}))}/>
                </Link>
                <Gap s="12px" />
              </>
              <SessionCreateButton />
            </Flex>
          }
          showHelp={true}
        />
      }
      content={
        <>
          {releasePageHelp && (
            <HelpDrawer
              video={VIDEO_MAP.creatingSessions}
              title="Let’s take a look inside Sessions"
              content={`A color session is the backbone of how SalonScale works. Every gram of hair color from your back bar needs to go through a color session so all the other features work properly. Sessions are 99% preformed on the mobile app, but also can be preformed on the Business Centre if needed. Your front staff can be added in teams and view sessions this way as well instead of using a phone at the front desk. `}
            />
          )}
          {modelsLara && paginationLara && (
            <>
              <Filters
                statusFilter={statusFilter}
                setStatusFilter={setStatusFilter}
                // sortFilter={sortFilter}
                // setSortFilter={setSortFilter}
                stylistDataFilter={stylistDataFilter}
                setStylistDataFilter={setStylistDataFilter}
              />
              <Gap s="12px" />
              <SessionDateSelect date={selectedDate} />
              <Gap s="12px" />
              <SessionListBySalonMember
                allSalonMembers={salonMembers}
                stylistDataFilter={stylistDataFilter}
                sessions={filteredModelsLara}
                sessionsLoading={sessionsLoading}
              />
            </>
          )}
          {(sessionsLoading || !paginationLara || !modelsLara || !filteredModelsLara) && loadingSessionsPage && (
            <Flex h="500px" align="center" justify="center">
              <Spinner />
            </Flex>
          )}
        </>
      }
    />
  )
}

export const Filters = (props: {
  // sortFilter: string | null
  // setSortFilter: (filter: string) => void

  statusFilter: string | null
  setStatusFilter: (filter: string) => void

  stylistDataFilter: string
  setStylistDataFilter: (filter: string) => void
}) => {
  const { statusFilter, setStatusFilter, stylistDataFilter, setStylistDataFilter } = props

  const { isMobile } = UseViewSize()
  const dispatch = useDispatch()
  const selectedDate = useAppSelector(selectMemberViewDateSelected)
  return (
    <FilterBar
      filters={
        <>
          <SessionStatusFilter isMobile={isMobile} setStatusFilter={setStatusFilter} statusFilter={statusFilter} />
          {/*<SessionSortFilter isMobile={isMobile} sortFilter={sortFilter} setSortFilter={setSortFilter}/>*/}
          <ShowStylistsWithNoSessions
            isMobile={isMobile}
            stylistDataFilter={stylistDataFilter}
            setStylistDataFilter={setStylistDataFilter}
          />
          <DateInput
            label="Date"
            w={isMobile ? '47%' : '140px'}
            value={selectedDate}
            isClearable={false}
            onChange={(date) => {
              dispatch(reduceMemberViewDateSelected(date))
            }}
          />
        </>
      }
    />
  )
}

export const SessionDateSelect = (props: { date: Date }) => {
  const { date } = props
  const dispatch = useDispatch()
  const size = '45px !important' // need to override some built in button styles so the buttons are consistent

  return (
    <Flex align="center" justify="center" fontSize="32px" gridGap="12px">
      <Button
        variant="round-ghost"
        maxW={size}
        minWidth={size}
        size={size}
        h={size}
        onClick={() => {
          const updated = subDays(date, 1)
          dispatch(reduceMemberViewDateSelected(updated))
        }}
      >
        <MaterialIcon name="chevron_left" size="40px" />
      </Button>
      {format(date, 'MMM dd yyyy')}
      <Button
        variant="round-ghost"
        maxW={size}
        minWidth={size}
        size={size}
        h={size}
        onClick={() => {
          const updated = addDays(date, 1)
          dispatch(reduceMemberViewDateSelected(updated))
        }}
      >
        <MaterialIcon name="chevron_right" size="40px" />
      </Button>
    </Flex>
  )
}
