import {DisplayUnit, SessionColorLara} from "../../../data/sessions/interfaces";
import {
  getBowlMapLara,
  getBowlTitleLara,
  getSessionColorWasteByBowlLara,
  getTotalSessionColorCostLara
} from "../../../data/sessions/utils";
import {Box, Divider, Flex, Text} from "@chakra-ui/react";
import {BowlFormula} from "../session-colors/SessionBowls";
import {formatCentsToDollars} from "../../../mini-lib/units/money";
import React from "react";
import {useAppSelector} from "../../../hooks";
import {selectActiveSessionColors, selectActiveSessionDisplayUnit} from "../../../data/sessions/slice";
import {COLORS} from "../../../mini-lib/theme/colors";

export const SessionWaste = () => {

  // lara session apis
  //
  //
  const sessionColors = useAppSelector(state => selectActiveSessionColors(state, null))
  const displayUnit = useAppSelector(selectActiveSessionDisplayUnit)

  const bowlMapLara = sessionColors ? getBowlMapLara(sessionColors) : null
  const wasteBowlListLara = bowlMapLara ? getSessionColorWasteByBowlLara(bowlMapLara) : null

  const wasteTotal = sessionColors ? getTotalSessionColorCostLara(sessionColors, 'waste') : 0
  return (
    <>
      {!!wasteTotal && (
        <Box borderRadius='8px' p='12px 0' border={`1px solid ${COLORS.shades_neutral_300}`}>
          <Flex justify="space-between" p='0 12px'>
            <Text fontWeight="bold">
              Waste
            </Text>
            <Text fontWeight="bold">
              ${formatCentsToDollars(wasteTotal)}
            </Text>
          </Flex>
          {wasteBowlListLara?.map((bowl, index) => {
            return <SessionBowlWaste key={index} index={index} displayUnit={displayUnit} bowlColors={bowl} />
          })}
        </Box>
      )}
    </>
  )
}
export const SessionBowlWaste = (props: { index: number, bowlColors: SessionColorLara[], displayUnit: DisplayUnit }) => {
  const { index, bowlColors, displayUnit } = props
  const bowlWasteTotal = getTotalSessionColorCostLara(bowlColors, 'waste')
  const sessionItemsWithWaste = bowlColors.filter((item) => item.wasteGrams && item.wasteGrams > 0)
  const { bowlType, bowlTag } = getBowlTitleLara(bowlColors[0])
  return (
    <>
      {sessionItemsWithWaste.length > 0 && (
        <>
          {index !== 0 && <Divider/>}
          <Box p='12px'>
            <Flex align="center" justify="space-between" gridGap="12px">
              <Text fontWeight='bold'>{bowlType} {bowlType && bowlTag && '•'} {bowlTag} Waste</Text>
              <Text variant="footnote">${formatCentsToDollars(bowlWasteTotal)}</Text>
            </Flex>
            <BowlFormula bowlColors={bowlColors} displayUnit={displayUnit} bowlView="waste" variant='line-items'/>
          </Box>
        </>
      )}
    </>
  )
}

