import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {
  ColorBrandProductDetail,
  ColorBrandReport,
  ColorBrandWasteProductDetail,
  ColorLabor,
  ColorStylistProductsDetail,
  ColorStylistReport,
  ColorStylistWasteProductsDetail,
  ColorStylistWasteReport,
  Units
} from './interfaces'
import { RootState } from '../../store'
import { DateRangeType } from '../../mini-lib/dates-and-times/constants'
import { orderBy } from "lodash";

interface ReportsV2State {
  selectedDateRangeType: DateRangeType
  customStartDate: string
  customEndDate: string
  selectedBrand: string
  colorBrandReports: ColorBrandReport[] | null
  colorBrandUsedProducts: ColorBrandProductDetail[] | null
  colorBrandUnusedProducts: ColorBrandProductDetail[] | null
  colorBrandWasteProductReports: ColorBrandWasteProductDetail[] | null
  colorStylistReports: ColorStylistReport[] | null,
  colorStylistWasteReports: ColorStylistWasteReport[] | null,
  colorStylistProductReports: ColorStylistProductsDetail[] | null,
  colorStylistWasteProductReports: ColorStylistWasteProductsDetail[] | null
  colorlaborReports: ColorLabor[] | null
  selectedWeightUnit: 'g' | 'oz'
}

const initialState: ReportsV2State = {
  colorBrandReports: null,
  selectedDateRangeType: 'week',
  customStartDate: '',
  customEndDate: '',
  selectedBrand: '',
  colorBrandUsedProducts: null,
  colorBrandUnusedProducts: null,
  colorBrandWasteProductReports: null,
  colorStylistReports: null,
  colorStylistWasteReports: null,
  colorStylistProductReports: null,
  colorStylistWasteProductReports: null,
  colorlaborReports: null,
  selectedWeightUnit: 'g'
}

export const ReportsV2Slice = createSlice({
  name: 'reportsv2',
  initialState,
  reducers: {
    reduceColorBrandReports: (state, action: PayloadAction<ColorBrandReport[] | null>) => {
      state.colorBrandReports = action.payload
    },
    reduceSelectedDateRange: (state, action: PayloadAction<DateRangeType>) => {
      state.selectedDateRangeType = action.payload
    },
    reduceSelectedBrand: (state, action: PayloadAction<string>) => {
      state.selectedBrand = action.payload
    },

    reduceCustomStartDate: (state, action: PayloadAction<string>) => {
      state.customStartDate = action.payload
    },

    reduceCustomEndDate: (state, action: PayloadAction<string>) => {
      state.customEndDate = action.payload
    },

    reduceColorBrandUsedProducts: (state, action: PayloadAction<ColorBrandProductDetail[] | null>) => {
      state.colorBrandUsedProducts = action.payload
    },

    reduceColorBrandUnusedProducts: (state, action: PayloadAction<ColorBrandProductDetail[] | null>) => {
      state.colorBrandUnusedProducts = action.payload
    },

    reduceColorBrandWasteProducts: (state, action: PayloadAction<ColorBrandWasteProductDetail[] | null>) => {
      state.colorBrandWasteProductReports = action.payload
    },

    reduceColorStylistsReports: (state, action: PayloadAction<ColorStylistReport[] | null>) => {
      state.colorStylistReports = action.payload
    },

    reduceColorStylistsWasteReports: (state, action: PayloadAction<ColorStylistWasteReport[] | null>) => {
      state.colorStylistWasteReports = action.payload
    },

    reduceColorStylistProducts: (state, action: PayloadAction<ColorStylistProductsDetail[] | null>) => {
      state.colorStylistProductReports = action.payload
    },

    reduceColorStylistWasteProducts: (state, action: PayloadAction<ColorStylistWasteProductsDetail[] | null>) => {
      state.colorStylistWasteProductReports = action.payload
    },

    reduceColorLaborReports: (state, action: PayloadAction<ColorLabor[] | null>) => {
      state.colorlaborReports = action.payload
    },

    reduceWeightUnit: (state, action: PayloadAction<any>) => {
      state.selectedWeightUnit = action.payload
    }
  },
})

export const {
  reduceSelectedBrand,
  reduceColorBrandReports,
  reduceSelectedDateRange,
  reduceCustomStartDate,
  reduceCustomEndDate,
  reduceColorBrandUsedProducts,
  reduceColorBrandUnusedProducts,
  reduceColorBrandWasteProducts,
  reduceColorStylistsReports,
  reduceColorStylistProducts,
  reduceColorStylistWasteProducts,
  reduceColorStylistsWasteReports,
  reduceColorLaborReports,
  reduceWeightUnit
} = ReportsV2Slice.actions

// selectors
export const selectColorBrandReports = (state: RootState): ColorBrandReport[] | null => {
  return state.reportsV2.colorBrandReports || null
}
export const selectColorBrandUsedProducts = ( state: RootState ): ColorBrandProductDetail[] | null => {
  if (!state.reportsV2.colorBrandUsedProducts) {
    return null
  }
  const lineSorter = ( product ) => product.line.toLowerCase()
  const categorySorter = ( product ) => product.category.toLowerCase()
  const nameSorter = ( product ) => product.type.toLowerCase()
  const sorted = orderBy(
      state.reportsV2.colorBrandUsedProducts,
      [categorySorter, lineSorter, nameSorter],
      ['asc', 'asc', 'asc'],
    )
  return sorted
}

export const selectColorBrandUnusedProducts = (state: RootState): ColorBrandProductDetail[] | null => {
  if (!state.reportsV2.colorBrandUnusedProducts) {
    return null
  }
  const lineSorter = ( product ) => product.line.toLowerCase()
  const categorySorter = ( product ) => product.category.toLowerCase()
  const nameSorter = ( product ) => product.type.toLowerCase()
  const sorted = orderBy(
      state.reportsV2.colorBrandUnusedProducts,
      [categorySorter, lineSorter, nameSorter],
      ['asc', 'asc', 'asc'],
    )
  return sorted
}
export const selectColorBrandWasteProducts = (state: RootState): ColorBrandWasteProductDetail[] | null => {
  if (!state.reportsV2.colorBrandWasteProductReports) {
    return null
  }
  const lineSorter = ( product ) => product.line.toLowerCase()
  const categorySorter = ( product ) => product.category.toLowerCase()
  const nameSorter = ( product ) => product.type.toLowerCase()
  const sorted = orderBy(
      state.reportsV2.colorBrandWasteProductReports,
      [categorySorter, lineSorter, nameSorter],
      ['asc', 'asc', 'asc'],
    )
  return sorted
}

export const selectSelectedBrand = (state: RootState): string => {
  return state.reportsV2.selectedBrand
}

export const selectColorStylistReports = (state: RootState): ColorStylistReport[] | null => {
  return state.reportsV2.colorStylistReports || null
}

export const selectColorStylistWasteReports = (state: RootState): ColorStylistWasteReport[] | null => {
  return state.reportsV2.colorStylistWasteReports || null
}

export const selectColorStylistProducts = (state: RootState): ColorStylistProductsDetail[] | null => {
  if (!state.reportsV2.colorStylistProductReports) {
    return null
  }
  const lineSorter = ( product ) => product.line.toLowerCase()
  const categorySorter = ( product ) => product.category.toLowerCase()
  const nameSorter = ( product ) => product.color.toLowerCase()
  const sorted = orderBy(
      state.reportsV2.colorStylistProductReports,
      [categorySorter, lineSorter, nameSorter],
      ['asc', 'asc', 'asc'],
    )
  return sorted
}

export const selectColorStylistWasteProducts = (state: RootState): ColorStylistWasteProductsDetail[] | null => {
  return state.reportsV2.colorStylistWasteProductReports || null
}

export const selectColorLaborReports = (state: RootState): ColorLabor[] | null => {
  return state.reportsV2.colorlaborReports || null
}

export const selectReportsDateRangeType = (state: RootState): DateRangeType => state.reportsV2.selectedDateRangeType
export const selectCustomStartDate = (state: RootState): string => state.reportsV2.customStartDate
export const selectCustomEndDate = (state: RootState): string => state.reportsV2.customEndDate
export const selectedUnit = (state: RootState): Units => state.reportsV2.selectedWeightUnit

export default ReportsV2Slice.reducer
