import { LoggedInUser, LoggedInUserSalonPermissions } from '../../data/user/interfaces'
import {
  hasActiveQuickTrialAndNoSubscription,
  hasExpiredQuickTrialAndNoSubscription,
  hasNoQuickTrialAndNoSubscription,
} from '../../data/salon/utils'
import { generatePath } from 'react-router-dom'

import { ROUTES } from '../../appRoutes'
import {
  getCurrentPaymentStatus,
  getLoggedInUserActiveSalonContext,
  hasInactiveSubscription,
  isStylistUser
} from '../../data/user/utils'

export const getRedirectUrlFromPaymentStatus = (params: {
  user: LoggedInUser | null
  userPermissions: LoggedInUserSalonPermissions | null
  finishedGuide: boolean | null
  redirectUrl?: string | null
}): string | null => {
  const { user, userPermissions, finishedGuide, redirectUrl } = params

  // no user
  //
  // note: maybe redirect to login here
  //
  if (!user) {
    return null
  }

  // override url
  //
  if (redirectUrl) {
    return redirectUrl
  }

  // super user
  //
  if (user.isSuperuser) {
    return 'admin'
  }

  // no salon
  //
  if (!user.salonContexts || user.salonContexts.length === 0) {
    const url = generatePath(ROUTES.signupV2Salon)
    return url
  }


  // payment status redirects
  //
  const paymentStatus = getCurrentPaymentStatus(user)
  const salonId = user.currentSalonContext?.salonId ?? getLoggedInUserActiveSalonContext(user.salonContexts)?.salonId ?? null

  // null payment status
  //
  if (paymentStatus === null) {
    // note: potentially send them to checkout v2 here?
    // note: potentially send them to trial after user/salon create?
    //
    const url = generatePath(ROUTES.signupV2Type)
    return url
  }

  // handle active subs
  //
  // active trial
  if (salonId && paymentStatus?.paymentSystemType === 'trial' && paymentStatus.status === 'active') {
    const url = generatePath(ROUTES.trialIndex, { salonId })
    return url
  }
  //
  // active chargebee or stripe
  //
  if (salonId && paymentStatus && (paymentStatus.paymentSystemType === 'chargebee' || paymentStatus.paymentSystemType === 'stripe') && paymentStatus.status === 'active') {
    const isStylist = userPermissions && isStylistUser(userPermissions)
    const url = isStylist
      ? generatePath(ROUTES.sessions, { salonId})
      : finishedGuide
        ? generatePath(ROUTES.home, { salonId })
        : generatePath(ROUTES.sgIndex, { salonId })
    return url
  }


  // handle expired subs
  //
  //
  // expired trial
  if (salonId && paymentStatus?.paymentSystemType === 'trial' && paymentStatus.status !== 'active') {
    // note: we might want to send them to a different page upon expiry
    //
    const url = generatePath(ROUTES.trialIndex, { salonId })
    return url
  }
  //
  // expired chargebee
  //
  if (salonId && paymentStatus?.paymentSystemType === 'chargebee' && paymentStatus.status !== 'active') {
    const url = generatePath(ROUTES.salonSettings, { salonId })
    return url
  }
  //
  // expired stripe
  //
  if (salonId && paymentStatus?.paymentSystemType === 'stripe' && paymentStatus.status !== 'active') {
    const url = generatePath(ROUTES.managePayment, { salonId })
    return url
  }

  return null
}

export const getRedirectUrl = (params: {
  user: LoggedInUser | null
  userPermissions: LoggedInUserSalonPermissions | null
  finishedGuide: boolean | null
  releaseQuickTrial: boolean
  redirectUrl?: string | null
}): string | null => {
  const { user, userPermissions, finishedGuide, releaseQuickTrial, redirectUrl } = params

  if (!user) {
    return null
  }

  if (redirectUrl) {
    return redirectUrl
  }

  if (user.isSuperuser) {
    return 'admin'
  }

  if (!user.salonContexts || user.salonContexts.length === 0) {
    const url = releaseQuickTrial ? generatePath(ROUTES.signupV2Salon) : generatePath(ROUTES.signupPlans)
    return url
  }

  if (user.currentSalonContext && hasActiveQuickTrialAndNoSubscription(user.currentSalonContext)) {
    const url = generatePath(ROUTES.trialIndex, { salonId: user.currentSalonContext.salonId })
    return url
  }

  if (user.currentSalonContext && hasExpiredQuickTrialAndNoSubscription(user.currentSalonContext)) {
    // note: leaving this here in case we decide we want to go to a different page upon expiry
    const url = generatePath(ROUTES.trialIndex, { salonId: user.currentSalonContext.salonId })
    return url
  }
  if (user.currentSalonContext && hasNoQuickTrialAndNoSubscription(user.currentSalonContext)) {
    const url = releaseQuickTrial ? generatePath(ROUTES.signupV2Type) : generatePath(ROUTES.signupPlans)
    return url
  }

  if (user.currentSalonContext && hasInactiveSubscription(user.currentSalonContext)) {
    const url = generatePath(ROUTES.settings, { salonId: user.currentSalonContext.salonId }) + '?tab=subscription'
    return url
  }

  if (user.currentSalonContext && userPermissions && isStylistUser(userPermissions)) {
    const url = generatePath(ROUTES.sessions, { salonId: user.currentSalonContext.salonId })
    return url
  }
  // todo: add page for stylist with inactive subscription
  //
  //

  if (user.currentSalonContext && finishedGuide) {
    const url = generatePath(ROUTES.home, { salonId: user.currentSalonContext.salonId })
    return url
  }
  if (user.currentSalonContext && !finishedGuide) {
    const url = generatePath(ROUTES.sgIndex, { salonId: user.currentSalonContext.salonId })
    return url
  }

  return null
}
