import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'

import { selectSupplyList } from '../../data/supplies/slice'
import { dispatchCreateOrUpdateSuppliesLara, dispatchDeleteSuppliesLara, dispatchListSupplies } from '../../data/supplies/api'

import { PageHeader } from '../../mini-lib/page-header/PageHeader'
import { PageLayout } from '../../mini-lib/layouts/PageLayout'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { generatePath } from 'react-router-dom'
import { ReleaseLaraSupplies } from '../../mini-lib/flags/Release'
import { ROUTES } from "../../appRoutes";
import { Table, Thead, Tbody, Tr, Th, Td, Menu, TableContainer, MenuButton, Button, Flex, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Box, MenuList, MenuItem, Text } from '@chakra-ui/react'
import { MaterialIcon } from '../../mini-lib/icons/MaterialIcon'
import { useAppSelector } from '../../hooks'
import { SelectInput, TextInput } from '../../mini-lib'
import { Gap } from '../../mini-lib/gap/Gap'
import { Formik } from 'formik'
import * as Yup from 'yup'
import { buildFormikProps } from '../../mini-lib/form/utils'
import { Supply } from '../../data/supplies/interfaces'
import { dollarsToCents } from '../../core/money/utils'
import { measurementTypeEnum } from './utils'
import { COLORS } from '../../mini-lib/theme/colors'


export const SuppliesPage = (props: { showBreadCrumbs?: boolean }) => {
  const { showBreadCrumbs = true } = props
  const {
    user: { token, userId },
    salonId,
  } = UseBaseApiParams()
  const dispatch = useDispatch()
  const { ITEM, ITEMS, APPLICATION } = measurementTypeEnum;

  const breadcrumbs = showBreadCrumbs ? [{ label: 'Home', url: generatePath(ROUTES.home, { salonId }) }, { label: 'Add-Ons' }] : []
  const models = useAppSelector(selectSupplyList)
  const releaseLaraSupplies = ReleaseLaraSupplies()

  const [isModelOpen, setIsModelOpen] = useState(false);
  const [selectedItem, setSelectedItem] = useState<any>(null);

  useEffect(() => {
    dispatch(dispatchListSupplies({ token, salonId, releaseLaraSupplies }))
  }, [dispatch, token, salonId, releaseLaraSupplies])

  useEffect(() => {
    !isModelOpen && setSelectedItem(null);
  }, [isModelOpen])

  const onSubmit = ({ name, price, measurementType, measurementValue, id }, { resetForm }) => {
    // API only accept item or application
    const measurementTypeUpdated = measurementType === ITEMS ? ITEM : measurementType
    dispatch(
      dispatchCreateOrUpdateSuppliesLara({
        token,
        userId,
        salonId,
        models: [{ name, price: dollarsToCents(price), measurement_type: measurementTypeUpdated, measurement_value: Number(measurementValue), id }],
        loadingId: 'creating-supplies',
      }),
    )
    setIsModelOpen(false);
    resetForm();
  }

  const deleteSupply = (supply: Supply) => {
    dispatch(
      dispatchDeleteSuppliesLara({
        token: token,
        salonId,
        models: [supply],
      }),
    )
  }

  const measurementOptions = [
    { label: 'per item', value: ITEM },
    { label: 'per # of items', value: ITEMS },
    { label: 'per application', value: APPLICATION }
  ]

  const formInitialValues = {
    id: selectedItem?.id ?? null,
    name: selectedItem?.name ?? '',
    price: selectedItem?.priceDollars ?? '',
    measurementType: selectedItem?.measurementType ?? ITEM,
    measurementValue: selectedItem?.measurementValue ?? '0',
  }

  const formValidationSchema = Yup.object({
    name: Yup.string().required('name is required'),
    price: Yup.string().required('price is required'),
    measurementType: Yup.string().required('measurement is required'),
    measurementValue: Yup.string().required('count is required'),
  })

  return (
    <>
      <PageLayout
        variant="full"
        header={<PageHeader showHelp={false} title="Add-Ons" breadcrumbs={breadcrumbs} actions={
          <Flex gridGap="12px">
            <Button colorScheme="brand.midnight" variant="round" onClick={() => { setIsModelOpen(true) }}>
              Add Item
            </Button>
          </Flex>
        } />}
        content={
          <>
            {/* <Gap s={'12px'} />
            <TextInput
              w='30%'
              variant="material"
              placeholder="Enter name"
              label="Name"
              value={name}
              errorText={''}
              onChange={(e) => setName(e)}
            />
            <Gap /> */}

            <TableContainer>
              <Table variant='simple'>
                <Thead height='53px' backgroundColor={'#f5f5f5'}>
                  <Tr>
                    <Th>Name</Th>
                    <Th>Cost</Th>
                    <Th></Th>
                    <Th></Th>
                  </Tr>
                </Thead>
                <Tbody>
                  {models?.map(({ id, name, priceDollars, measurementType, measurementValue }) => {
                    let displayMeasurementType = measurementType ?? ITEM
                    measurementType === ITEM && measurementValue > 1 && (displayMeasurementType = `${measurementValue} ${ITEMS}`)
                    return (
                      <Tr key={id}>
                        <Td>{name}</Td>
                        <Td>
                          <Flex>
                            <Text>${priceDollars} /</Text>
                            <Text color={COLORS.text_secondary} ml={1}>{displayMeasurementType}</Text>
                          </Flex>
                        </Td>
                        <Td w='20px'>
                          <Button 
                            fontWeight='bold'
                            color="brand.lavender.500"
                            padding="0px"
                            variant="round-ghost-lower"
                            onClick={() => {
                              setSelectedItem({ id, name, priceDollars, measurementType, measurementValue })
                              setIsModelOpen(true)
                            }}
                          >
                            <Text textTransform='none'>Edit</Text>
                          </Button>
                        </Td>
                        <Td w='20px'>
                          <Menu>
                            <MenuButton>
                              <MaterialIcon name="more_vert" />
                            </MenuButton>
                            <MenuList>
                              <MenuItem
                                color="black"
                                onClick={() => {
                                  deleteSupply({ id, name, priceDollars, measurementType, measurementValue })
                                }}
                              >
                                Delete
                              </MenuItem>
                            </MenuList>
                          </Menu>
                        </Td>
                      </Tr>
                    )
                  })}
                </Tbody>
              </Table>
            </TableContainer>
          </>
        }
      />
      <Formik onSubmit={onSubmit} initialValues={formInitialValues} validationSchema={formValidationSchema} enableReinitialize={true}>
        {({ handleSubmit, ...formikParams }) => {
          const measurementTypeFieldParams = buildFormikProps({ name: 'measurementType', formikParams })
          const nameFieldParams = buildFormikProps({ name: 'name', formikParams })
          const priceFieldParams = buildFormikProps({ name: 'price', formikParams })
          const measurementValueFieldParams = buildFormikProps({ name: 'measurementValue', formikParams })
          const modalHeader = formInitialValues.id ? `Edit ${formInitialValues.name}` : "Add Item"
          return (
            <Modal size={'sm'} onClose={() => {
              setSelectedItem(null);
              formikParams.resetForm();
              setIsModelOpen(false)
            }} isCentered isOpen={isModelOpen}>
              <ModalOverlay />
              <ModalContent>
                <ModalHeader>{modalHeader}</ModalHeader>
                <ModalCloseButton />
                <ModalBody>
                  <TextInput
                    variant='material'
                    label="Name"
                    value={nameFieldParams.value}
                    errorText={nameFieldParams.errorText}
                    onBlur={nameFieldParams.onBlur}
                    onChange={nameFieldParams.onChange}
                  />
                  <Gap />
                  <TextInput
                    variant='material'
                    label="Price"
                    type='number'
                    value={priceFieldParams.value}
                    errorText={priceFieldParams.errorText}
                    onBlur={priceFieldParams.onBlur}
                    onChange={priceFieldParams.onChange}
                  />
                  <Gap />
                  <Flex align='end' gridGap={3}>
                    <SelectInput
                      variant='material'
                      label="Measurement"
                      options={measurementOptions}
                      value={measurementTypeFieldParams.value}
                      errorText={measurementTypeFieldParams.errorText}
                      onBlur={(v) => { measurementTypeFieldParams.onBlur(v) }}
                      onChange={(v) => {
                        if (v === ITEM || v === APPLICATION) {
                          measurementValueFieldParams.onChange(0);
                        } else {
                          measurementValueFieldParams.onChange(2);
                        }
                        measurementTypeFieldParams.onChange(v)
                      }}
                      isClearable={false}
                    />
                    <Box w='200px'>
                      {measurementTypeFieldParams.value === 'items' && <TextInput
                        variant='material'
                        type='number'
                        value={measurementValueFieldParams.value}
                        errorText={measurementValueFieldParams.errorText}
                        onBlur={measurementValueFieldParams.onBlur}
                        onChange={measurementValueFieldParams.onChange}
                      />}
                    </Box>
                  </Flex>
                </ModalBody>
                <ModalFooter>
                  <Button colorScheme="brand.midnight" variant="round-outline" onClick={() => {
                    setSelectedItem(null);
                    setIsModelOpen(false);
                    formikParams.resetForm();
                  }}>Cancel</Button>
                  <Button ml={3} colorScheme="brand.midnight" variant="round" onClick={() => { handleSubmit() }}>Add</Button>
                </ModalFooter>
              </ModalContent>
            </Modal>
          )
        }}
      </Formik>
    </>
  )
}

