/////////////// utils.ts
//
//

import {
  ColorLara,
  LineNamesByVendor,
  LinesByVendor,
  Product,
  ProductFilters,
  STOCK_LEVEL_TYPES,
  StockLevel,
  Vendor,
  APIUpdateProduct,
  LineLara,
} from './interfaces'
import {has, keys, reduce, uniq} from 'lodash'

import { formatDollars } from "../../mini-lib/units/money";
import { ProductOnHandFilter, ProductPricingFilter, ProductTargetFilter } from "../sessions/interfaces";

export const FEATURED_BRANDS = ['redken', 'schwarzkopf', 'goldwell', 'aveda', 'pulp riot', 'l`oreal']

export const filterVendors = (
  vendors: Vendor[],
  searchText: string,
): { featuredVendors: Vendor[]; regularVendors: Vendor[] } => {
  const normalizedText = searchText.toLowerCase()
  const regularVendors: Vendor[] = []
  const featuredVendors: Vendor[] = []
  vendors.forEach((vendor) => {
    const normalizedName = vendor.name.toLowerCase()
    if (normalizedName.includes(normalizedText)) {
      if (FEATURED_BRANDS.includes(normalizedName)) {
        featuredVendors.push(vendor)
      } else {
        regularVendors.push(vendor)
      }
    }
  })

  return { featuredVendors, regularVendors }
}

export const filterLineNamesByVendor = (
  lineNamesByVendor: LineNamesByVendor,
  searchText: string,
): LineNamesByVendor => {
  const normalizedText = searchText.toLowerCase()
  const filteredLineNamesByVendor: LineNamesByVendor = {}
  keys(lineNamesByVendor).forEach((vendorName) => {
    // if matches vendor return all lines
    if (vendorName.toLowerCase().includes(normalizedText)) {
      filteredLineNamesByVendor[vendorName] = lineNamesByVendor[vendorName]
    }
    // if vendor doesnt match check lines
    const linesNames: string[] = lineNamesByVendor[vendorName]
    const filteredLines: string[] = []
    linesNames.forEach((name) => {
      if (name.toLowerCase().includes(normalizedText)) {
        filteredLines.push(name)
      }
    })
    if (filteredLines && filteredLines.length > 0) {
      filteredLineNamesByVendor[vendorName] = filteredLines
    }
  })
  return filteredLineNamesByVendor
}

export const filterLinesByVendor = (linesByVendor: LinesByVendor, searchText: string): LinesByVendor => {
  const normalizedText = searchText.toLowerCase()
  const filteredLinesByVendor: LinesByVendor = {}
  keys(linesByVendor).forEach((vendorName) => {
    // if matches vendor return color lines
    if (vendorName.toLowerCase().includes(normalizedText)) {
      filteredLinesByVendor[vendorName] = linesByVendor[vendorName]
    }
    // only filter out color lines
    // const lines: LineOption[] = linesByVendor[vendorName]
    // const filteredLines: LineOption[] = []
    // lines.forEach((line) => {
    //   if (line.lineName.toLowerCase().includes(normalizedText)) {
    //     filteredLines.push(line)
    //   }
    // })
    // if (filteredLines && filteredLines.length > 0) {
    //   filteredLinesByVendor[vendorName] = filteredLines
    // }
  })
  return filteredLinesByVendor
}

export const calculateMarkupNumber = (product: Product): number | null => {
  if (product?.pricing?.price && product?.inventory?.cost) {
    return Math.round((product.pricing.price / product.inventory.cost) * 100 - 100)
  }
  return null
}

export const getNumberOfProductsWithPrices = (products: Product[]): number => {
  return reduce(
    products,
    (sum, product) => {
      if (product.pricing.price) {
        return sum + 1
      }
      return sum
    },
    0,
  )
}

export const getCategoryNames = (products: Product[]): string[] => {
  const categories = products.map((product) => product.category)
  return uniq(categories)
}

export const getLevelNames = (products: Product[]): string[] => {
  const categories = products.map((product) => product.type)
  return uniq(categories)
}

// if there are no prices set or there is only one unique price across all products in the line show the bulk update input
// if there are multiple prices already set don't show it
export const shouldShowBulkUpdateInput = (products: Product[]): boolean => {
  const prices = products.map((product) => product.pricing.price)
  const uniqPrices = uniq(prices)
  return uniqPrices.length <= 1
}

export const getBulkUpdateInputValue = (products: Product[]): string | number | null => {
  const prices = products.map((product) => product.inventory.cost)
  const uniqPrices = uniq(prices)
  if (uniqPrices.length === 1) {
    return uniqPrices[0].toFixed(2)
  }
  return null
}

export const getMatchesPricingFilter = (product: Product, pricingFilter: ProductPricingFilter): boolean => {
  if (pricingFilter === 'hasPricing' && (!product.pricing.pricePerG || product.pricing.pricePerG === 0)) {
    return false
  }
  if (pricingFilter === 'noPricing' && product.pricing.pricePerG > 0) {
    return false
  }
  return true
}

export const getMatchesOnHandFilter = (product: Product, onHandFilter: ProductOnHandFilter): boolean => {
  if (onHandFilter === 'hasOnHand' && (!product.inventory.quantityOnHand)) {
    return false
  }
  if (onHandFilter === 'noOnHand' && product.inventory.quantityOnHand > 0) {
    return false
  }
  return true
}

export const getMatchesTargetFilter = (product: Product, onHandFilter: ProductTargetFilter): boolean => {
  if (onHandFilter === 'hasTarget' && (!product.inventory.maxStockLevel)) {
    return false
  }
  if (onHandFilter === 'noTarget' && product.inventory && product.inventory.maxStockLevel !== null) {
    return false
  }
  return true
}

export const getMatchesStockLevel = (product: Product, stockLevel: StockLevel | null): boolean => {
  // base case
  if (!stockLevel) {
    return true
  }
  return product.inventory.stockLevel === stockLevel
}

// follows lodash standards - returns true if the product should stay, returns false if the product should be omitted
// if no search or search is empty do not filter out the product
// if there is a search allow the product to stay if its type, line, or vendor match the search
export const filterProduct = (product: Product, filters: ProductFilters): boolean => {
  const { searchText, productName, productSize, pricingFilter, onHandFilter, targetFilter, stockLevel, lineName, categoryName, vendorName, lineOrType } =
    filters

  // normalize search and see if it exists
  const normalizedSearch = searchText ? searchText.toLowerCase() : ''
  const hasTypeSearch = normalizedSearch ? product.type.toLowerCase().includes(normalizedSearch) : true
  const hasLineSearch = normalizedSearch ? product.line.name.toLowerCase().includes(normalizedSearch) : true
  const hasVendorSearch = normalizedSearch ? product.vendor.name.toLowerCase().includes(normalizedSearch) : true
  const matchesSearchFilter = hasTypeSearch || hasLineSearch || hasVendorSearch

  const normalizedType = productName ? productName.toLowerCase() : ''
  const hasType = normalizedType ? product.type.toLowerCase().includes(normalizedType) : true

  const normalizedCategory = categoryName ? categoryName.toLowerCase() : ''
  const hasCategory = normalizedCategory ? product.category.toLowerCase().includes(normalizedCategory) : true

  const normalizedVendorName = vendorName ? vendorName.toLowerCase() : ''
  const hasVendor = normalizedVendorName ? product.vendor.name.toLowerCase() === normalizedVendorName : true

  const normalizedLineName = lineName ? lineName.toLowerCase() : ''
  const hasLine = normalizedLineName ? product.line.name.toLowerCase() === normalizedLineName : true

  const normalizedLineOrType = lineOrType ? lineOrType.toLowerCase() : ''
  const hasLineOrType = normalizedLineOrType ? (
    product.line.name.toLowerCase().includes(normalizedLineOrType) || 
    product.type.toLowerCase().includes(normalizedLineOrType)
    ) : true

  const hasSize = productSize ? product.size === productSize : true

  // get products with the right type of pricing
  const matchesPricingFilter = getMatchesPricingFilter(product, pricingFilter)

  // get products with the right type of pricing
  const matchesOnHandFilter = getMatchesOnHandFilter(product, onHandFilter)

  // get products with the right type of pricing
  const matchesTargetFilter = getMatchesTargetFilter(product, targetFilter)

  // get products with the right type of stock
  const matchesStockFilter = getMatchesStockLevel(product, stockLevel)

  // we want all filters to match for the product to be added
  return (
    matchesSearchFilter &&
    hasType &&
    hasLine &&
    hasLineOrType &&
    hasCategory &&
    hasSize &&
    hasVendor &&
    matchesPricingFilter &&
    matchesStockFilter &&
    matchesOnHandFilter &&
    matchesTargetFilter
  )
}

export const filterForProductsWithStockIssues = (products: Product[]): Product[] => {
  return products?.filter((product) => {
    return [STOCK_LEVEL_TYPES.out, STOCK_LEVEL_TYPES.over, STOCK_LEVEL_TYPES.low].includes(product.inventory.stockLevel)
  })
}

export const filterProducts = (products: Product[], filters: ProductFilters): Product[] => {
  return products.filter((product) => filterProduct(product, filters))
}

export const calculateInventoryCost = (products: Product[]): string => {
  const inventoryTotal = products.reduce((partialSum, product) => {
    const currentProductCost =
      product.inventory.amountGramsInStock > 0 ? product.inventory.costPerG * product.inventory.amountGramsInStock : 0
    return partialSum + currentProductCost
  }, 0)
  return formatDollars(inventoryTotal)
}

export const getNumFiltersActive = (filters: { [key: string]: string | null | any }): number => {
  let numActive = 0
  keys(filters).forEach((filterKey) => {
    if (filters[filterKey]) {
      numActive++
    }
  })
  return numActive
}

export const calculatePricePerGram = (params: { containerSize: number, prizePerContainer: number }): number => {
  const {containerSize, prizePerContainer} = params
  const pricePerGram: number = prizePerContainer / containerSize
  const roundedPricePerGram: number = Math.round(pricePerGram * 100) / 100
  return roundedPricePerGram
}

export const convertPricePerGramToPricePerOz = (params: { pricePerGram: number }): number => {
  const pricePerOz: number = params.pricePerGram / 28.3495
  const roundedPricePerOz: number = Math.round(pricePerOz * 100) / 100
  return roundedPricePerOz
}


export const getMatchesPricingFilterLara = (color: ColorLara, pricingFilter): boolean => {
  if (pricingFilter === 'hasPricing' && (!color.clientPurchaseCentsPerGram || color.clientPurchaseCentsPerGram === 0)) {
    return false
  }
  if (pricingFilter === 'noPricing' && color.clientPurchaseCentsPerGram > 0) {
    return false
  }
  return true
}

export const getMatchesStockLevelLara = (color: ColorLara, stockLevel: StockLevel | null): boolean => {
  // base case
  if (!stockLevel) {
    return true
  }
  return color.stockLevel === stockLevel
}

export const getUpdatedProducts = (params: {
  existingProducts: Product[]
  // todo: add booleans to set each property for this because null is valid value
  updatedProperties: {
    setCostDollars: boolean
    costDollars: string | null;

    markup: string | null;
    setMarkup: boolean

    setTarget: boolean
    target: string | null;

    setOnHand: boolean
    onHand: string | null
  }
}): APIUpdateProduct[] => {
  const { existingProducts, updatedProperties } = params
  const normalizedCostDollars = updatedProperties.costDollars ? parseFloat(updatedProperties.costDollars) : 0
  const normalizedMarkup = updatedProperties.markup ? parseFloat(updatedProperties.markup) : 0
  const normalizedTarget = updatedProperties.target ? parseFloat(updatedProperties.target) : 0
  const normalizedOnHand = updatedProperties.onHand ? parseFloat(updatedProperties.onHand) : 0

  const costValid = normalizedCostDollars >= 0
  const markupValid = normalizedMarkup >= 0
  const targetValid = normalizedTarget >= 0
  const onHandValid = normalizedOnHand >= 0

  if (existingProducts && existingProducts.length > 0 && costValid && markupValid && targetValid && onHandValid) {
    const updatedProducts: APIUpdateProduct[] = existingProducts.map((product) => {
      const calculatedMarkup = calculateMarkupNumber(product)
      const updatedProduct = {
        product_id: product.id,
        is_default_price: false,
      }

      // they want to update price and markup
      if (updatedProperties.setCostDollars && updatedProperties.setMarkup && (normalizedCostDollars !== product.inventory.cost || normalizedMarkup !== calculatedMarkup)) {
        updatedProduct['wholesale_price'] = normalizedCostDollars
        updatedProduct['mark_up'] = normalizedMarkup
      }

      // the just want to update price not markup
      if (updatedProperties.setCostDollars && !updatedProperties.setMarkup && (normalizedCostDollars !== product.inventory.cost || normalizedMarkup !== calculatedMarkup)) {
        updatedProduct['wholesale_price'] = normalizedCostDollars
        updatedProduct['mark_up'] = calculatedMarkup || 0
      }

      // the just want to update markup not price
      if (!updatedProperties.setCostDollars && updatedProperties.setMarkup && (normalizedCostDollars !== product.inventory.cost || normalizedMarkup !== calculatedMarkup)) {
        updatedProduct['wholesale_price'] = product.inventory.cost
        updatedProduct['mark_up'] = normalizedMarkup
      }

      if (updatedProperties.setTarget && normalizedTarget !== product.inventory.maxStockLevel) {
        updatedProduct['max_stock_level'] = normalizedTarget
      }
      // only update amount grams in stock if it's a single product and the value has changed
      if (updatedProperties.setOnHand && normalizedOnHand !== product.inventory.quantityOnHand && existingProducts.length === 1) {
        updatedProduct['amount_grams_in_stock'] = Math.round(normalizedOnHand * product.size)
      }
      return updatedProduct
    })
    return updatedProducts
  }
  return []
}


export const mapLinesLaraToLinesByVendor = ( lines: LineLara[] ): LinesByVendor => {
  const vendors: LinesByVendor = {}
  lines.forEach(line => {
    has(vendors, line.vendorName)
      ? ( vendors[line.vendorName] = [...vendors[line.vendorName], line] )
      : ( vendors[line.vendorName] = [line] )
  })
  const sortedVendors: LinesByVendor = {}
  keys(vendors).forEach(( key ) => {
    const linesForKey = vendors[key]
    sortedVendors[key] = uniq(linesForKey).sort()
  })
  return sortedVendors
}