/////////////// api.ts
//
//

import {Dispatch} from '@reduxjs/toolkit'

import { delay } from 'lodash'
import { reduceSetLoadingState } from '../../core/loading/slice'
import {DELETE_LABORS, LIST_LABORS, UPSERT_LABORS} from "./constants";
import {buildLaraConfig} from "../../mini-lib/lara/lara-utils";
import {GetServerBaseUrl} from "../../env";
import axios, {AxiosResponse} from "axios";
import {mapAPILaborsToLabors} from "./mappers";
import {APILabor, APILaborUpsert, Labor} from "./interfaces";
import {
  reduceDeleteLabors,
  reduceListLabors,
  reduceListLaborsForUser,
  reduceResetLabors,
  selectLaborsByCategory
} from "./slice";
import {RootState} from "../../store";

export const apiListLabors = (params: {
  token: string,
  salonId: number,
  userId?: number
}): Promise<Labor[]> => {

  const {token, salonId, userId} = params
  const config = buildLaraConfig({ token })
  const userIdParam = userId ? `?filter[user_id]=${userId}` : ''
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/labors${userIdParam}`
  // const url = `http://0.0.0.0/lara/api/v3/salons/${salonId}/labors${userIdParam}`
  return axios
    .get(url, config)
    .then((response: AxiosResponse<{ data: APILabor[] }>) => {
      return mapAPILaborsToLabors(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const dispatchListLabors = (params: {
  token: string,
  salonId: number,
  userId?: number
  pageNumber?: number,
  searchText?: string
}) => {
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({name: LIST_LABORS, state: true }))
    return apiListLabors(params).then((resp) => {
      dispatch(reduceResetLabors([]))
      dispatch(reduceListLabors(resp))
      dispatch(reduceSetLoadingState({name: LIST_LABORS, state: false }))
    })
  }
}

export const dispatchListLaborsForUser = (params: {
  token: string,
  salonId: number,
  userId: number
  pageNumber?: number,
  searchText?: string
}) => {
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({name: LIST_LABORS, state: true }))
    return apiListLabors(params).then((resp) => {
      dispatch(reduceListLaborsForUser(resp))
      dispatch(reduceSetLoadingState({name: LIST_LABORS, state: false }))
    })
  }
}

export const apiUpsertLabors = (params: {
  token: string
  userId: number
  salonId: number
  models: APILaborUpsert[]
}): Promise<Labor[]> => {
  const { token, salonId, models } = params
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/labors`
  // const url = `http://0.0.0.0/lara/api/v3/salons/${salonId}/labors`
  const body = {
    labors: models,
  }
  return axios
    .post(url, body, config)
    .then((response: any) => {
      return mapAPILaborsToLabors(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const dispatchUpsertLabors = (params: {
  token: string
  userId: number
  salonId: number
  loadingId?: string,
  models: APILaborUpsert[]
}) => {
  const {loadingId = UPSERT_LABORS} = params
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({ name: loadingId, state: true }))
    return apiUpsertLabors(params)
      .then((resp) => {
        dispatch(reduceListLabors(resp))
        delay(() => {
          dispatch(reduceSetLoadingState({ name: loadingId, state: false }))
        }, 2000)
      })
      .catch((error) => {
        throw error
      })
  }
}
export const apiDeleteLabors = (params: {
  token: string
  userId: number
  salonId: number
  models: Labor[]
}): Promise<any> => {
  const { token, salonId, models } = params
  const modelIds = models.map((model) => model.id).join(',')
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/labors?ids=${modelIds}`
  // const url = `http://0.0.0.0/lara/api/v3/salons/${salonId}/labors?ids=${modelIds}`
  return axios
    .delete(url, config)
    .then((response: any) => {
      return models
    })
    .catch((error) => {
      throw error
    })
}
export const dispatchDeleteLabors = (params: {
  token: string
  userId: number
  salonId: number
  loadingId?: string,
  models: Labor[],
}) => {
  const {loadingId = DELETE_LABORS} = params
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({ name: loadingId, state: true }))
    return apiDeleteLabors(params)
      .then((resp) => {
        dispatch(reduceDeleteLabors(resp))
        delay(() => {
          dispatch(reduceSetLoadingState({ name: loadingId, state: false }))
        }, 2000)
      })
      .catch((error) => {
        throw error
      })
  }
}

export const dispatchDeleteLaborsForCategory = (params: {
  token: string
  userId: number
  salonId: number
  category: string
  loadingId?: string,
}) => {
  const {
    token,
    userId,
    salonId,
    category,
    loadingId
  } = params

  return (dispatch: Dispatch | any, getState: () => RootState) => {
    const state = getState()
    const laborsByCategory = selectLaborsByCategory(state)
    const laborsForCategory = laborsByCategory ? laborsByCategory[category] : []
    dispatch(dispatchDeleteLabors({token, userId: userId, salonId: salonId, models: laborsForCategory, loadingId}))
  }
}