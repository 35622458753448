import React, {useEffect} from 'react'
import {useDispatch} from 'react-redux'
import {
  Box,
  Button,
  Circle,
  Flex,
  Menu,
  MenuButton,
  MenuList,
  Spinner,
  Tab,
  TabIndicator,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
  Text, Tooltip,
} from '@chakra-ui/react'
import {useAppSelector} from '../../hooks'
import {
  reduceIsLaborsSheetVisible,
  reduceIsNoteSheetVisible,
  reduceIsPhotoSheetVisible,
  reduceRedirectToSessionId,
  reduceSetActiveSessionDisplayUnit,
  selectActiveSessionLabors,
  selectActiveSessionMeta, selectIsActiveSessionWithoutLabor,
  selectRedirectToSessionId,
} from '../../data/sessions/slice'

import {PageHeader} from '../../mini-lib/page-header/PageHeader'
import {PageLayout} from '../../mini-lib/layouts/PageLayout'
import {UseBaseApiParams} from '../../core/UseBaseApiParams'
import {UseViewSize} from '../../core/UseViewSize'
import {generatePath, useHistory, useParams} from 'react-router-dom'
import {
  dispatchDeleteSessionMetaLara,
  dispatchGetSessionMetaLara,
  dispatchListSessionColorsLara,
  dispatchListSessionExtensionsLara,
  dispatchListSessionSuppliesLara,
  dispatchPatchSessionMetaLara,
} from '../../data/sessions/api'
import {APISessionMetaPatchLara, SessionMetaLara} from '../../data/sessions/interfaces'
import {AddSessionSupplies, SessionSupplies} from './session-supplies/SessionSupplies'
import {AddBowl, SessionBowls} from './session-colors/SessionBowls'
import {SessionMetaDetails, SessionMetaDetailsSheet} from './components/SessionMetaDetails'
import {AddSessionExtensions, SessionExtensions} from './session-extensions/SessionExtensions'
import {SessionPhotos, SessionPhotosSheet} from './components/SessionPhotos'
import {LOADING_SESSION_META, SESSION_STATUS_OPTIONS} from '../../data/sessions/constants'
import {ConfirmPopover, TextBadge} from '../../mini-lib'
import {ROUTES} from '../../appRoutes'
import {ReleaseLabour, ReleaseOpenDay} from '../../mini-lib/flags/Release'
import {dispatchListSessionLaborItemsLara} from '../../data/sessions/session-labor-legacy/api'
import {getLocalStorageItem} from '../../core/localStorage'
import {dispatchListSessionLabors} from '../../data/sessions/session-labor/api'
import {capitalize, orderBy} from 'lodash'
import {Gap} from '../../mini-lib/gap/Gap'
import {SessionSuppliesSheet} from './session-supplies/SessionSuppliesSheet'
import {SessionExtensionsSheet} from './session-extensions/SessionExtensionsSheet'
import {SessionBowlSheet} from './session-colors/SessionBowlSheet'
import {SessionStatusButton} from './components/SessionStatusButton'
import {SessionNotes, SessionNotesSheet} from './components/SessionNotes'
import {SessionLaborSheet} from './session-labors/SessionLaborSheet'
import {COLORS} from '../../mini-lib/theme/colors'
import {MaterialIcon} from '../../mini-lib/icons/MaterialIcon'
import {Icon} from '@chakra-ui/icons'
import {setNavVisible} from '../../mini-lib/sidenav/slice'
import {SessionCompleteDrawer} from "./components/SessionCompleteDrawer";
import {SessionCostSummary} from "./components/SessionCostSummary";
import {SessionWaste} from "./components/SessionWaste";
import {selectLaborsForUser} from "../../data/labor/slice";
import {dispatchListLaborsForUser} from "../../data/labor/api";
import {selectLoadingState} from "../../core/loading/slice";

export const SessionDetailsPage = () => {
  const {user, salonId} = UseBaseApiParams()
  const {isMobile} = UseViewSize()

  const linkUrl = generatePath(ROUTES.sessions, {salonId})
  const breadcrumbs = isMobile
    ? []
    : [
      {label: 'Home', url: generatePath(ROUTES.home, {salonId})},
      {label: 'Sessions', url: linkUrl},
      {label: 'Details'},
    ]
  const dispatch = useDispatch()
  const history = useHistory()
  const params: any = useParams()
  const {sessionId} = params
  const redirectToSessionId = useAppSelector(selectRedirectToSessionId)
  const releaseOpenDay = ReleaseOpenDay()
  const releaseLabor = ReleaseLabour()
  const sessionMetaData = useAppSelector(selectActiveSessionMeta)
  const loadingSessionMeta = useAppSelector(state => selectLoadingState(state, LOADING_SESSION_META))
  const sessionMeta = loadingSessionMeta ? null : sessionMetaData

  const sessionUserId = sessionMeta?.user.userId

  useEffect(() => {
    dispatch(reduceSetActiveSessionDisplayUnit(getLocalStorageItem('unit') ?? 'g'))
  }, [dispatch])

  useEffect(() => {
    if (sessionUserId) {
      dispatch(dispatchListLaborsForUser({token: user.token, salonId, userId: sessionUserId}))
    }
  }, [dispatch, user.token, salonId, sessionUserId])

  // reset the redirect id if the user came here from creating a session
  useEffect(() => {
    if (redirectToSessionId !== null) {
      dispatch(reduceRedirectToSessionId(null))
    }
  }, [dispatch, redirectToSessionId])

  // lara session apis
  //
  //
  useEffect(() => {
    if (user.token && salonId && sessionId) {
      dispatch(dispatchListSessionColorsLara({token: user.token, salonId, sessionId}))
    }
  }, [dispatch, user.token, salonId, sessionId])

  useEffect(() => {
    if (user.token && salonId && sessionId) {
      dispatch(dispatchListSessionExtensionsLara({token: user.token, salonId, sessionId}))
    }
  }, [dispatch, user.token, salonId, sessionId])

  useEffect(() => {
    if (user.token && salonId && sessionId) {
      dispatch(dispatchListSessionSuppliesLara({token: user.token, salonId, sessionId}))
    }
  }, [dispatch, user.token, salonId, sessionId])

  useEffect(() => {
    if (user.token && salonId && sessionId) {
      dispatch(dispatchGetSessionMetaLara({token: user.token, salonId, sessionId}))
    }
  }, [dispatch, user.token, salonId, sessionId])

  useEffect(() => {
    if (user.token && salonId && sessionId) {
      dispatch(dispatchListSessionLaborItemsLara({token: user.token, salonId, sessionId}))
    }
  }, [dispatch, user.token, salonId, sessionId])

  useEffect(() => {
    if (user.token && salonId && sessionId) {
      dispatch(dispatchListSessionLabors({token: user.token, salonId, sessionId}))
    }
  }, [dispatch, user.token, salonId, sessionId])

  //
  //
  //////

  const patchSessionMeta = (updatedProperties: APISessionMetaPatchLara) => {
    dispatch(dispatchPatchSessionMetaLara({token: user.token, salonId, sessionId, model: updatedProperties}))
  }
  return (
    <PageLayout
      hasUserNav={!isMobile}
      variant="full"
      padding={isMobile ? '0' : ''}
      header={
        isMobile ? null : (
          <PageHeader
            p='12px'
            title={
              sessionMeta && sessionMeta.status === SESSION_STATUS_OPTIONS.complete ? 'View Session' : 'Edit Session'
            }
            breadcrumbs={breadcrumbs}
            actions={
              <Flex gridGap="12px">
                {/*<Link to={generatePath(ROUTES.sessions, { salonId })}>*/}
                {/*  <Button variant="round-ghost-upper">Back</Button>*/}
                {/*</Link>*/}
                <ConfirmPopover
                  title={`This Action is Permanent`}
                  subtitle={`Removing this session means it will no longer appear in your salon.`}
                  onConfirm={() => {
                    if (sessionMeta) {
                      // dispatch(dispatchDeleteSession({ token: user.token, salonId, model }))
                      dispatch(dispatchDeleteSessionMetaLara({token: user.token, salonId, sessionId}))

                      const linkUrl = releaseOpenDay
                        ? generatePath(ROUTES.sessionsByMember, {salonId})
                        : generatePath(ROUTES.sessions, {salonId})
                      history.push(linkUrl)
                    }
                  }}
                >
                  <Button w="100px" variant="round-ghost-upper" color="danger" cursor="pointer">
                    {isMobile ? 'Delete' : 'Delete Session'}
                  </Button>
                </ConfirmPopover>
                {sessionMeta && <SessionStatusButton sessionMeta={sessionMeta}/>}
              </Flex>
            }
          />
        )
      }
      content={
        <>
          {isMobile && sessionMeta && <MobileSessionHeader sessionMeta={sessionMeta}/>}
          {isMobile && sessionMeta && sessionMeta.status === 'open' && <SessionCompleteDrawer sessionMeta={sessionMeta}/>}
          <SessionSuppliesSheet/>
          <SessionExtensionsSheet/>
          <SessionBowlSheet/>
          <SessionLaborSheet/>
          {sessionMeta && <SessionPhotosSheet sessionMeta={sessionMeta}/>}
          {sessionMeta && <SessionNotesSheet sessionMeta={sessionMeta} patchSessionMeta={patchSessionMeta}/>}
          {sessionMeta && <SessionMetaDetailsSheet sessionMeta={sessionMeta} patchSessionMeta={patchSessionMeta}/>}

          {sessionMeta && (
            <>
              <Tabs colorScheme="brand.lavender" variant="line">
                <TabList border="none" display="flex" justify="center" bg={isMobile ? COLORS.shades_neutral_100 : ''} >
                  <Tab border="none" fontSize="14px" w={isMobile ? '30%' : ''}>
                    {releaseLabor ? 'Services' : 'Session'}
                  </Tab>
                  <Tab border="none" fontSize="14px" w={isMobile ? '40%' : ''}>
                    Cost Summary
                  </Tab>
                  <Tab border="none" fontSize="14px" w={isMobile ? '30%' : ''}>
                    Details
                  </Tab>
                </TabList>
                <Flex bg={COLORS.shades_neutral_100}>
                  <TabIndicator mt='-4px' height="3px" borderRadius="50px 50px 0 0" bg={COLORS.lavender_500}/>
                </Flex>
                <Gap s="12px"/>
                <TabPanels>
                  <TabPanel>
                    {sessionMeta && <SessionDataTab sessionMeta={sessionMeta}/>}
                    <Gap s='150px'/>
                  </TabPanel>
                  <TabPanel>
                    <Box w={isMobile ? '100%' : '60%'} maxW='500px'>
                      <SessionCostSummary />
                      <Gap s='150px'/>
                    </Box>
                  </TabPanel>
                  <TabPanel>
                    <Box w={isMobile ? '100%' : '60%'} maxW='500px'>
                      <SessionMetaDetails sessionMeta={sessionMeta} patchSessionMeta={patchSessionMeta} isEditable={false}/>
                      <Gap/>
                      <SessionPhotos sessionMeta={sessionMeta} componentId="details-tab" variant='box'/>
                      <Gap/>
                      <SessionNotes sessionMeta={sessionMeta} patchSessionMeta={patchSessionMeta} variant='box'/>
                      <Gap s='150px'/>
                    </Box>
                  </TabPanel>
                </TabPanels>
              </Tabs>
            </>
          )}
          {!sessionMeta && (
            <Flex h="500px" align="center" justify="center">
              <Spinner/>
            </Flex>
          )}
        </>
      }
    />
  )
}

export const SessionDataTab = (props: { sessionMeta: SessionMetaLara }) => {
  const {sessionMeta} = props
  const {isMobile} = UseViewSize()

  const releaseLabour = ReleaseLabour()
  const isOldSessionWithoutLabor = useAppSelector(selectIsActiveSessionWithoutLabor)
  const sessionLabors = useAppSelector(selectActiveSessionLabors)
  const sortedSessionLabors = orderBy(sessionLabors, 'stampedName')


  return (
    <Box w={isMobile ? '100%' : '60%'} maxW='500px'>
      {sessionMeta.status === SESSION_STATUS_OPTIONS.open && <SessionActionBar isDisabled={!!isOldSessionWithoutLabor}/>}
      {releaseLabour &&
        sortedSessionLabors &&
        sortedSessionLabors.length > 0 &&
        sortedSessionLabors.map((sessionLabor) => {
          return (
            <Box key={sessionLabor.id}>
              <Gap/>
              <Text fontSize="20px" fontWeight="bold">
                {sessionLabor.stampedName}
              </Text>
              <Gap s="4px"/>
              <Flex gridGap="12px">
                <AddSessionExtensions sessionMeta={sessionMeta} laborId={sessionLabor.laborId}/>
                <AddSessionSupplies sessionMeta={sessionMeta} laborId={sessionLabor.laborId}/>
              </Flex>
              <Gap s="12px"/>
              <SessionBowls sessionMeta={sessionMeta} laborId={sessionLabor.laborId}/>
              <SessionExtensions sessionMeta={sessionMeta} laborId={sessionLabor.laborId}/>
              <SessionSupplies sessionMeta={sessionMeta} laborId={sessionLabor.laborId}/>
              <Gap s="12px"/>
              <AddBowl sessionMeta={sessionMeta} laborId={sessionLabor.laborId}/>
              <Gap s="12px"/>
              {/*<SessionWaste/>*/}

            </Box>
          )
        })}

      {(!releaseLabour || (sortedSessionLabors && sortedSessionLabors.length === 0)) && (
        <>
          <Box>
            <Gap/>
            <Text fontSize="20px" fontWeight="bold">
              Session Details
            </Text>
            <Gap s="4px"/>
            <Flex gridGap="12px">
              <AddSessionExtensions sessionMeta={sessionMeta} laborId={null}/>
              <AddSessionSupplies sessionMeta={sessionMeta} laborId={null}/>
            </Flex>
            <Gap s="12px"/>
            <SessionBowls sessionMeta={sessionMeta} laborId={null}/>
            <SessionExtensions sessionMeta={sessionMeta} laborId={null}/>
            <SessionSupplies sessionMeta={sessionMeta} laborId={null}/>
            <Gap s="12px"/>
            <AddBowl sessionMeta={sessionMeta} laborId={null}/>
            <Gap s="12px"/>
            <SessionWaste/>
          </Box>
        </>
      )}
      {/*{releaseLabour && <AddSessionLabor sessionMeta={sessionMeta}/>}*/}
    </Box>
  )
}

export const SessionActionBar = (props: {isDisabled?: boolean}) => {
  const {isDisabled = false} = props
  const dispatch = useDispatch()
  const {isMobile} = UseViewSize()
  const labors = useAppSelector(selectLaborsForUser)
  const releaseLabor = ReleaseLabour()
  return (
    <Flex gridGap="12px" justify={isMobile ? 'center' : 'start'}>
      {releaseLabor && labors && labors.length > 0 && (
        <>
          <Tooltip label={isDisabled ? 'Services cannot be added to Sessions that already have data' : ''}>
            <Box>
              <SquareButton
                isDisabled={isDisabled}
                label="Service"
                iconName="add_circle_outline"
                onClick={() => {
                  dispatch(reduceIsLaborsSheetVisible(true))
                }}
              />
            </Box>
          </Tooltip>
        </>
      )}
      {/*<SquareButton label='Timer' iconName='timer' onClick={() => {}}/>*/}
      <SquareButton
        label="Photo"
        iconName="photo_camera"
        onClick={() => {
          dispatch(reduceIsPhotoSheetVisible(true))
        }}
      />
      <SquareButton
        label="Note"
        iconName="description"
        onClick={() => {
          dispatch(reduceIsNoteSheetVisible(true))
        }}
      />
    </Flex>
  )
}

export const SquareButton = (props: { label: string; iconName: string; onClick: () => void, isDisabled?: boolean }) => {
  const {label, iconName, onClick, isDisabled = false} = props
  return (
    <Flex
      cursor="pointer"
      w="80px"
      justify="center"
      direction="column"
      align="center"
      gridGap="4px"
      bg={COLORS.shades_neutral_100}
      c={COLORS.midnight_500}
      fontSize="14px"
      p="4px 12px"
      borderRadius="8px"
      onClick={() => !isDisabled && onClick()}
    >
      <MaterialIcon name={iconName} colorhex={COLORS.midnight_500} variant="outline"/>
      <Text color={COLORS.midnight_500}>{label}</Text>
    </Flex>
  )
}

export const MobileSessionHeader = (props: { sessionMeta: SessionMetaLara }) => {
  const dispatch = useDispatch()
  const {sessionMeta} = props
  const {user, salonId} = UseBaseApiParams()
  const history = useHistory()
  const params: any = useParams()
  const {sessionId} = params
  return (
    <Flex direction="column" bg={COLORS.shades_neutral_100} justify="center" gridGap="12px" p='24px 0'>
      <Flex justify="space-between" align="center">
        <Circle p="8px" cursor="pointer " onClick={() => dispatch(setNavVisible(true))}>
          <MaterialIcon name="menu"/>
        </Circle>
        <Text fontWeight="bold" fontSize="24px">
          {sessionMeta && sessionMeta.client.firstName + ' ' + sessionMeta.client.lastName}
        </Text>
        <Menu>
          <MenuButton>
            <Icon as={() => <i className="material-icons-round">more_vert</i>}/>
          </MenuButton>
          <MenuList>
            <ConfirmPopover
              title={`This Action is Permanent`}
              subtitle={`Removing this session means it will no longer appear in your salon.`}
              onConfirm={() => {
                if (sessionMeta) {
                  dispatch(dispatchDeleteSessionMetaLara({token: user.token, salonId, sessionId}))
                  const linkUrl = generatePath(ROUTES.sessionsByMember, {salonId})
                  history.push(linkUrl)
                }
              }}
            >
              <Box pl="24px" color={COLORS.danger}>
                Delete Session
              </Box>
            </ConfirmPopover>
          </MenuList>
        </Menu>
      </Flex>
      <Flex justify="center">
        {sessionMeta && <SessionStatusIndicator sessionMeta={sessionMeta}/>}
      </Flex>
    </Flex>
  )
}

export const SessionStatusIndicator = (props: { sessionMeta: SessionMetaLara }) => {
  const {sessionMeta} = props
  return (
    <TextBadge
      w="80px"
      label={
        <Flex gridGap="4px" align="center">
          <Box
            h="8px"
            w="8px"
            borderRadius='50px'
            bg={sessionMeta.status === 'open' ? COLORS.success_500 : COLORS.lavender_500}
          ></Box>
          <Text>{capitalize(sessionMeta.status || '')}</Text>
        </Flex>
      }
      bgHex={sessionMeta.status === 'open' ? COLORS.success_100 : COLORS.lavender_100}
      cHex={'black'}
    />
  )
}