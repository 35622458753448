import { Client } from './interfaces'
import {UseBaseApiParams} from "../../core/UseBaseApiParams";
import {isSuperUser} from "../user/utils";

export const EMPTY_CLIENT = (): Client => {
  return {
    id: -1,
    firstName: '',
    lastName: '',
    phone: ''
  }
}

export const CLIENTS_MERGING_CONSTANT = 'merging-clients'
export const CLIENTS_LOADING_CONSTANT = 'loading-clients'
export const CREATING_CLIENT_CONSTANT = 'creating-client'

export const UseBulkUploadLimit = (): number => {
  const {user} = UseBaseApiParams()
  const isAdmin = isSuperUser(user)
  return isAdmin ? 20000 : 2500
}