import { PageLayout } from '../../mini-lib/layouts/PageLayout'
import React, { useEffect } from 'react'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { TrialDaysRemaining } from './TrialDaysRemaining'
import { Gap } from '../../mini-lib/gap/Gap'
import { Box, Button, Flex, Image, Text } from '@chakra-ui/react'
import { COLORS } from '../../mini-lib/theme/colors'
import { VIDEO_MAP } from '../../mini-lib/video/constants'
import { Video } from '../../mini-lib/video/Video'
import { UseViewSize } from '../../core/UseViewSize'
import whitneyLogo from '../../assets/trial/whitney-headshot.png'
import testimonial1 from '../../assets/trial/team-testimonial-1.png'
import testimonial2 from '../../assets/trial/team-testimonial-2.png'
import testimonial3 from '../../assets/trial/team-testimonial-3.png'
import soloTestimonial1 from '../../assets/trial/solo-testimonial-1.png'
import soloTestimonial2 from '../../assets/trial/solo-testimonial-2.png'
import soloTestimonial3 from '../../assets/trial/solo-testimonial-3.png'
import { CalendlyPopupWidget } from '../../integrations/calendly/calendly'
import { generatePath, Link } from 'react-router-dom'
import { UseIsSoloOrTeamTrial } from './hooks'
import { useDispatch } from 'react-redux'
import { dispatchListUserChecklistItems } from '../../data/start-guide/api'
import { ROUTES } from '../../appRoutes'
import { TrialQrCodesButtonAndModal } from './TrialQrCodes'
import mobileIcons from '../../assets/trial/mobile-icons.png'
import { PricingSteps } from '../start-guide-v2/SGIndex/SGPricingIndex'
import { TeamAndClientsSteps } from '../start-guide-v2/SGIndex/SGTeamAndClientsIndex'
import { ImplementationSteps } from '../start-guide-v2/SGIndex/SGImplementationIndex'
import { ExtrasSteps } from '../start-guide-v2/SGIndex/SGExtrasIndex'
import { InventorySteps } from '../start-guide-v2/SGIndex/SGInventoryIndex'
import { dispatchGetUserLara } from "../../data/user/api";

export const TrialIndexPage = () => {
  const dispatch = useDispatch()
  const { user, salonId } = UseBaseApiParams()
  // recall the user to ensure we have their subscription data
  useEffect(() => {
    dispatch(dispatchGetUserLara({token: user.token, userId: user.userId, salonId}))
  // eslint-disable-next-line
  }, [])

  useEffect(() => {
    dispatch(dispatchListUserChecklistItems(user.token, user.userId))
  }, [dispatch, user.token, user.userId])


  return (
    <>
      <PageLayout
        variant="full"
        header={<></>}
        content={
          <Box maxW="100%">
            <Gap />
            <TrialDaysRemaining />
            <Gap />
            <TrailHeader />
            {/*<TrialTasksLayout />*/}
            {/*<Gap />*/}
            {/*<TrialEightMinuteDemo />*/}
            <Gap s="48px" />
            <TrialMobileInfo />
            <Gap />
            <TrialSupport />
            <Gap />
            <PricingSteps isDefaultOpen={false} />
            <Gap size="24px" />
            <TeamAndClientsSteps isDefaultOpen={false} />
            <Gap size="24px" />
            <ImplementationSteps isDefaultOpen={false} />
            <Gap size="24px" />
            <Text color="text.secondary" variant="footnote" pl="12px">
              When you're ready:
            </Text>
            <Gap size="24px" />
            <ExtrasSteps isDefaultOpen={false} />
            <Gap size="24px" />
            <InventorySteps isDefaultOpen={false} />
            {/*<TrialTestimonials />*/}
          </Box>
        }
      />
    </>
  )
}

export const TrialEightMinuteDemo = () => {
  const { salonId } = UseBaseApiParams()
  const { isMobile } = UseViewSize()
  return (
    <Flex
      borderRadius="16px"
      border={`1px solid ${COLORS.shades_neutral_300}`}
      p="24px"
      wrap={isMobile ? 'wrap' : 'nowrap'}
      justify="center"
    >
      <Video
        video={VIDEO_MAP.trial8MinuteDemo}
        variant="miniplayer"
        height={isMobile ? '200px' : '170px'}
        width={isMobile ? '300px' : '300px'}
      />
      <Gap s={isMobile ? '24px' : '72px'} />
      <Box>
        <Text variant="title3">Let’s take a look inside SalonScale</Text>
        <Gap />
        <Text color={COLORS.text_secondary}>
          Watch a demo of how the SalonScale platform is helping thousands of salon’s turn their losses into profits.
          Connect your day-to-day backbar usage directly to your supply and learn how our inventory management system
          will save you hours of time each month.
        </Text>
        <Gap />
        <Link to={generatePath(ROUTES.checkoutV1Subscription, { salonId })}>
          <Button variant="round-outline" colorScheme="brand.lavender" w={isMobile ? '100%' : '200px'}>
            upgrade now
          </Button>
        </Link>
      </Box>
    </Flex>
  )
}

export const TrailHeader = () => {
  const { user } = UseBaseApiParams()
  return (
    <Box textAlign="center">
      <Text variant="largetitle" textTransform="uppercase" color={COLORS.midnight_900}>
        Welcome to your trial, {user.firstName}! 👋
      </Text>
      <Gap />
      <Text color={COLORS.midnight_900}>
        We are so excited you are here! Over the next 14 days you will have access to our favourite Weigh features
        including our cost calculator, remix feature, developer ratio calculator, notes, photos and more!
      </Text>
      <Gap />
      <Text color={COLORS.midnight_900}>
        Your mobile app has already been loaded with your products and sample prices, so what are you waiting for? Try
        it now!
      </Text>
    </Box>
  )
}

export const TrialSupport = () => {
  const { isMobile } = UseViewSize()
  return (
    <>
      <Flex
        borderRadius="16px"
        border={`1px solid ${COLORS.shades_neutral_300}`}
        p="24px 48px 24px 24px"
        justify="space-between"
        wrap={isMobile ? 'wrap' : 'nowrap'}
        gridGap="24px"
      >
        <Flex direction="column" justify="space-between" maxW="450px">
          <Text>
            As you explore SalonScale, feel free to schedule a call with one of our dedicated experts if you have any
            questions.
          </Text>
          {!isMobile && (
            <a href="https://helpcenter.salonscale.com/" target="_blank" rel="noreferrer">
              <Text fontSize="18px" cursor="pointer" color={COLORS.lavender_500}>
                Help Center | Chat Support
              </Text>
            </a>
          )}
        </Flex>

        <Flex align="center" gridGap="24px">
          <Image src={whitneyLogo} h={isMobile ? '100px' : '100px'} borderRadius="50%" />
          <Box>
            <Text variant="callout">Whitney Jones</Text>
            <Text variant="caption2" color={COLORS.text_secondary}>
              Talk with an expert
            </Text>
            <Gap />
            <Text as="div" colorScheme="brand.lavender" variant="round-outline" w="100%" p="0">
              {/* note: setting padding to 0 here so the calendly popup widget has a bigger click areay*/}
              <CalendlyPopupWidget
                text="BOOK A DEMO"
                styleOverrides={{
                  textTransform: 'uppercase',
                  fontSize: '16px',
                  fontWeight: '500',
                  padding: '6px 12px',
                }}
              />
            </Text>
          </Box>
        </Flex>
        {isMobile && (
          <a href="https://helpcenter.salonscale.com/" target="_blank" rel="noreferrer">
            <Text fontSize="18px" cursor="pointer" color={COLORS.lavender_500}>
              Help Center | Chat Support
            </Text>
          </a>
        )}
      </Flex>
    </>
  )
}

export const TrialTestimonials = () => {
  const { isMobile } = UseViewSize()
  const trialType = UseIsSoloOrTeamTrial()
  return (
    <Box border={`1px solid ${COLORS.shades_neutral_300}`} p="24px" textAlign="center" borderRadius="16px">
      <Text variant="title1">Learn how we are Transforming Salons</Text>
      <Gap />
      <Flex borderRadius="16px" justify="space-around" wrap={isMobile ? 'wrap' : 'nowrap'} gridGap="12px">
        {trialType === 'team' ? (
          <>
            <Image
              src={testimonial1}
              h={isMobile ? '300px' : '300px'}
              borderRadius="16px"
              border={`1px solid ${COLORS.shades_neutral_300}`}
            />
            <Image
              src={testimonial2}
              h={isMobile ? '300px' : '300px'}
              borderRadius="16px"
              border={`1px solid ${COLORS.shades_neutral_300}`}
            />
            <Image
              src={testimonial3}
              h={isMobile ? '300px' : '300px'}
              borderRadius="16px"
              border={`1px solid ${COLORS.shades_neutral_300}`}
            />
          </>
        ) : (
          <>
            <Image
              src={soloTestimonial1}
              h={isMobile ? '300px' : '300px'}
              borderRadius="16px"
              border={`1px solid ${COLORS.shades_neutral_300}`}
            />
            <Image
              src={soloTestimonial2}
              h={isMobile ? '300px' : '300px'}
              borderRadius="16px"
              border={`1px solid ${COLORS.shades_neutral_300}`}
            />
            <Image
              src={soloTestimonial3}
              h={isMobile ? '300px' : '300px'}
              borderRadius="16px"
              border={`1px solid ${COLORS.shades_neutral_300}`}
            />
          </>
        )}
      </Flex>
    </Box>
  )
}

export const TrialMobileInfo = () => {
  const { isMobile } = UseViewSize()
  return (
    <Flex
      justify="space-between"
      border={`1px solid ${COLORS.shades_neutral_300}`}
      p="24px"
      borderRadius="16px"
      wrap={isMobile ? 'wrap' : 'nowrap'}
      color={COLORS.midnight_900}
    >
      <Flex w={isMobile ? '100%' : '49.5%'} justify="center" align="center">
        {/* todo: need the video for this */}
        <Video
          video={VIDEO_MAP.trialWelcome}
          variant="miniplayer"
          height={isMobile ? '200px' : '250px'}
          width={isMobile ? '300px' : '400px'}
        />
      </Flex>
      <Box w={isMobile ? '100%' : '49.5%'}>
        <Text variant={isMobile ? 'title1mobile' : 'title1'} textAlign={isMobile ? 'center' : 'left'} fontWeight="bold">
          Get the most out of your trial
        </Text>
        <Gap />
        <Image src={mobileIcons} />
        <Gap />
        <Text variant="footnote" color={COLORS.text_secondary}>
          To get started with your trial, head to your mobile app store and download SalonScale for your Apple or
          Android device. Don’t forget to explore our favorite features - we think you will love them!
        </Text>
        <Gap />
        <TrialQrCodesButtonAndModal />
      </Box>
    </Flex>
  )
}
