import { DisplayUnit, SessionColorLara, SessionMetaLara, SessionStatus } from '../../../data/sessions/interfaces'
import { useDispatch } from 'react-redux'
import React from 'react'
import {
  convertDisplayUnits,
  getBowlMapLara,
  getBowlTitleLara,
  getSessionColorsByBowlLara,
  getTotalSessionColorCostLara,
} from '../../../data/sessions/utils'
import { Box, Divider, Flex, Text } from '@chakra-ui/react'
import { MaterialIcon } from '../../../mini-lib/icons/MaterialIcon'
import {
  reduceSelectedLaborId,
  reduceSetSelectedBowlId,
  selectActiveSessionColors,
  selectActiveSessionDisplayUnit, selectHighestBowlId,
} from '../../../data/sessions/slice'
import { COLORS } from '../../../mini-lib/theme/colors'
import { useAppSelector } from '../../../hooks'
import { SESSION_STATUS_OPTIONS } from '../../../data/sessions/constants'

import { formatCentsToDollars } from '../../../mini-lib/units/money'
import {Gap} from "../../../mini-lib/gap/Gap";

export const SessionBowls = (props: { sessionMeta: SessionMetaLara; laborId: number | null, isClickable?: boolean }) => {

  const { sessionMeta, laborId, isClickable = true } = props

  // lara session apis
  //
  //
  const displayUnit = useAppSelector(selectActiveSessionDisplayUnit)
  const sessionColors = useAppSelector((state) => selectActiveSessionColors(state, laborId))

  const bowlMapLara = sessionColors ? getBowlMapLara(sessionColors) : null
  const bowlListLara = bowlMapLara ? getSessionColorsByBowlLara(bowlMapLara) : null
  // const bowlColors = bowlMapLara && selectedBowlId ? bowlMapLara[selectedBowlId] : []

  return (
    <>
      <Box>
        {/*<Text>${sessionColors ? formatCentsToDollars(getTotalSessionColorCostLara(sessionColors, 'amount')) : 0}</Text>*/}
        {bowlListLara && bowlListLara.length > 0 && (
          <>
            <Flex direction="column" borderRadius="8px" gridGap='12px'>
              {bowlListLara?.map((bowlColors, index) => {
                return (
                  <SessionBowl
                    key={index}
                    isClickable={isClickable}
                    sessionStatus={sessionMeta?.status || SESSION_STATUS_OPTIONS.open}
                    displayUnit={displayUnit}
                    bowlColors={bowlColors}
                  />
                )
              })}
            </Flex>
          </>
        )}
      </Box>
    </>
  )
}

export const AddBowl = (props: {sessionMeta: SessionMetaLara, laborId: number | null}) => {
  const dispatch = useDispatch()
  const {sessionMeta, laborId} = props
  const highestBowlId = useAppSelector(selectHighestBowlId)
  const newBowlId = highestBowlId ? highestBowlId + 1 : 1
  return (
    <>
      {sessionMeta.status === SESSION_STATUS_OPTIONS.open && (
        <Flex
          p="12px"
          mb="12px"
          border={`1px solid ${COLORS.shades_neutral_300}`}
          borderRadius="8px"
          align="center"
          onClick={() => {
            dispatch(reduceSetSelectedBowlId(newBowlId))
            dispatch(reduceSelectedLaborId(laborId))
          }}
          cursor="pointer"
        >
          <MaterialIcon colorhex={COLORS.lavender_500} name="add_circle" style={{ marginRight: '4px' }} />{' '}
          <Text color="brand.lavender.500" fontWeight="bold">
            Add Bowl
          </Text>
        </Flex>
      )}
    </>
  )
}

export const SessionBowl = (props: {
  sessionStatus: SessionStatus
  displayUnit: DisplayUnit
  bowlColors: SessionColorLara[]
  isClickable: boolean
}) => {
  const dispatch = useDispatch()
  const { sessionStatus, displayUnit, bowlColors, isClickable } = props
  const { bowlType, bowlTag } = getBowlTitleLara(bowlColors[0])
  const bowlAmountTotal = getTotalSessionColorCostLara(bowlColors, 'amount')
  const bowlWasteTotal = getTotalSessionColorCostLara(bowlColors, 'waste')
  return (
    <>
      <Box
        border={`1px solid ${COLORS.shades_neutral_300}`}
        borderRadius="8px"
        p="12px"
        onClick={() => {
          if (isClickable && sessionStatus === SESSION_STATUS_OPTIONS.open) {
            dispatch(reduceSetSelectedBowlId(bowlColors[0].bowl))
            dispatch(reduceSelectedLaborId(bowlColors[0].laborId))
          }
        }}
        cursor={isClickable && sessionStatus === SESSION_STATUS_OPTIONS.open ? 'pointer' : ''}
        className="cy-session-color"
      >
        <Flex align="center" justify="space-between" gridGap="12px">
          <Text fontWeight="bold">
            {bowlType} {bowlType && bowlTag && '•'} {bowlTag}
          </Text>
          <Text fontWeight="bold">${formatCentsToDollars(bowlAmountTotal)}</Text>
        </Flex>
        {bowlWasteTotal > 0 && (
          <Flex align="center" justify="space-between" gridGap="12px" color={COLORS.text_secondary} fontSize='12px'>
            <Text>
              waste
            </Text>
            <Text>${formatCentsToDollars(bowlWasteTotal)}</Text>
          </Flex>
        )}

        <Gap s='8px'/>
        <Divider/>
        <Gap s='12px'/>
        <BowlFormula bowlColors={bowlColors} displayUnit={displayUnit} bowlView="amount" variant='line-items'/>
      </Box>
    </>
  )
}

export const BowlFormula = (props: {
  bowlView: 'amount' | 'waste'
  displayUnit: DisplayUnit
  bowlColors: SessionColorLara[]
  variant: 'formula' | 'line-items'
}) => {
  const { bowlColors, bowlView, variant = 'formula' } = props
  // many of our types have a + on the end of them, that is confusing here
  const cleanType = (type: string): string => (type.trim().slice(-1) === '+' ? type.slice(0, type.length - 1) : type)

  const text = bowlColors.reduce((text, item, index) => {
    const maybePlusSign = index + 1 !== bowlColors.length ? ' + ' : ''
    // note: the original units will always be grams because the api always sends amounts as grams
    if (bowlView === 'waste') {
      return `${text}${convertDisplayUnits(item.wasteGrams || 0, 'g', item.displayUnit)}${item.displayUnit} ${cleanType(
        item.product.type,
      )}${maybePlusSign}`
    }
    return `${text}${convertDisplayUnits(item.amountGrams, 'g', item.displayUnit)}${item.displayUnit} ${cleanType(
      item.product.type,
    )}${maybePlusSign}`
  }, '')
  if (variant === 'formula')
  return (
    <Text variant={bowlView === 'waste' ? 'footnote' : ''} color="text.secondary">
      {text}
    </Text>
  )
  // line items

  return (
    <Flex direction='column' gridGap='8px'>
      {bowlColors.map(item => {
        const amount = bowlView === "waste" ? item.wasteGrams : item.amountGrams
        return (
          <Flex justify='space-between' key={item.id}>
            <Box>
              <Text fontSize='12px'>
                {cleanType(item.product.type)}
              </Text>
              <Text fontSize='12px' color={COLORS.text_secondary}>
                {item.product.brand}
              </Text>
            </Box>
            <Text fontSize='12px' color={COLORS.text_secondary}>
              {convertDisplayUnits(amount || 0, 'g', item.displayUnit)}{item.displayUnit}
            </Text>
          </Flex>
        )
      })}
    </Flex>
  )
}
