import { Text, Divider, Menu, MenuButton, MenuItem, MenuList, Box, useDisclosure } from '@chakra-ui/react'
import { MaterialIcon } from "../icons/MaterialIcon";
import React from "react";
import { COLORS } from "../theme/colors";
import { ParseVish } from "../../integrations/vish/utils";

export const SuperUserClientMenu = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <Menu isOpen={isOpen} onOpen={onOpen} onClose={onClose} closeOnSelect={false}>
      <MenuButton onClick={onOpen}>
        <MaterialIcon name="more_vert" />
      </MenuButton>
      <MenuList bg='white' zIndex='10'>
        <Box m='12px'>
          <Text variant='button'>Super User Options</Text>
          <Text color={COLORS.danger} variant='headline'>only admins can see this menu</Text>
        </Box>
        <Divider />
        <MenuItem onClick={(e) => e.stopPropagation()}>
          <ParseVish />
        </MenuItem>
      </MenuList>
    </Menu>
  )
}