import React from 'react'
import { CsvUpload } from '../../mini-lib/csv/CsvUpload'
import {buildCsv} from "../../mini-lib/csv/buildCsv";
import {orderBy} from "lodash";

interface CSVRecord {
  amount: string
  client: string
  date: string
  employee: string
  id: string
  product: string
  service: string
}

interface ProductMeasurement {
  amount: string
  product: string
  formatted: string
}

interface Bowl {
  id: string
  measurements: ProductMeasurement[]
  formula: string
}

interface Appointment {
  bowls: Bowl[]
  client: string
  date: string
  employee: string
  id: string
}

interface ClientAppointments {
  client: string
  appointments: Appointment[]
  notes: string
}

const parseCSV = (fileRows: any[]) => {
  // get models
  const records: CSVRecord[] = fileRows.map((row: any) => ({
    amount: row['Amount'],
    client: row['Client'],
    date: row['Date'],
    employee: row['Employee'],
    id: row['ID'],
    product: row['Product'],
    service: row['Service'],
  }))

  // map to appointment
  //
  const recordsByAppointmentId = records.reduce((acc, record) => {
    const key = `${record.date.split(' ')[0]}_${record.client}`
    if (!acc[key]) acc[key] = []
    acc[key].push(record)
    return acc
  }, {} as Record<string, CSVRecord[]>)

  // iterate over appointments and make bowls
  //
  const appointments: Appointment[] = Object.entries(recordsByAppointmentId).map(([appointmentId, records]) => {
    const recordsById = records.reduce((acc, record) => {
      if (!acc[record.id]) acc[record.id] = []
      acc[record.id].push(record)
      return acc
    }, {} as Record<string, CSVRecord[]>)

    const bowls: Bowl[] = Object.entries(recordsById).map(([id, records]) => {
      const measurements = records.map((record) => ({
        amount: record.amount,
        product: record.product,
        formatted: `${record.date}: ${record.amount} ${record.product.split(' - ')[1]}`,
      }))
      const formula = measurements.map((m) => m.formatted).join(' + ')
      return {
        id,
        measurements,
        formula,
      }
    })

    return {
      bowls,
      client: records[0].client,
      date: records[0].date.split(' ')[0],
      employee: records[0].employee,
      id: appointmentId,
    }
  })

  appointments.sort((a, b) => a.date.localeCompare(b.date))

  const uniqueClients = Array.from(new Set(appointments.map((a) => a.client)))

  const clients: ClientAppointments[] = uniqueClients.map((client) => {
    const clientAppointments = appointments.filter((a) => a.client === client)
    let note = ''
    const dateSorter = (session) => session.date
    const sortedAppointments = orderBy(clientAppointments, [dateSorter], ['desc'])
    sortedAppointments.forEach((appointment) => {
      appointment.bowls.forEach((bowl) => {
        note += '\n\n' + bowl.formula
      })
    })
    return {
      client,
      appointments: clientAppointments,
      notes: note,
    }
  })

  // note: we dont use appointments yet but at some point me might try to make them into sessions or something
  return {clients, appointments}
}

export const buildSalonscaleCompatibleClientCSV = (clients: ClientAppointments[]) => {
  const titleRow = ['first_name', 'last_name', 'notes']
  const mapped = clients.map(client => {
    const split = client.client.split(' ')
    return {
      first_name: split.length > 0 ? split[0] : '',
      last_name: split.length > 1 ? split[1] : '',
      notes: client.notes
    }
  })
  buildCsv('salonscale-compatible-vish-clients', titleRow, mapped)
}

export const ParseVish = () => {
  const handleFileUpload = (fileRows: any) => {
    const {clients} = parseCSV(fileRows)
    buildSalonscaleCompatibleClientCSV(clients)
  }

  return <CsvUpload
    theme="midnight"
    variant="round-ghost"
    buttonLabel='Convert Vish CSV to Salonscale CSV'
    onFileUpload={(fileRows) => handleFileUpload(fileRows)}
  />
}
