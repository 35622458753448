import React from 'react'
import { SGLayout, SGTwoColumnContent } from '../start-guide-v2/SGLayout'
import { VIDEO_MAP } from '../../mini-lib/video/constants'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { generatePath } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { ROUTES } from '../../appRoutes'
import { SGActionButtons } from '../start-guide-v2/SGActionButtons'
import { getCurrentPaymentStatus } from '../../data/user/utils'
import { getCountryCode } from '../../mini-lib/time/utils'
import { getEnvConfig } from '../../config'
import { APIStripeCheckout } from '../../integrations/stripe/interfaces'
import { PLANS } from '../../integrations/stripe/constants'
import { dispatchStripeCheckout } from '../../integrations/stripe/api'
import { Text } from '@chakra-ui/react'

export const InventoryOrderPaywallPage = () => {
  const { user, salonId } = UseBaseApiParams()
  const dispatch = useDispatch()
  const paymentStatus = getCurrentPaymentStatus(user)
  const countryCode = getCountryCode()
  const config = getEnvConfig()
  const addInventory = () => {
    const isCad = countryCode === 'CA'
    const body: APIStripeCheckout = {
      plan: PLANS.inventory.id,
      billing_frequency: 'month',
      currency: isCad ? 'cad' : 'usd',
      success_url: `${config.reactHost}${generatePath(ROUTES.managePayment, { salonId })}`,
      cancel_url: `${config.reactHost}${generatePath(ROUTES.managePayment, { salonId })}`,
    }
    if (paymentStatus?.customerId) {
      body['stripe_customer_id'] = paymentStatus.customerId
    }
    dispatch(dispatchStripeCheckout({ token: user.token, userId: user.userId, salonId, body }))
  }
  return (
    <>
      <SGLayout>
        <SGTwoColumnContent
          variant='paywall'
          priceText={
            <>
              <Text fontWeight='bold' fontSize='20px'>
                $49.00 / month
              </Text>
            </>
          }
          iconName="inventory"
          nameOverride={true}
          title="Inventory & Orders Add-on"
          subtitle='Smart inventory Tracking for a more Profitable backbar'
          description={descriptionText}
          video={VIDEO_MAP.simplifyInventoryAndOrdering}
          actions={
            <SGActionButtons
              showGoBack={false}
              action={{
                label: 'Purchase',
                url: generatePath(ROUTES.inventoryOrdersPaywall, { salonId }),
                onClick: () => addInventory(),
              }}
              action2={{
                label: 'Learn More',
                url: 'https://www.salonscale.com/inventory-management',
                onClick: () => {},
                externalLink: true
              }}
            />
          }
          taskBar={<></>}
          imgPath={require('../../assets/start-guide/inventory.png').default}
        />
      </SGLayout>
    </>
  )
}

const descriptionText = `Say goodbye to ripping tabs. Easily see exactly what your products you have, what you need, and the total value of your backbar.

✓ Automatically track your color inventory

✓ Get stock alerts and uncover insights on your backbar

✓ Restock in 1-click, so you never run low on essential products

`
