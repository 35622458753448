import { UseBaseApiParams } from "../../../core/UseBaseApiParams";
import { PageLayout } from "../../../mini-lib/layouts/PageLayout";
import { PageHeader } from "../../../mini-lib/page-header/PageHeader";
import React, { useEffect } from "react";
import { generatePath, Link } from "react-router-dom";
import { UseBuildSignupQueryParams, UseCanSeeScales, UseInitializeSignupState } from "../../signup/v1/signupHooks";
import { Box, Button, Flex, Image, Text } from "@chakra-ui/react";
import { UseViewSize } from "../../../core/UseViewSize";
import { Gap } from "../../../mini-lib/gap/Gap";
import { COLORS } from "../../../mini-lib/theme/colors";
import { Scale } from "../../../data/signup/interfaces";
import { BLACK_SCALE, BLACK_MINI_SCALE, LINEN_SCALE } from "../../../data/signup/constants";
import {
  reduceSetSelectedFreeScale,
  selectScaleQuantitiesInStockForCountryById
} from "../../../data/signup/slice";
import { useDispatch } from "react-redux";
import { ROUTES } from "../../../appRoutes";
import { useAppSelector } from "../../../hooks";
import { dispatchListScaleQuantitiesInStock } from "../../../data/signup/api";
import { Loading } from "../../../mini-lib/loading/Loading";
import { EmptyBox } from "../../../mini-lib/empty/EmptyBox";
import {BlackMiniScaleAvailable, BlackV1ScaleAvailable, LinenScaleAvailable} from "../../../mini-lib/flags/PermanentFlags";

export const CheckoutFreeScalesPage = () => {
  const { isMobile } = UseViewSize()
  const breadcrumbs = []
  const dispatch = useDispatch()
  const {user, salonId} = UseBaseApiParams()
  UseInitializeSignupState()

  useEffect(() => {
      dispatch(
        dispatchListScaleQuantitiesInStock({ token: user.token}),
      )
  }, [dispatch, user.token])

  const scaleQuantitiesInStockById = useAppSelector(selectScaleQuantitiesInStockForCountryById)
  const linenScalesAvailable = LinenScaleAvailable()
  const blackV1ScalesAvailable = BlackV1ScaleAvailable()
  const blackMiniScalesAvailable = BlackMiniScaleAvailable()
  const hasBlackScales = scaleQuantitiesInStockById ? blackV1ScalesAvailable && scaleQuantitiesInStockById[BLACK_SCALE.id]?.quantity > 0 : null
  const hasLinenScales = scaleQuantitiesInStockById ? linenScalesAvailable && scaleQuantitiesInStockById[LINEN_SCALE.id]?.quantity > 0 : null
  const hasBlackMiniScales = scaleQuantitiesInStockById ? blackMiniScalesAvailable && scaleQuantitiesInStockById[BLACK_MINI_SCALE.id]?.quantity > 0 : null
  const canSeeScales = UseCanSeeScales()
  return (
    <>
      <PageLayout
        variant="full"
        header={<PageHeader title='' breadcrumbs={breadcrumbs}/>}
        content={
          <Flex justify='space-around' direction='column' align='center'>
            <>
              <Flex justify='start' w='100%'>
                <Link
                  to={{
                    pathname: generatePath(ROUTES.checkoutV1Subscription, { salonId }),
                    search: UseBuildSignupQueryParams(),
                  }}
                >
                  <Button variant='round-outline' colorScheme='brand.midnight'>Back</Button>
                </Link>
              </Flex>
              {isMobile && <Gap/>}
              <Text
                align='center'
                variant={isMobile ? 'largetitle' : 'gigantictext'}
                color="brand.midnight.900"
              >
                YOU GET A FREE GIFT, YAY!
              </Text>

              <Gap/>

              <Text maxW='400px' textAlign='center'>
                Your paid plan comes with a free bluetooth scale. Select your free scale before continuing.
              </Text>

              <Gap s='72px'/>
            </>

            <Flex justify={isMobile ? 'center' : 'space-between'} gridGap='24px' w={isMobile ? '100%' : ''} wrap={isMobile ? 'wrap' : 'nowrap'}>
              {!canSeeScales && <EmptyBox title="No Scales Available" content="Scales are unavailable in your country" />}
              {!scaleQuantitiesInStockById && <Loading/>}
              {scaleQuantitiesInStockById && hasBlackScales && <ScaleCard scale={BLACK_SCALE}/>}
              {scaleQuantitiesInStockById && hasBlackMiniScales && <ScaleCard scale={BLACK_MINI_SCALE}/>}
              {scaleQuantitiesInStockById && hasLinenScales && <ScaleCard scale={LINEN_SCALE}/>}
            </Flex>
            <Gap/>
            <Text fontStyle='italic'>*scales only ship to USA and Canada</Text>
          </Flex>
        }
      />
    </>
  )
}

export const ScaleCard = ( props: { scale: Scale } ) => {
  const dispatch = useDispatch()
  const { scale } = props
  const { isMobile } = UseViewSize()
  const {salonId} = UseBaseApiParams()
  const blackMiniScaleAvailable = BlackMiniScaleAvailable()
  const onScaleSelect = () => {
    dispatch(reduceSetSelectedFreeScale(scale))
  }
  return (
    <Flex w={isMobile ? '100%' : ''} justify='center'>
      <Flex
        direction='column'
        justify='space-between'
        w='350px'
        bg={COLORS.shades_neutral_0}
        border={`1px solid ${COLORS.shades_neutral_300}`}
        borderRadius='16px'
      >
        <Flex justify='center' align='center'>
          <Image src={scale.imgUrl} h={isMobile ? '250px' : '300px'}/>
        </Flex>
        <Flex p='24px' justify='space-between' borderTop={`1px solid ${COLORS.shades_neutral_300}`}>
          <Box>
            <Text fontWeight='bold'>Bluetooth Scale - {scale.label}</Text>
            <Box>
              <Text as='span' textDecoration='line-through'>$60.00</Text>
              {' '}
              <Text as='span' fontWeight='bold' color={COLORS.lavender_500}>FREE</Text>
            </Box>
          </Box>
        </Flex>
        <Box p='0 24px 24px 24px'>
          <Link
            to={{
              pathname: blackMiniScaleAvailable ? generatePath(ROUTES.checkoutV1PaidScale, { salonId }): generatePath(ROUTES.checkoutV1Cart, { salonId }),
              search: UseBuildSignupQueryParams({freeScale: scale}),
            }}
          >
            <Button w='100%' variant='round-outline' colorScheme='midnight' onClick={onScaleSelect}>CLAIM FREE SCALE</Button>
          </Link>
        </Box>
      </Flex>
    </Flex>
  )
}
