import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { useAppSelector } from '../../../hooks'
import { useDispatch } from 'react-redux'
import {
  APICreateOrUpdateSessionColorLara,
  BowlType, DisplayUnit,
  SessionColorLara,
} from '../../../data/sessions/interfaces'
import {
  reduceSelectedLaborId,
  reduceSetSelectedBowlId, selectActiveSessionColors,
  selectSelectedBowlId, selectSelectedLaborId
} from '../../../data/sessions/slice'
import { find, sumBy } from 'lodash'
import { Product } from '../../../data/products/interfaces'
import { DisableAutofocus } from '../../../mini-lib/disable-autofocus/DisableAutofocus'
import { ProductSelectSheet } from '../../products/products/ProductSelectSheet'
import { SessionBowlColorsView } from './SessionBowlColorsView'
import { SessionBowlWasteView } from './SessionBowlWasteView'
import { SessionBowlEmptyBowlsView } from './SessionBowlEmptyBowlsView'
import { EmptyBowl } from '../../../data/empty-bowls/interfaces'
import { SessionBowlEmptyBowlDetailsView } from './SessionBowlEmptyBowlDetailsView'
import { BOWL_SHEET_VIEW_TYPES_LARA, BowlSheetViewTypeLara } from '../../../data/sessions/constants'
import { UseBaseApiParams } from "../../../core/UseBaseApiParams";
import { dispatchCreateOrUpdateSessionColorsLara, dispatchDeleteSessionColorLara } from "../../../data/sessions/api";
import {getBowlMapLara} from "../../../data/sessions/utils";
import {useParams} from "react-router-dom";

export const SessionBowlSheet = () => {
  const dispatch = useDispatch()
  const selectedBowlId = useAppSelector(selectSelectedBowlId)

  const selectedLaborId = useAppSelector(selectSelectedLaborId)
  const sessionColors = useAppSelector((state) => selectActiveSessionColors(state, selectedLaborId))


  const params: any = useParams()
  const { sessionId } = params

  const bowlMapLara = sessionColors ? getBowlMapLara(sessionColors) : null
  const bowlColors = bowlMapLara && selectedBowlId ? bowlMapLara[selectedBowlId] : []

  const {user, salonId} = UseBaseApiParams()

  const initialWasteTotal = sumBy(bowlColors, 'wasteGrams')
  const [wasteTotalGrams, setWasteTotalGrams] = useState<string>(initialWasteTotal ? initialWasteTotal.toString() : '')

  const [selectedEmptyBowl, setSelectedEmptyBowl] = useState<Partial<EmptyBowl> | null>(null)
  const [emptyBowlWeight, setEmptyBowlWeight] = useState<string>('0')
  const [emptyBowl, setEmptyBowl] = useState<EmptyBowl | null>(null)

  const [showProductSheet, setShowProductSheet] = useState(false)
  const [wasteRecentlyAdded, setWasteRecentlyAdded] = useState(false)
  const [bowlView, setBowlView] = useState<BowlSheetViewTypeLara>(BOWL_SHEET_VIEW_TYPES_LARA.amount)

  const savedBowlWaste = sumBy(bowlColors, 'wasteGrams')
  const isBowlDisabled = savedBowlWaste > 0 || wasteRecentlyAdded

  const totalBowlAmount = sumBy(bowlColors, BOWL_SHEET_VIEW_TYPES_LARA.amount)
  const bowlType = bowlColors && bowlColors.length > 0 ? bowlColors[0].bowlType : null

  const updateSessionColor = (params: {sessionColor: SessionColorLara, amount?: number, waste?: number, displayUnit?: DisplayUnit}) => {
    const {sessionColor, amount, waste, displayUnit } = params
    const updatedSessionColor: APICreateOrUpdateSessionColorLara = {
      id: sessionColor.id,
      product_id: sessionColor.product.id,

      // note: this might need some improvement given that we are nut actually using session wide units
      // this works currently because everything is stored as grams the entire time anyway
      // if we start letting them set the units through the ui we will need to check the units of each session item
      // then using those units we can decide to normalize to grams or not
      //
      amount_grams: amount !== null && amount !== undefined ? amount : sessionColor.amountGrams || 0,
      waste_grams: waste !== null ? waste :  sessionColor.wasteGrams || 0,
      //
      //
      bowl: selectedBowlId || 1,
      bowl_type_id: bowlType?.bowltypeId || null,
      bowl_nickname: sessionColor.bowlNickname || null,
      display_unit: displayUnit || sessionColor.displayUnit,
      // method_of_entry: 'manual',
      // bowl_nickname: 'john bowl'
    }
    if (selectedLaborId) {
      updatedSessionColor['labor_id'] = selectedLaborId
    }
    dispatch(dispatchCreateOrUpdateSessionColorsLara({ token: user.token, salonId, sessionId: sessionId, models: [updatedSessionColor] }))
  }

  const updateBowlProducts = (colorsToAdd: Product[]) => {
    const newColors: APICreateOrUpdateSessionColorLara[] = []

    colorsToAdd.forEach(( color ) => {
      // don't re add any colors that already exist
      const alreadyExists: SessionColorLara | undefined = find(
        bowlColors,
        ( item ) => item?.product.id === color.id,
      )
      if (!alreadyExists) {
        const newSessionColor: APICreateOrUpdateSessionColorLara = {
          product_id: color.id,
          amount_grams: 0,
          bowl: selectedBowlId || 1,
          bowl_type_id: bowlType?.bowltypeId || null,
          bowl_nickname: '',
        }
        if (selectedLaborId) {
          newSessionColor['labor_id'] = selectedLaborId
        }
        newColors.push(newSessionColor)
      }
    })
    const colorsToDelete: SessionColorLara[] = []
    bowlColors?.forEach(sessionColor => {
      const existsInColorsToAdd = find(colorsToAdd, ( color ) => color.id === sessionColor.product.id)
      if (!existsInColorsToAdd) {
        colorsToDelete.push(sessionColor)
      }
    })
    if (colorsToDelete.length > 0) {
      const idsToDelete = colorsToDelete.map(ext => ext.id)
      dispatch(dispatchDeleteSessionColorLara({
        token: user.token,
        salonId,
        sessionId: sessionId,
        sessionColorIds: idsToDelete
      }))
    }
    dispatch(dispatchCreateOrUpdateSessionColorsLara({
      token: user.token,
      salonId,
      sessionId: sessionId,
      models: newColors
    }))
  }

  const onSave = () => {
    dispatch(reduceSetSelectedBowlId(null))
    dispatch(reduceSelectedLaborId(null))
  }

  const onSaveWaste = () => {
    const updatedBowlColors: APICreateOrUpdateSessionColorLara[] = bowlColors.map((item) => {
      const itemPercentage = item.amountGrams / totalBowlAmount
      const normalizedWaste = parseFloat(wasteTotalGrams)
      const totalColorWaste = normalizedWaste - parseFloat(emptyBowlWeight)
      const calculatedWaste = Math.round(itemPercentage * totalColorWaste * 100) / 100
      const updatedColor = {
        id: item.id,
        product_id: item.product.id,
        amount_grams: item.amountGrams || 0,
        bowl: item.bowl,
        waste_grams: calculatedWaste,
        bowl_nickname: item.bowlNickname || null,
        bowl_type_id: bowlType?.bowltypeId || null,
        display_unit: item.displayUnit || 'g'
      }
      if (selectedLaborId) {
        updatedColor['labor_id'] = selectedLaborId
      }
      return updatedColor
    })
    dispatch(dispatchCreateOrUpdateSessionColorsLara({ token: user.token, salonId, sessionId: sessionId, models: updatedBowlColors }))
    setWasteRecentlyAdded(true)
    setBowlView(BOWL_SHEET_VIEW_TYPES_LARA.amount)

  }

  const onDeleteSessionItem = (sessionColor: SessionColorLara) => {
      dispatch(dispatchDeleteSessionColorLara({
        token: user.token,
        salonId,
        sessionId: sessionId,
        sessionColorIds: [sessionColor.id]
      }))
  }
  const onDeleteBowl = () => {
    const idsToDelete = bowlColors ? bowlColors.map(ext => ext.id) : []
      dispatch(dispatchDeleteSessionColorLara({
        token: user.token,
        salonId,
        sessionId: sessionId,
        sessionColorIds: idsToDelete
      }))
    dispatch(reduceSetSelectedBowlId(null))
  }


  const onBowlTypeSelect = (type: BowlType | null) => {
    const updatedBowlColors: APICreateOrUpdateSessionColorLara[] = bowlColors.map((bowlItem) => {
      return {
        id: bowlItem.id,
        product_id: bowlItem.product.id,
        amount_grams: bowlItem.amountGrams || 0,
        bowl_type_id: type?.bowltypeId || null,
        bowl_nickname: bowlItem.bowlNickname || null,
        bowl: bowlItem.bowl,
        labor_id: bowlItem.laborId
      }
    })

    dispatch(dispatchCreateOrUpdateSessionColorsLara({ token: user.token, salonId, sessionId: sessionId, models: updatedBowlColors }))
  }

  const onBowlTagChange = (bowlTagValue: string) => {
    const updatedBowlColors: APICreateOrUpdateSessionColorLara[] = bowlColors.map((bowlItem) => {
      const updatedColor = {
        id: bowlItem.id,
        product_id: bowlItem.product.id,
        amount_grams: bowlItem.amountGrams || 0,
        bowl: bowlItem.bowl,
        bowl_nickname: bowlTagValue,
        bowl_type_id: bowlType?.bowltypeId || null
      }
      if (selectedLaborId) {
        updatedColor['labor_id'] = selectedLaborId
      }
      return updatedColor
    })
    dispatch(dispatchCreateOrUpdateSessionColorsLara({ token: user.token, salonId, sessionId: sessionId, models: updatedBowlColors }))
  }

  const selectedProductIds = bowlColors ? bowlColors.map((item) => item.product.id) :  []
  return (
    <>
      <Drawer
        size="md"
        isOpen={!!selectedBowlId}
        placement="right"
        onClose={() => {
          dispatch(reduceSetSelectedBowlId(null))
          dispatch(reduceSelectedLaborId(null))
        }}
      >
        {showProductSheet && (
          <ProductSelectSheet
            selectedProductIds={selectedProductIds}
            show={showProductSheet}
            onHide={() => setShowProductSheet(false)}
            onProductsSelected={(products) => {
              updateBowlProducts(products)
              setShowProductSheet(false)
            }}
          />
        )}
        <DrawerOverlay />
        <DrawerCloseButton />
        <DrawerContent>
          <DrawerBody>
            <>
              <DisableAutofocus />
              {bowlView === BOWL_SHEET_VIEW_TYPES_LARA.amount && (
                <SessionBowlColorsView
                  onDeleteBowl={onDeleteBowl}
                  onSave={onSave}
                  setBowlView={setBowlView}
                  setShowProductSheet={setShowProductSheet}
                  sessionColors={bowlColors}
                  onBowlTypeSelect={onBowlTypeSelect}
                  onBowlTagChange={onBowlTagChange}
                  onDeleteSessionItem={onDeleteSessionItem}
                  updateSessionColor={updateSessionColor}
                  isDisabled={isBowlDisabled}
                />
              )}
              {bowlView === BOWL_SHEET_VIEW_TYPES_LARA.waste && (
                <SessionBowlWasteView
                  totalBowlAmount={totalBowlAmount}
                  onSaveWaste={onSaveWaste}
                  emptyBowl={emptyBowl}
                  setBowlView={setBowlView}
                  updatedBowl={bowlColors}
                  wasteTotalGrams={wasteTotalGrams}
                  setWasteTotalGrams={setWasteTotalGrams}
                  emptyBowlWeight={emptyBowlWeight}
                  setEmptyBowlWeight={setEmptyBowlWeight}
                  isDisabled={isBowlDisabled}
                />
              )}
              {bowlView === BOWL_SHEET_VIEW_TYPES_LARA.emptyBowls && (
                <SessionBowlEmptyBowlsView
                  setSelectedEmptyBowl={setSelectedEmptyBowl}
                  setBowlView={setBowlView}
                  onEmptyBowlSelect={(bowl) => {
                    setEmptyBowl(bowl)
                    setEmptyBowlWeight(bowl.amountInGrams.toString())
                    setBowlView(BOWL_SHEET_VIEW_TYPES_LARA.waste)
                  }}
                />
              )}
              {bowlView === BOWL_SHEET_VIEW_TYPES_LARA.emptyBowlDetails && (
                <SessionBowlEmptyBowlDetailsView
                  selectedEmptyBowl={selectedEmptyBowl}
                  setSelectedEmptyBowl={setSelectedEmptyBowl}
                  setBowlView={setBowlView}
                  onEmptyBowlSelect={(bowl) => {
                    setEmptyBowl(bowl)
                    setEmptyBowlWeight(bowl.amountInGrams.toString())
                    setBowlView(BOWL_SHEET_VIEW_TYPES_LARA.waste)
                  }}
                />
              )}
            </>
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}



export const SaveSessionModal = (props: {
  isOpen: boolean
  onClose: () => void
  onCancel: () => void
  onSave: () => void
}) => {
  const { isOpen, onClose, onCancel, onSave } = props
  return (
    <>
      <Modal isOpen={isOpen} onClose={onCancel}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />
          <ModalBody>
            <Box h="36px" />
            Are you sure you want to leave, any changes you made will be lost!
          </ModalBody>
          <ModalFooter>
            <Flex gridGap="12px">
              <Button variant="round-outline" onClick={onClose}>
                Close without saving
              </Button>
              <Button variant="round" onClick={onSave}>
                Save and Close
              </Button>
            </Flex>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  )
}
