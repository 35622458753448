
// session labor apis

import {GetServerBaseUrl} from "../../../env";
import axios from "axios";
import {Dispatch} from "@reduxjs/toolkit";
import {reduceSetLoadingState} from "../../../core/loading/slice";
import {APISessionLabor, APIUpsertSessionLabor, SessionLabor} from "./interfaces";
import {buildLaraConfig} from "../../../mini-lib/lara/lara-utils";
import {mapAPISessionLaborsToSessionLabors} from "./mappers";
import {reduceActiveSessionLabors, reduceDeleteActiveSessionLabors, reduceUpdateActiveSessionLabors} from "../slice";
import {LOADING_SESSION_LABOR_DELETE, LOADING_SESSION_LABOR_LIST, LOADING_SESSION_LABOR_UPSERT} from "../constants";

export const apiListSessionLabors = (params: {
  token: string
  salonId: number
  sessionId: number
}): Promise<SessionLabor[]> => {
  const {token, salonId, sessionId} = params
  const config = buildLaraConfig({token})
  const url = `${GetServerBaseUrl('v3', 'lara',)}/salons/${salonId}/sessions/${sessionId}/labors`
  // const url = `http://0.0.0.0/lara/api/v3/salons/${salonId}/sessions/${sessionId}/labors`

  return axios
    .get(url, config)
    .then((response: { data: {data: APISessionLabor[]} }) => {
      return mapAPISessionLaborsToSessionLabors(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiUpsertSessionLabors = (params: {
  token: string
  salonId: number
  sessionId: number
  models: APIUpsertSessionLabor[]
}): Promise<SessionLabor[]> => {
  const {token, salonId, sessionId, models} = params

  const config = buildLaraConfig({token})
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/sessions/${sessionId}/labors`
  // const url = `http://0.0.0.0/lara/api/v3/salons/${salonId}/sessions/${sessionId}/labors`

  return axios
    .post(url, models, config)
    .then((response: { data: {data: APISessionLabor[]} }) => {
      return mapAPISessionLaborsToSessionLabors(response.data.data)
    })
    .catch((error) => {
      throw error
    })
}

export const apiDeleteSessionLabors = (params: {
  token: string
  salonId: number
  sessionId: number
  models: SessionLabor[]
}): Promise<any> => {
  const { token, salonId, sessionId, models } = params
  const modelIds = models.map((model) => model.id).join(',')
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/sessions/${sessionId}/labors?ids=${modelIds}`
  // const url = `http://0.0.0.0/lara/api/v3/salons/${salonId}/sessions/${sessionId}/labors?ids=${modelIds}`
  return axios
    .delete(url, config)
    .then((response: any) => {
      return models
    })
    .catch((error) => {
      throw error
    })
}


export const dispatchListSessionLabors = (params: {
  token: string;
  salonId: number,
  sessionId: number,
  loadingName?: string
}) => {
  const {loadingName = LOADING_SESSION_LABOR_LIST} = params
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({name: loadingName, state: true}))
    return apiListSessionLabors(params).then((resp) => {
      dispatch(reduceActiveSessionLabors(resp))
      dispatch(reduceSetLoadingState({name: loadingName, state: false}))
    })
  }
}

export const dispatchUpsertSessionLabors = (params: {
  token: string;
  salonId: number,
  sessionId: number
  models: APIUpsertSessionLabor[]
  loadingName?: string
}) => {
  const {loadingName = LOADING_SESSION_LABOR_UPSERT} = params
  return (dispatch: Dispatch) => {
    dispatch(reduceSetLoadingState({name: loadingName, state: true}))
    return apiUpsertSessionLabors(params).then((resp) => {
      dispatch(reduceUpdateActiveSessionLabors(resp))
      dispatch(reduceSetLoadingState({name: loadingName, state: false}))
    })
  }
}

export const dispatchDeleteSessionLabors = (params: {
  token: string
  salonId: number
  sessionId: number
  models: SessionLabor[]
  loadingName?: string
} ) => {
  const {loadingName = LOADING_SESSION_LABOR_DELETE} = params
  return ( dispatch: Dispatch ) => {
    dispatch(reduceSetLoadingState({name: loadingName, state: true}))
    return apiDeleteSessionLabors(params)
      .then(( resp ) => {
        dispatch(reduceDeleteActiveSessionLabors(params.models))
        dispatch(reduceSetLoadingState({name: loadingName, state: false}))
      })
      .catch(( error ) => {
        throw error
      })
  }
}
