// references
//
// slice redux docs - https://redux-toolkit.js.org/tutorials/typescript
import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import {assign, filter, keyBy, max, omit, orderBy, unionBy, values} from 'lodash'

import { RootState } from '../../store'
import {
  BowlType,
  SessionColorLara,
  SessionExtensionLara, SessionLaborItemLaraLegacy,
  SessionMetaLara,
  SessionPhotoLara,
  SessionSupplyLara
} from './interfaces'
import { ModelPagination } from '../../core/pagination'
import { LaraPagination } from "../../mini-lib/lara/lara-utils";
import {
  MOCKED_DISPLAY_UNIT_WE_SHOULD_SET_SOMEWHERE_MAYBE_USER_SETTINGS_OR_SOMETHING,
  SESSION_STATUS_OPTIONS
} from "./constants";
import {
  getSessionExtensionsLaraTotalCost,
  getSessionLaborLaraTotalCost,
  getSessionSuppliesLaraTotalCost,
  getTotalSessionColorCostLara,
  getTotalSessionExtensionsCostLara,
  getTotalSessionGramsLara,
  getTotalSessionWasteGramsLara
} from "./utils";
import { DATE_RANGE_OPTION_MAP, DateRangeType, DateSelection } from "../../mini-lib/dates-and-times/constants";
import {SessionLabor} from "./session-labor/interfaces";
import {buildDefaultSessionLabor} from "./session-labor/constants";
import {Client} from "../clients/interfaces";

// state
//
interface SessionState {
  // recentSessionsById: { [key: string]: Session } | null
  // sessionsById: { [key: string]: Session } | null
  selectedSessionId: number | null
  selectedBowlId: number | null
  // sessionDetails: Session | null
  pagination: ModelPagination | null

  searchPagination: ModelPagination | null
  isExtensionSheetVisible: boolean
  isSupplySheetVisible: boolean
  isLaborsSheetVisible: boolean
  isPhotoSheetVisible: boolean
  isMetaDetailSheetVisible: boolean
  isNoteSheetVisible: boolean
  isLaborSheetVisibleLegacy: boolean
  bowlTypes: BowlType[] | null
  redirectToSessionId: number | null

  // lara sessions
  //
  sessionsLaraById: { [key: string]: SessionMetaLara } | null
  paginationLara: LaraPagination | null

  // session details state
  //
  activeSessionMeta: SessionMetaLara | null
  activeSessionSupplies: SessionSupplyLara[] | null
  activeSessionLabors: SessionLabor[] | null
  activeSessionLaborsLegacy: SessionLaborItemLaraLegacy[] | null
  activeSessionExtensions: SessionExtensionLara[] | null
  activeSessionColors: SessionColorLara[] | null
  activeSessionPhotos: SessionPhotoLara[] | null
  activeSessionDisplayUnit: 'g' | 'oz'
  // our server sometimes sends deleted sessions if we navigate from session details to session list too fast after a deletion
  // this ensures that they are never displayed even if the client beats the server
  recentlyDeletedSessionIds: number[]
  dateSelection: DateSelection
  memberViewDateSelected: Date,
  sessionPhotosForClient: SessionPhotoLara[] | null,
  selectedLaborId: number | null,

}
const initialState: SessionState = {
  // sessionsById: null,
  // recentSessionsById: null,
  selectedSessionId: null,
  selectedBowlId: null,
  // sessionDetails: null,
  pagination: null,
  searchPagination: null,
  isExtensionSheetVisible: false,
  isSupplySheetVisible: false,
  isLaborsSheetVisible: false,
  isPhotoSheetVisible: false,
  isMetaDetailSheetVisible: false,
  isNoteSheetVisible: false,
  isLaborSheetVisibleLegacy: false,
  redirectToSessionId: null,
  bowlTypes: null,

  // lara sessions
  //
  sessionsLaraById: null,
  paginationLara: null,

  // session details state
  //
  activeSessionMeta: null,
  activeSessionSupplies: null,
  activeSessionLaborsLegacy: null,
  activeSessionLabors: null,
  activeSessionExtensions: null,
  activeSessionColors: null,
  activeSessionPhotos: null,
  activeSessionDisplayUnit: MOCKED_DISPLAY_UNIT_WE_SHOULD_SET_SOMEWHERE_MAYBE_USER_SETTINGS_OR_SOMETHING,
  recentlyDeletedSessionIds: [],
  dateSelection: {
    type: DATE_RANGE_OPTION_MAP.day.value as DateRangeType,
    startDate: null,
    endDate: null
  },
  memberViewDateSelected: new Date(),
  sessionPhotosForClient: null,
  selectedLaborId: null,
}

// reducer
//
export const SessionSlice = createSlice({
  name: 'sessions',
  initialState,
  reducers: {
    reduceSetSelectedSessionId: (state, action: PayloadAction<number | null>) => {
      state.selectedSessionId = action.payload
    },
    reduceSetSelectedBowlId: (state, action: PayloadAction<number | null>) => {
      state.selectedBowlId = action.payload
    },
    // reduceListSessions: (state, action: PayloadAction<{ pagination: ModelPagination; sessions: Session[] }>) => {
    //   state.sessionsById = assign({}, state.sessionsById, keyBy(action.payload.sessions, 'id'))
    //
    //   if (action.payload.pagination.type === 'list') {
    //     state.pagination = action.payload.pagination
    //   } else {
    //     state.searchPagination = action.payload.pagination
    //   }
    // },
    // reduceListRecentSessions: (state, action: PayloadAction<{ pagination: ModelPagination; sessions: Session[] }>) => {
    //   state.recentSessionsById = assign({}, state.sessionsById, keyBy(action.payload.sessions, 'id'))
    // },
    reduceCreateSessionLara: (state, action: PayloadAction<SessionMetaLara>) => {
      if (state.sessionsLaraById) {
        state.sessionsLaraById[action.payload.id] = action.payload
      }
    },
    // reduceClearSessionDetails: (state, action: PayloadAction<null>) => {
    //   state.sessionDetails = null
    // },
    reduceIsExtensionSheetVisible: (state, action: PayloadAction<boolean>) => {
      state.isExtensionSheetVisible = action.payload
    },
    reduceIsSupplySheetVisible: (state, action: PayloadAction<boolean>) => {
      state.isSupplySheetVisible = action.payload
    },
    reduceIsLaborSheetVisibleLegacy: (state, action: PayloadAction<boolean>) => {
      state.isLaborSheetVisibleLegacy = action.payload
    },
    reduceIsLaborsSheetVisible: (state, action: PayloadAction<boolean>) => {
      state.isLaborsSheetVisible = action.payload
    },
    reduceIsPhotoSheetVisible: (state, action: PayloadAction<boolean>) => {
      state.isPhotoSheetVisible = action.payload
    },
    reduceIsMetaDetailSheetVisible: (state, action: PayloadAction<boolean>) => {
      state.isMetaDetailSheetVisible = action.payload
    },
    reduceIsNoteSheetVisible: (state, action: PayloadAction<boolean>) => {
      state.isNoteSheetVisible = action.payload
    },
    reduceListBowlTypes: (state, action: PayloadAction<BowlType[]>) => {
      state.bowlTypes = action.payload
    },

    reduceRedirectToSessionId: (state, action: PayloadAction<number | null>) => {
      state.redirectToSessionId = action.payload
    },


    // lara sessions
    //
    //
    reduceListSessionsLara: (state, action: PayloadAction<SessionMetaLara[]>) => {
      const sessions = assign({}, state.sessionsLaraById, keyBy(action.payload, 'id'))
      state.sessionsLaraById = omit(sessions, state.recentlyDeletedSessionIds)
    },
    reduceResetSessionsLara: (state, action: PayloadAction<void>) => {
      state.sessionsLaraById = {}
    },
    reduceSessionsLaraPagination: (state, action: PayloadAction<LaraPagination>) => {
      state.paginationLara = action.payload
    },

    // session details
    //
    //
    reduceActiveSessionMeta: (state, action: PayloadAction<SessionMetaLara>) => {
      state.activeSessionMeta = action.payload
    },
    reduceUpdateActiveSessionMetaClient: (state, action: PayloadAction<Client>) => {
      const updatedClient = assign({}, state.activeSessionMeta, action.payload)
      const updatedSessionMeta = assign({}, state.activeSessionMeta, {client: updatedClient})
      state.activeSessionMeta = updatedSessionMeta
    },
    reduceActiveSessionSupplies: (state, action: PayloadAction<SessionSupplyLara[]>) => {
      state.activeSessionSupplies = action.payload
    },
    reduceDeleteActiveSessionSupply: (state, action: PayloadAction<number>) => {
      state.activeSessionSupplies = filter(state.activeSessionSupplies, item => item.id !== action.payload)
    },
    reduceActiveSessionLaborItemsLegacy: (state, action: PayloadAction<SessionLaborItemLaraLegacy[]>) => {
      state.activeSessionLaborsLegacy = action.payload
    },
    reduceActiveSessionLabors: (state, action: PayloadAction<SessionLabor[]>) => {
      state.activeSessionLabors = action.payload
    },
    reduceUpdateActiveSessionLabors: (state, action: PayloadAction<SessionLabor[]>) => {
      state.activeSessionLabors = unionBy(action.payload, state.activeSessionLabors, 'id')
  },
    reduceDeleteActiveSessionLaborItemsLegacy: (state, action: PayloadAction<number>) => {
      state.activeSessionLaborsLegacy = filter(state.activeSessionLaborsLegacy, item => item.id !== action.payload)
    },
    reduceDeleteActiveSessionLabors: (state, action: PayloadAction<SessionLabor[]>) => {
      const ids = action.payload.map(payloadItem => payloadItem.id)
      state.activeSessionLabors = filter(state.activeSessionLabors, activeItem => !ids.includes(activeItem.id))
    },
    reduceActiveSessionExtensions: (state, action: PayloadAction<SessionExtensionLara[]>) => {
      state.activeSessionExtensions = action.payload
    },
    reduceDeleteActiveSessionExtension: (state, action: PayloadAction<number[]>) => {
      state.activeSessionExtensions = filter(state.activeSessionExtensions, item => !action.payload.includes(item.id))
    },
    reduceActiveSessionColors: (state, action: PayloadAction<SessionColorLara[]>) => {
      state.activeSessionColors = action.payload
    },
    reduceDeleteActiveSessionColor: (state, action: PayloadAction<number[]>) => {
      state.activeSessionColors = filter(state.activeSessionColors, item => !action.payload.includes(item.id))
    },
    reduceSetActiveSessionDisplayUnit: (state, action: PayloadAction<'g' | 'oz'>) => {
      state.activeSessionDisplayUnit = action.payload
    },
    reduceActiveSessionPhotos: ( state, action: PayloadAction<SessionPhotoLara[]> ) => {
      state.activeSessionPhotos = action.payload
    },
    reduceDeleteActiveSessionPhoto: ( state, action: PayloadAction<number> ) => {
      state.activeSessionPhotos = filter(state.activeSessionPhotos, item => action.payload !== item.id)
    },
    reduceCreateActiveSessionPhoto: ( state, action: PayloadAction<SessionPhotoLara> ) => {
      state.activeSessionPhotos = state.activeSessionPhotos ? [...state.activeSessionPhotos, action.payload] : [action.payload]
    },
    reduceCompleteSessionLara: ( state, action: PayloadAction<number> ) => {
      if (state.sessionsLaraById) {
        const updatedSession = assign({}, state.sessionsLaraById[action.payload], {status: SESSION_STATUS_OPTIONS.complete})
        state.sessionsLaraById = assign({}, state.sessionsLaraById, {[action.payload]: updatedSession})
      }
    },
    reduceDeleteActiveSession: (state, action: PayloadAction<number>) => {
      state.activeSessionMeta = null
      state.activeSessionSupplies = null
      state.activeSessionLaborsLegacy = null
      state.activeSessionExtensions = null
      state.activeSessionColors = null
      state.recentlyDeletedSessionIds = [...state.recentlyDeletedSessionIds, action.payload]
      state.sessionsLaraById = omit(state.sessionsLaraById, action.payload)
    },
    reduceSessionsDateSelection: (state, action: PayloadAction<DateSelection>) => {
      state.dateSelection = action.payload
    },
    reduceMemberViewDateSelected: (state, action: PayloadAction<Date>) => {
      state.memberViewDateSelected = action.payload
    },
    reduceSessionPhotosForClient: (state, action: PayloadAction<SessionPhotoLara[]>) => {
      state.sessionPhotosForClient = action.payload
    },
    reduceSelectedLaborId: (state, action: PayloadAction<number | null>) => {
      state.selectedLaborId = action.payload
    }
  },
})

// actions
//
export const {
  // reduceListSessions,
  // reduceListRecentSessions,

  // lara sessions
  reduceSetSelectedSessionId,
  reduceSetSelectedBowlId,
  reduceMemberViewDateSelected,
  reduceIsExtensionSheetVisible,
  reduceIsSupplySheetVisible,
  reduceIsLaborSheetVisibleLegacy,
  reduceRedirectToSessionId,
  reduceListBowlTypes,

  reduceUpdateActiveSessionMetaClient,
  reduceListSessionsLara,
  reduceSessionsLaraPagination,
  reduceCreateSessionLara,
  reduceActiveSessionMeta,
  reduceActiveSessionSupplies,
  reduceDeleteActiveSessionSupply,
  reduceActiveSessionLaborItemsLegacy,
  reduceDeleteActiveSessionLaborItemsLegacy,
  reduceActiveSessionLabors,
  reduceUpdateActiveSessionLabors,
  reduceDeleteActiveSessionLabors,
  reduceIsLaborsSheetVisible,
  reduceIsPhotoSheetVisible,
  reduceIsMetaDetailSheetVisible,
  reduceIsNoteSheetVisible,
  reduceDeleteActiveSessionExtension,
  reduceActiveSessionExtensions,
  reduceDeleteActiveSessionColor,
  reduceActiveSessionColors,
  reduceActiveSessionPhotos,
  reduceDeleteActiveSessionPhoto,
  reduceCreateActiveSessionPhoto,
  reduceSetActiveSessionDisplayUnit,
  reduceDeleteActiveSession,
  reduceCompleteSessionLara,
  reduceSessionsDateSelection,
  reduceResetSessionsLara,
  reduceSessionPhotosForClient,

  reduceSelectedLaborId
} = SessionSlice.actions

// selectors
//
export const selectSelectedBowlId = (state: RootState): number | null => {
  return state.sessions.selectedBowlId
}

export const selectIsExtensionSheetVisible = (state: RootState) => state.sessions.isExtensionSheetVisible
export const selectIsSupplySheetVisible = (state: RootState) => state.sessions.isSupplySheetVisible
export const selectIsLaborSheetVisibleLegacy = (state: RootState) => state.sessions.isLaborSheetVisibleLegacy
export const selectIsLaborsSheetVisible = (state: RootState) => state.sessions.isLaborsSheetVisible
export const selectIsPhotoSheetVisible = (state: RootState) => state.sessions.isPhotoSheetVisible
export const selectIsMetaDetailsSheetVisible = (state: RootState) => state.sessions.isMetaDetailSheetVisible
export const selectIsNoteSheetVisible = (state: RootState) => state.sessions.isNoteSheetVisible
export const selectSelectedLaborId = (state: RootState) => state.sessions.selectedLaborId
export const selectSessionsForClientLara = (state: RootState, clientId: number): SessionMetaLara[] | null => {
  // if there are no sessions return null, the state is loading
  if (!state.sessions.sessionsLaraById) {
    return null
  }

  const dateSorter = (session) => session.date
  const sessionList = values(state.sessions.sessionsLaraById)
  const filteredSessionList = filter(sessionList, (session) => session.client.id === clientId)
  const sortedFilteredSessionList = orderBy(filteredSessionList, [dateSorter], ['desc'])
  return sortedFilteredSessionList
}

export const selectRedirectToSessionId = (state: RootState) => state.sessions.redirectToSessionId
export const selectBowlTypes = (state: RootState): BowlType[] | null => state.sessions.bowlTypes


// lara sessions
//
//
export const selectSessionsLara = (state: RootState): SessionMetaLara[] | null => {
  const dateSorter = (session) => session.date
  // the server returns dates without times which means that we have no way to compare two sessions on the same date
  // we want newer sessions at the top, this will make higher ids go to the top allowing us to 'sort' within dates
  const idSorter = (session) => session.id
  return state.sessions.sessionsLaraById ? orderBy(values(state.sessions.sessionsLaraById), [dateSorter, idSorter], ['desc', 'desc']) : null
}

export const selectSessionsPaginationLara = (state: RootState): LaraPagination | null => {
  return state.sessions.paginationLara
}


// active session
//
//
//
export const selectActiveSessionMeta = (state: RootState): SessionMetaLara | null => state.sessions.activeSessionMeta
export const selectActiveSessionLaborsLegacy = (state: RootState): SessionLaborItemLaraLegacy[] | null => state.sessions.activeSessionLaborsLegacy
export const selectActiveSessionLabors = (state: RootState): SessionLabor[] | null => state.sessions.activeSessionLabors

export const selectIsActiveSessionWithoutLabor = (state: RootState): boolean | null => {
  const colorsWithoutLabor = state.sessions.activeSessionColors?.filter(item => item.laborId === null)
  if (colorsWithoutLabor && colorsWithoutLabor.length > 0) {
    return true
  }

  const suppliesWithoutLabor = state.sessions.activeSessionSupplies?.filter(item => item.laborId === null)
  if (suppliesWithoutLabor && suppliesWithoutLabor.length > 0) {
    return true
  }

  const extensionsWithoutLabor = state.sessions.activeSessionExtensions?.filter(item => item.laborId === null)
  if (extensionsWithoutLabor && extensionsWithoutLabor.length > 0) {
    return true
  }

  return false
}
export const selectActiveSessionSupplies = (state: RootState, laborId: number | null): SessionSupplyLara[] | null => {
  if (laborId) {
    return state.sessions.activeSessionSupplies ? state.sessions.activeSessionSupplies.filter(model => model.laborId === laborId) : null
  }
  return state.sessions.activeSessionSupplies
}
export const selectActiveSessionExtensions = (state: RootState, laborId: number | null): SessionExtensionLara[] | null => {
  if (laborId) {
    return state.sessions.activeSessionExtensions ? state.sessions.activeSessionExtensions.filter(color => color.laborId === laborId) : null
  }
  return state.sessions.activeSessionExtensions
}
export const selectActiveSessionColors = (state: RootState, laborId: number | null): SessionColorLara[] | null => {
  if (laborId) {
    return state.sessions.activeSessionColors ? state.sessions.activeSessionColors.filter(color => color.laborId === laborId) : null
  }
  return state.sessions.activeSessionColors
}
export const selectHighestBowlId = (state: RootState): number | null => {
  const bowlIds: number[] | null = state.sessions.activeSessionColors
    ? state.sessions.activeSessionColors.map(color => color.bowl)
    : null
  return max(bowlIds) ?? null
}
export const selectActiveSessionPhotos = (state: RootState): SessionPhotoLara[] | null => state.sessions.activeSessionPhotos
export const selectActiveSessionDisplayUnit = (state: RootState): 'g' | 'oz' => state.sessions.activeSessionDisplayUnit
export const selectActiveSessionTotalCents = (state: RootState): number => {
  const totals: number[] = []
  if (state.sessions.activeSessionColors) {
    const colorTotal = getTotalSessionColorCostLara(state.sessions.activeSessionColors, 'amount')
    totals.push(colorTotal)
  }

  if (state.sessions.activeSessionSupplies) {
    const supplyTotal = getSessionSuppliesLaraTotalCost(state.sessions.activeSessionSupplies)
    totals.push(supplyTotal)
  }
  if (state.sessions.activeSessionLaborsLegacy) {
    const laborTotal = getSessionLaborLaraTotalCost(state.sessions.activeSessionLaborsLegacy)
    totals.push(laborTotal)
  }
  if (state.sessions.activeSessionExtensions) {
    const extensionTotal = getSessionExtensionsLaraTotalCost(state.sessions.activeSessionExtensions)
    totals.push(extensionTotal)
  }
  return totals.reduce((acc, total) => acc + total , 0)
}

export const selectSessionsDateSelection = (state: RootState): DateSelection => state.sessions.dateSelection

export const selectSessionCostSummary = (state: RootState): {
  labors: {name: string, colorTotalCents: number | null, colorGrams: number | null, colorUnits: string | null, supplyTotalCents: number | null, extensionsTotalCents: number | null}[],
  wastes: {name: string, wasteTotalCents: number | null, wasteTotalGrams: number | null, wasteUnits: string | null}[],
  sessionColorTotalCents: number,
  sessionSupplyTotalCents: number,
  sessionExtensionTotalCents: number,
  sessionTotalCents: number
} | null => {
  const activeSessionMeta = state.sessions.activeSessionMeta
  if (!activeSessionMeta) {return null}

  const allColors = state.sessions.activeSessionColors || []
  const allExtensions = state.sessions.activeSessionExtensions || []
  const allSupplies = state.sessions.activeSessionSupplies || []
  const allSessionLabors = state.sessions.activeSessionLabors || []

  const currentSalonId = state.users.loggedInUser?.currentSalonContext?.salonId || -1
  const defaultLabor = buildDefaultSessionLabor({sessionId: activeSessionMeta.id, salonId: currentSalonId})
  const normalizedSessionLabors = [...allSessionLabors, defaultLabor]

  const labors: any = []
  const wastes: any = []
  normalizedSessionLabors.forEach(labor => {
    const colorsForLabor = allColors.filter(item => item.laborId === labor.laborId || (item.laborId === null && labor.id === -1))
    const suppliesForLabor = allSupplies.filter(item => item.laborId === labor.laborId || (item.laborId === null && labor.id === -1))
    const extensionsForLabor = allExtensions.filter(item => item.laborId === labor.laborId || (item.laborId === null && labor.id === -1))

    const colorTotalCents = getTotalSessionColorCostLara(colorsForLabor, 'amount')
    const wasteTotalCents = getTotalSessionColorCostLara(colorsForLabor, 'waste')
    const colorTotalGrams = getTotalSessionGramsLara(colorsForLabor)
    const wasteTotalGrams = getTotalSessionWasteGramsLara(colorsForLabor)
    const colorUnits = colorsForLabor && colorsForLabor.length > 0 ? colorsForLabor[0].displayUnit : 'g'
    const suppliesTotalCents = getSessionSuppliesLaraTotalCost(suppliesForLabor)
    const extensionsTotalCents = getTotalSessionExtensionsCostLara(extensionsForLabor)

    const data = {
      name: labor.stampedName,
      colorTotalCents: colorTotalCents,
      colorGrams: colorTotalGrams,
      colorUnits: colorUnits,
      supplyTotalCents: suppliesTotalCents,
      extensionsTotalCents: extensionsTotalCents
    }

    const wasteData = {
      name: labor.stampedName,
      wasteTotalCents: wasteTotalCents,
      wasteTotalGrams: wasteTotalGrams,
      wasteUnits: colorUnits,
    }
    // only include it if there is some data associated with it
    // this throws out the default labor grouping for older sessions
    if (colorsForLabor.length > 0 || suppliesForLabor.length > 0 || extensionsForLabor.length > 0) {
      labors.push(data)
      wastes.push(wasteData)
    }
  })
  const colorTotalCents = getTotalSessionColorCostLara(allColors, 'amount')
  const suppliesTotalCents = getSessionSuppliesLaraTotalCost(allSupplies)
  const extensionsTotalCents = getTotalSessionExtensionsCostLara(allExtensions)
  const totalCents = colorTotalCents + suppliesTotalCents + extensionsTotalCents
  return {
    labors: labors,
    wastes: wastes,
    sessionColorTotalCents: colorTotalCents,
    sessionSupplyTotalCents: suppliesTotalCents,
    sessionExtensionTotalCents: extensionsTotalCents,
    sessionTotalCents: totalCents
  }
}
export const selectMemberViewDateSelected = (state: RootState): Date => state.sessions.memberViewDateSelected

export const selectSessionPhotosForClient = (state: RootState): SessionPhotoLara[] | null => {
  return state.sessions.sessionPhotosForClient
}


// export
//
export default SessionSlice.reducer
