import React from 'react'
import { PAGE_CONTENT_WIDE_MAX_WIDTH } from '../../data/constants'
import {Box, Flex} from '@chakra-ui/react'
import { UseViewSize } from '../../core/UseViewSize'
import { UserNav } from '../user-nav/UserNav'
import { ReleaseMultiLocation } from '../flags/Release'

export function PageLayout (props: {
  variant: 'full' | 'narrow-clear'
  hasUserNav?: boolean
  top?: JSX.Element
  header: JSX.Element | null
  content?: JSX.Element
  children?: any
  padding?: string
}) {
  const { hasUserNav = true, top, header, content, children, variant = 'full', padding } = props
  const { isMobile, isTablet } = UseViewSize()
  const releaseMultiLocation = ReleaseMultiLocation()
  return (
    <Flex
      direction="column"
      justify="center"
      align="center"
      p={padding ? padding : isMobile || isTablet ? '6px 6px 300px 6px' : '24px 72px 300px'}
    >
      {variant === 'full' && (
        <>
          {hasUserNav && releaseMultiLocation && <UserNav />}
          <Box maxW={PAGE_CONTENT_WIDE_MAX_WIDTH} w="100%" p={padding ? padding : isMobile || isTablet ? '4px' : '0 12px'}>
            {top}
            {header}
            {/*<Gap/>*/}
            {/*<Divider/>*/}
            {/*<Gap/>*/}
          </Box>


          <Box
            p={padding ? padding : isMobile || isTablet ? '4px' : '0 12px'}
            maxW={PAGE_CONTENT_WIDE_MAX_WIDTH}
            w="100%"
            minH="100px"
            borderRadius={15}
          >
            {content}
            {children}
          </Box>
        </>
      )}
    </Flex>
  )
}
