import {map} from "lodash";
import {APISessionLabor, SessionLabor} from "./interfaces";

export const mapAPISessionLaborToSessionLabor = (api: APISessionLabor ): SessionLabor => {
  return {
       id: api.id,
       salonId: api.salon_id,
       sessionId: api.session_id,
       laborId: api.labor_id,
       stampedName: api.stamped_name,
       stampedCategory: api.stamped_category,
       stampedColorhex: api.stamped_colorhex,
       stampedDurationSeconds: api.stamped_duration_seconds,
  }
}
export const mapAPISessionLaborsToSessionLabors = (api: APISessionLabor[] ): SessionLabor[] => {
  return map(api, mapAPISessionLaborToSessionLabor)
}
