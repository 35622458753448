import React, { useEffect } from 'react'
import { SGLayout, SGTwoColumnContent } from './SGLayout'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { TaskBar } from './HelpStepperV2'
import { generatePath } from 'react-router-dom'
import { useAppSelector } from '../../hooks'
import { selectChecklistItemsByCode } from '../../data/start-guide/slice'
import { dispatchListUserChecklistItems } from '../../data/start-guide/api'
import { useDispatch } from 'react-redux'
import { ROUTES } from "../../appRoutes";
import { SGActionButtons } from "./SGActionButtons";
import {selectActiveSalonContext} from "../../data/salon/slice";

export const SGLaborIntroPage = () => {
  const {
    user,
    salonId,
  } = UseBaseApiParams()

  const dispatch = useDispatch()
  const checklistItems = useAppSelector(selectChecklistItemsByCode)
  const checklistItemsLoaded = !!checklistItems

  const salon = useAppSelector(selectActiveSalonContext)

  useEffect(() => {
    if (!checklistItemsLoaded && user.userId) {
      dispatch(dispatchListUserChecklistItems(user.token, user.userId))
    }
  }, [dispatch, user.token, user.userId, checklistItemsLoaded])
  const salonHasLabor = salon && salon.laborsCount > 0
  const url = generatePath(ROUTES.labor, { salonId })

  return (
    <>
      <SGLayout>
        <SGTwoColumnContent
          title="SERVICE MENUS"
          description={descriptionText}
          actions={
              // action2={{
              //   label: 'Learn More',
              //   url: url,
              // }}
            <SGActionButtons
              action={{
                label: 'Start Now',
                url: url,
              }}
            />
          }
          taskBar={<TaskBar showLabel={false} totalSteps={1} stepsComplete={salonHasLabor ? 1 : 0} />}
          imgPath={require('../../assets/start-guide/labor.png').default}
        />
      </SGLayout>
    </>
  )
}

const descriptionText = `Unlock new business insights by adding your services you are looking to track product costs.

✓   Sync services with Square Online

✓   Add presets to enhance your stylist experience

✓   Access business insights for your services

`
