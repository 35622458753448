import {SessionMetaLara} from "../../../data/sessions/interfaces";
import {UseBaseApiParams} from "../../../core/UseBaseApiParams";
import {useDispatch} from "react-redux";
import {ReleaseOpenDay} from "../../../mini-lib/flags/Release";
import {generatePath, Link} from "react-router-dom";
import {ROUTES} from "../../../appRoutes";
import {SESSION_STATUS_OPTIONS} from "../../../data/sessions/constants";
import {Button, Center, Text} from "@chakra-ui/react";
import {dispatchCompleteSessionLara} from "../../../data/sessions/api";
import {COLORS} from "../../../mini-lib/theme/colors";
import React from "react";

export const SessionStatusButton = (props: {
  sessionMeta: SessionMetaLara
}) => {
  const { user, salonId } = UseBaseApiParams()
  const dispatch = useDispatch()
  const { sessionMeta: { status, id } } = props
  const releaseOpenDay = ReleaseOpenDay()
  const linkUrl = releaseOpenDay ? generatePath(ROUTES.sessionsByMember, { salonId }) : generatePath(ROUTES.sessions, { salonId })
  return (
    <>
      {status === SESSION_STATUS_OPTIONS.open && (
        <Link to={linkUrl}>
          <Button
            colorScheme="brand.lavender"
            variant="round"
            onClick={() => {
              dispatch(dispatchCompleteSessionLara({ token: user.token, sessionId: id, salonId }))
            }}
          >
            Complete Session
          </Button>
        </Link>
      )}
      {status === SESSION_STATUS_OPTIONS.complete && (
        <Center h="40px">
          <Text variant="button" color={COLORS.shades_neutral_400}>
            SESSION COMPLETE
          </Text>
        </Center>
        // <Button
        //   colorScheme="brand.lavender"
        //   variant="round-outline"
        //   onClick={() => updateSession({ status: SESSION_STATUS_OPTIONS.open })}
        // >
        //   Edit Session
        // </Button>
      )}
    </>
  )
}