import { APISessionMetaPatchLara, SessionMetaLara } from '../../../data/sessions/interfaces'
import { UseViewSize } from '../../../core/UseViewSize'
import {
  Box,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Text
} from '@chakra-ui/react'
import { DateInput } from '../../../mini-lib/dates-and-times/DateInput'
import { StylistAutocompleteDeprecated } from '../../stylists/StylistAutocompleteDeprecated'
import { ClientAutocomplete } from '../../clients/ClientAutocomplete'
import React, { useEffect } from 'react'
import { buildLaraServerDateTime, buildMMMDDYYYYDate } from '../../../core/dates'
import { SESSION_STATUS_OPTIONS } from '../../../data/sessions/constants'
import { useAppSelector } from '../../../hooks'
import { SalonMember } from '../../../data/user/interfaces'
import { selectMemberList } from '../../../data/user/slice'
import { find } from 'lodash'
import { dispatchListSalonMembers } from '../../../data/user/api'
import { useDispatch } from 'react-redux'
import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { Loading } from '../../../mini-lib/loading/Loading'
import { dispatchDeleteSessionLaborItemLaraLegacy } from '../../../data/sessions/session-labor-legacy/api'
import {
  reduceIsMetaDetailSheetVisible,
  selectActiveSessionLaborsLegacy, selectIsMetaDetailsSheetVisible,
} from '../../../data/sessions/slice'
import { Gap } from '../../../mini-lib/gap/Gap'
import { COLORS } from '../../../mini-lib/theme/colors'
import {MaterialIcon} from "../../../mini-lib/icons/MaterialIcon";
import {DisableAutofocus} from "../../../mini-lib/disable-autofocus/DisableAutofocus";


export const SessionMetaDetailsSheet = (props: {sessionMeta: SessionMetaLara, patchSessionMeta: any}) => {
  const {sessionMeta, patchSessionMeta} = props
  const dispatch = useDispatch()
  const { isMobile } = UseViewSize()

  const isVisible = useAppSelector(selectIsMetaDetailsSheetVisible)

  const onClose = () => {
    dispatch(reduceIsMetaDetailSheetVisible(false))
  }

  return (
    <Drawer
      size={isMobile ? 'xs' : 'sm'}
      isOpen={isVisible}
      placement="right"
      onClose={onClose}
    >
      <DrawerOverlay />
      <DrawerCloseButton />
      <DrawerContent>
        <DrawerHeader>
          <Flex justify="space-between" align="center">
            <MaterialIcon
              cursor="pointer"
              colorhex={COLORS.lavender_500}
              size="36px"
              name="chevron_left"
              onClick={onClose}
            />
            <Text fontWeight='bold' fontSize='20px'>Edit Details</Text>
          </Flex>
        </DrawerHeader>
        <DrawerBody>
          <DisableAutofocus />
          <EditDetails sessionMeta={sessionMeta} patchSessionMeta={patchSessionMeta}/>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}


export const SessionMetaDetails = (props: {
  sessionMeta: SessionMetaLara | null
  patchSessionMeta: (props: APISessionMetaPatchLara) => void
  isEditable: boolean
}) => {
  const { sessionMeta } = props
  const dispatch = useDispatch()
  return (
    <>
      <Gap s="12px" />
      <Flex direction="column" borderRadius="8px" border={`1px solid ${COLORS.shades_neutral_300}`} p="12px">
        {sessionMeta?.status === 'open' && (
          <>
            <Flex justify="space-between" align="center">
              <Text fontWeight="bold">Details</Text>
              <Text
                color={COLORS.lavender_500}
                cursor="pointer"
                onClick={() => {
                  dispatch(reduceIsMetaDetailSheetVisible(true))
                }}
              >
                edit
              </Text>
            </Flex>
          </>
        )}
        <Gap s="12px" />
        {sessionMeta && <ViewDetails sessionMeta={sessionMeta} />}
      </Flex>
    </>
  )
}

export const ViewDetails = (props: { sessionMeta: SessionMetaLara }) => {
  const { sessionMeta } = props
  return (
    <>
      <Flex justify="space-between" align="center">
        <Text color={COLORS.text_secondary}>Date</Text>
        <Text>{buildMMMDDYYYYDate(sessionMeta?.date || null)}</Text>
      </Flex>

      <Gap s="12px" />

      <Flex justify="space-between" align="center">
        <Text color={COLORS.text_secondary}>Stylist</Text>
        <Text>{sessionMeta?.user.fullName}</Text>
      </Flex>

      <Gap s="12px" />

      {sessionMeta?.assistant && (
        <>
          <Flex justify="space-between" align="center">
            <Text color={COLORS.text_secondary}>Assistant</Text>
            <Text>{sessionMeta?.assistant.fullName}</Text>
          </Flex>
          <Gap s="12px" />
        </>
      )}

      <Flex justify="space-between" align="center">
        <Text color={COLORS.text_secondary}>Client</Text>
        <Text>{sessionMeta?.client.firstName + ' ' + sessionMeta?.client.lastName}</Text>
      </Flex>

      <Gap s="12px" />
    </>
  )
}
export const EditDetails = (props: {
  sessionMeta: SessionMetaLara | null
  patchSessionMeta: (props: APISessionMetaPatchLara) => void
}) => {
  const { isMobile } = UseViewSize()
  const dispatch = useDispatch()
  const { sessionMeta, patchSessionMeta } = props
  const { user, salonId } = UseBaseApiParams()
  const models = useAppSelector(selectMemberList)
  const initialStylist: SalonMember | null | any =
    sessionMeta && sessionMeta.user && models ? find(models, { userId: sessionMeta.user.userId }) || null : null
  const initialAssistant: SalonMember | null | any =
    sessionMeta && sessionMeta.assistant && models
      ? find(models, { userId: sessionMeta.assistant.userId }) || null
      : null
  const date = sessionMeta ? new Date(sessionMeta.date) : null
  const sessionLaborItems = useAppSelector(selectActiveSessionLaborsLegacy)
  useEffect(() => {
    dispatch(dispatchListSalonMembers(user.token, salonId))
  }, [dispatch, user.token, salonId])
  return (
    <>
      <Flex gridGap="12px" direction="column">
        <Box w={isMobile ? '45%' : '100%'}>
          <DateInput
            label="Date"
            placeholder="Date"
            disabled={sessionMeta?.status === SESSION_STATUS_OPTIONS.complete}
            value={date}
            onChange={(date) => {
              // updateSession({ date: date.toISOString(), completed_date: date.toISOString() })

              patchSessionMeta({ date: buildLaraServerDateTime(date) })
            }}
            isClearable={false}
            onKeyDown={(e) => {
              e.preventDefault()
            }}
          />
        </Box>

        <Box w={isMobile ? '45%' : '100%'}>
          {initialStylist ? (
            <StylistAutocompleteDeprecated
              label="Stylist"
              width='100%'
              disabled={sessionMeta?.status === SESSION_STATUS_OPTIONS.complete}
              isClearable={false}
              initialStylist={initialStylist}
              onSelect={(stylist) => {
                // updateSession({ stylist_id: stylist.stylistId, stylist_user_id: stylist.userId })
                patchSessionMeta({ user_id: stylist.userId })

                // if a new stylist is selected then the session labor items will become invalid so we need to archive them
                //
                if (sessionMeta && initialStylist.userId !== stylist.userId) {
                  sessionLaborItems?.forEach((sessionLaborItem) => {
                    dispatch(
                      dispatchDeleteSessionLaborItemLaraLegacy({
                        token: user.token,
                        salonId,
                        sessionId: sessionMeta.id,
                        sessionLaborItemId: sessionLaborItem.id,
                      }),
                    )
                  })
                }
              }}
            />
          ) : (
            <Loading />
          )}
        </Box>

        <Box w={isMobile ? '45%' : '100%'}>
          {initialAssistant || initialStylist ? (
            <StylistAutocompleteDeprecated
              label="Assistant"
              width='100%'
              disabled={sessionMeta?.status === SESSION_STATUS_OPTIONS.complete}
              isClearable={false}
              initialStylist={initialAssistant}
              onSelect={(stylist) => {
                // updateSession({ assistant_user_id: stylist.userId })

                patchSessionMeta({ user_assistant_id: stylist.userId })
              }}
            />
          ) : (
            <Loading />
          )}
        </Box>

        <Box w={isMobile ? '45%' : '100%'}>
          {sessionMeta?.client && (
            <ClientAutocomplete
              width='100%'
              label="Client"
              disabled={sessionMeta?.status === SESSION_STATUS_OPTIONS.complete}
              isClearable={false}
              initialClient={sessionMeta?.client}
              onSelect={(client) => {
                // updateSession({ client_id: client.id })

                patchSessionMeta({ client_id: client.id })
              }}
            />
          )}
        </Box>
      </Flex>
    </>
  )
}
