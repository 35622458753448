import { useDispatch } from 'react-redux'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { generatePath } from 'react-router-dom'
import { ROUTES } from '../../appRoutes'
import React, {ReactNode, useEffect} from 'react'
import { PageLayout } from '../../mini-lib/layouts/PageLayout'
import { PageHeader } from '../../mini-lib/page-header/PageHeader'
import { Box, Button, Divider, Flex, Progress, Text } from '@chakra-ui/react'
import { TEXT_VARIANTS } from '../../theme'
import { COLORS } from '../../mini-lib/theme/colors'
import { BorderedBox } from '../../mini-lib/box/BorderedBox'
import { Gap } from '../../mini-lib/gap/Gap'
import { formatCentsToDollars } from '../../mini-lib/units/money'
import {getCurrentPaymentStatus, getSalonUserLimitPercentage, getSessionLimitPercentage} from '../../data/user/utils'
import { buildDateFromServer } from '../../core/dates'
import {capitalize, isNumber} from 'lodash'
import {dispatchStripeCheckout, dispatchStripeManage} from "../../integrations/stripe/api";
import {useAppSelector} from "../../hooks";
import {selectLoadingState} from "../../core/loading/slice";
import {
  PLANS,
  STRIPE_CREATE,
  STRIPE_LOADING,
  StripeBillingInterval,
  StripePlanType
} from "../../integrations/stripe/constants";
import {Loading} from "../../mini-lib/loading/Loading";
import {dispatchListSalonUsers} from "../../data/salon-user/api";
import {selectSalonUsers} from "../../data/salon-user/slice";
import {APIStripeCheckout} from "../../integrations/stripe/interfaces";
import {getCountryCode} from "../../mini-lib/time/utils";
import {getEnvConfig} from "../../config";
import {selectPaymentFeatures} from "../../data/payment-status/slice";
import {dispatchListPaymentFeatures} from "../../data/payment-status/api";
import {SalonScaleIcon} from "../../mini-lib/icons/SalonScaleIcon";
import {getLogoIconNameFromPaymentStatus, getPaymentFeatureIconName} from "../../data/payment-status/utils";

export const ManagePaymentPage = () => {
  const dispatch = useDispatch()
  const { user, salonId } = UseBaseApiParams()
  const breadcrumbs = [{ label: 'Home', url: generatePath(ROUTES.home, { salonId }) }, { label: 'Manage Payment' }]

  const salonUsers = useAppSelector(selectSalonUsers)
  const paymentFeatures = useAppSelector(selectPaymentFeatures)
  useEffect(() => {
    if (user?.token) {
      dispatch(dispatchListSalonUsers({ token: user.token, salonId }))
    }
  }, [dispatch, user?.token, salonId])

  useEffect(() => {
    if (user?.token) {
      dispatch(dispatchListPaymentFeatures({ token: user.token, userId: user.userId }))
    }
  }, [dispatch, user?.token, user?.userId])

  const paymentStatus = getCurrentPaymentStatus(user)
    const countryCode = getCountryCode()
  const config = getEnvConfig()


  const managePayment = () => {
    if (paymentStatus?.status === 'active') {
      dispatch(dispatchStripeManage({token: user.token, salonId, userId: user.userId}))
    }
    if (paymentStatus?.status === 'cancelled') {
      const isCad = countryCode === 'CA'
      const body: APIStripeCheckout = {
        plan: paymentStatus.planId as StripePlanType,
        billing_frequency: paymentStatus.paymentInterval as StripeBillingInterval,
        currency: isCad ? 'cad' : 'usd',
        success_url: `${config.reactHost}${generatePath(ROUTES.managePayment, { salonId })}`,
        cancel_url: `${config.reactHost}${generatePath(ROUTES.managePayment, { salonId })}`,
      }
      if (paymentStatus?.customerId) {
        body['stripe_customer_id'] = paymentStatus.customerId
      }
      dispatch(dispatchStripeCheckout({ token: user.token, salonId, userId: user.userId, body }))
    }
  }
  const loadingStripeRedirect = useAppSelector(state => selectLoadingState(state, STRIPE_LOADING))
  const loadingAddInventory = useAppSelector(state => selectLoadingState(state, STRIPE_CREATE))



  const addInventory = () => {
    const isCad = countryCode === 'CA'
    const body: APIStripeCheckout = {
      plan: PLANS.inventory.id,
      billing_frequency: 'month',
      currency: isCad ? 'cad' : 'usd',
      success_url: `${config.reactHost}${generatePath(ROUTES.managePayment, { salonId })}`,
      cancel_url: `${config.reactHost}${generatePath(ROUTES.managePayment, { salonId })}`,
    }
    if (paymentStatus?.customerId) {
      body['stripe_customer_id'] = paymentStatus.customerId
    }
    dispatch(dispatchStripeCheckout({ token: user.token, salonId, userId: user.userId, body }))
  }

  const logoIconName = paymentStatus ? getLogoIconNameFromPaymentStatus(paymentStatus.planId) : ''
  return (
    <PageLayout
      variant="full"
      header={
        <PageHeader
          title="Subscription"
          breadcrumbs={breadcrumbs}
          actions={
            <Button variant="round-outline" w='200px' colorScheme="brand.midnight" onClick={() => managePayment()}>
              {loadingStripeRedirect ? <Loading/> : paymentStatus?.status === 'active' ? 'Manage Subscription' : 'Reactivate Subscription'}
            </Button>
          }
        />
      }
      content={
        <Box maxW="500px">
          {/*{!allPaymentFeatures && <Loading />}*/}
          {paymentStatus && (
            <>
              <Text variant={TEXT_VARIANTS.v2title}>Your Subscriptions</Text>
              <Gap />
              <BorderedBox>
                <Row
                  iconName={logoIconName}
                  actionButton={paymentStatus.status}
                  title={capitalize(paymentStatus.planId)}
                  subtitle={`billed ${paymentStatus.paymentInterval}${
                    paymentStatus.paymentInterval === 'month' || paymentStatus.paymentInterval === 'year' ? 'ly' : ''
                  }`}
                  bottomText={`** Your plan renews on ${buildDateFromServer(
                    paymentStatus.subscriptionExpiresAt,
                  ).toLocaleDateString('en-US', { month: 'long', day: 'numeric' })}`}
                />
                <Gap />
                <Divider />
                <Gap />

                <Text>
                  {`${salonUsers ? salonUsers.length : ''} of `}{' '}
                  {paymentStatus.teamLimit ? paymentStatus.teamLimit : 'unlimited'} seats
                </Text>
                <Progress
                  bg="#E6E0E9"
                  colorScheme="brand.lavender"
                  size="xs"
                  value={getSalonUserLimitPercentage(paymentStatus, salonUsers) || 0}
                />

                <Gap />

                <Flex justify="space-between" align="center">
                  <Text>
                    {`${paymentStatus.limitIntervalSessionCount ? paymentStatus.limitIntervalSessionCount : '0'} of `}{' '}
                    {paymentStatus.sessionLimit ? paymentStatus.sessionLimit : 'unlimited'} sessions
                  </Text>
                </Flex>
                <Progress
                  bg="#E6E0E9"
                  colorScheme="brand.lavender"
                  size="xs"
                  value={getSessionLimitPercentage(paymentStatus) || 0}
                />
                <Gap />
              </BorderedBox>

              {paymentStatus && paymentStatus.hasInventory && (
                <>
                  <Gap />
                  <BorderedBox>
                    <Row
                      iconName="inventory"
                      title={PLANS.inventory.title}
                      priceCents={paymentStatus.planId === 'luxe' ? 'included' : PLANS.inventory.per_month * 100}
                      subtitle={'billed monthly'}
                    />
                  </BorderedBox>
                </>
              )}
              <Gap />

              {paymentFeatures && paymentFeatures?.length > 0 && (
                <Text variant={TEXT_VARIANTS.v2title}>Your Add-ons</Text>
              )}

              {paymentFeatures?.map((paymentFeature) => (
                <Box key={paymentFeature.stripeOrderItemId}>
                  <Gap />
                  <BorderedBox>
                    <Row
                      iconName={getPaymentFeatureIconName(paymentFeature.name)}
                      priceCents={paymentFeature.costCents}
                      title={paymentFeature.name}
                      subtitle={paymentFeature.quantity > 1 ? `qty ${paymentFeature.quantity}` : 'billed one time'}
                      bottomText={''}
                    />
                  </BorderedBox>
                </Box>
              ))}

              {paymentStatus && !paymentStatus.hasInventory && (
                <>
                  <Gap />
                  <Text variant={TEXT_VARIANTS.v2title}>Other Add-ons</Text>
                  <Gap />
                  <BorderedBox>
                    <Row
                      iconName="inventory"
                      title={PLANS.inventory.title}
                      subtitle={`$${PLANS.inventory.per_month} / month`}
                      bottomText={
                        'Our Inventory Management keeps your stock levels updated, tracks product usage per service, and simplifies reordering with just a few clicks.'
                      }
                      actionButton={
                        <Button variant="round-outline" onClick={addInventory}>
                          {loadingAddInventory ? <Loading /> : 'Add'}
                        </Button>
                      }
                    />
                  </BorderedBox>
                </>
              )}
              <Gap />
            </>
          )}
        </Box>
      }
    />
  )
}

const Row = (props: {
  title: string
  subtitle: string
  iconName: string | null,
  priceCents?: number | string
  bottomText?: string
  quantity?: number
  actionButton?: ReactNode
}) => {
  const { title, subtitle, priceCents = null, quantity, bottomText, actionButton, iconName } = props
  return (
    <>
      <Flex justify="space-between">
        <Flex gridGap="24px" align="center">
          {iconName ? <SalonScaleIcon name={iconName} nameOverride={true} size='40px'/> : <Box h="40px" w="40px" bg={COLORS.lavender_300} borderRadius="8px" />}
          <Box>
            <Text>{title}</Text>
            <Text color={COLORS.text_secondary}>{subtitle}</Text>
          </Box>
        </Flex>
        <Box>
          {actionButton}
          {/*todo: extract this into a util*/}
          {(priceCents === 0 || isNumber(priceCents)) && <Box>{priceCents > 0 ? '$' + formatCentsToDollars(priceCents) : 'included'}</Box>}
          {priceCents !== 0 && priceCents && !isNumber(priceCents) && <Box>{priceCents}</Box>}
          {quantity && <Text color={COLORS.text_secondary}>qty {quantity}</Text>}
        </Box>
      </Flex>
      {bottomText && <>
        <Gap />
        <Text color={COLORS.text_secondary}>{bottomText}</Text>
        </>
      }
    </>
  )
}
