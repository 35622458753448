import React, { useEffect } from 'react'
import { PageHeader } from '../../mini-lib/page-header/PageHeader'
import { Box, Circle, Divider, Flex, Img, Text } from '@chakra-ui/react'
import { PageLayout } from '../../mini-lib/layouts/PageLayout'
import { MaterialIcon } from '../../mini-lib/icons/MaterialIcon'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { generatePath, Link, useLocation } from 'react-router-dom'
import { COLORS } from '../../mini-lib/theme/colors'
import { SalonSettingsSideSheet } from './SalonSettingsSideSheet'
import { SalonDefaultUnit } from './SalonDefaultUnit'
import { useAppSelector } from '../../hooks'
import { manageChargebeeSubscription } from '../../integrations/chargebee/service'
import { SalonScaleIcon } from '../../mini-lib/icons/SalonScaleIcon'
import { selectActiveSalonContext } from '../../data/salon/slice'
import { useDispatch } from 'react-redux'
import { dispatchLaraGetSalon, dispatchLaraListSalons } from '../../data/salon/api'
import { CancellationAndRefundPolicies } from '../signup/v1/CheckoutPage'
import { ReleaseSquare, ReleaseStripe } from '../../mini-lib/flags/Release'
import { ROUTES } from '../../appRoutes'
import { Gap } from '../../mini-lib/gap/Gap'
import { dispatchBookingPermissions } from '../../data/integrations/api'
import { getCurrentPaymentStatus } from '../../data/user/utils'

export const SalonSettingsPage = () => {
  const dispatch = useDispatch()
  const { user, salonId } = UseBaseApiParams()
  const salon = useAppSelector(selectActiveSalonContext)
  const loaded = !!salon
  const breadcrumbs = [{ label: 'Home', url: generatePath(ROUTES.home, { salonId }) }, { label: 'Salon Settings' }]
  const [showSalonSettings, setShowSalonSettings] = React.useState(false)
  const [showDefaultUnit, setShowDefaultUnit] = React.useState(false)
  useEffect(() => {
    if (user.token && !loaded) {
      // list all of the salons a user has
      dispatch(dispatchLaraListSalons({ token: user.token, userId: user.userId }))
      // todo: consider adding salon id to lara list salons and setting active context on that api instead of calling this get
      dispatch(dispatchLaraGetSalon({ token: user.token, userId: user.userId, salonId }))
    }
    user.token && loaded && dispatch(dispatchBookingPermissions({ token: user.token, salonId }))
  }, [dispatch, user.token, user.userId, loaded, salonId])
  return (
    <PageLayout
      variant="full"
      header={<PageHeader title="Salon Settings" breadcrumbs={breadcrumbs} />}
      content={
        <Box maxW="500px">
          {salon && <SalonSettingsSideSheet salon={salon} show={showSalonSettings} setShow={setShowSalonSettings} />}
          {salon && <SalonDefaultUnit show={showDefaultUnit} setShow={setShowDefaultUnit} />}
          <SalonSettings setShowSalonSettings={setShowSalonSettings} setShowDefaultUnit={setShowDefaultUnit} />
        </Box>
      }
    />
  )
}

export const SalonSettings = (props: {
  setShowSalonSettings: (show: boolean) => void
  setShowDefaultUnit: (show: boolean) => void
}) => {
  const dispatch = useDispatch()

  const { setShowSalonSettings, setShowDefaultUnit } = props
  const { user, salonId } = UseBaseApiParams()

  const salon = useAppSelector(selectActiveSalonContext)
  const loaded = !!salon

  const squareReleased = ReleaseSquare()

  const location = useLocation()
  const isTrial = location.pathname.includes('trial')

  useEffect(() => {
    if (user.token && !loaded) {
      dispatch(dispatchLaraGetSalon({ token: user.token, userId: user.userId, salonId }))
    }
  }, [dispatch, user.token, user.userId, loaded, salonId])

  const releaseStripe = ReleaseStripe()
  const url = releaseStripe
    ? generatePath(ROUTES.checkoutV2Subscription, { salonId })
    : generatePath(ROUTES.checkoutV1Subscription, { salonId })

  return (
    <>
      {salon && (
        <Box>
          {/* salon profile section */}
          {/**/}
          <Box h="24px" />
          <Text color={COLORS.text_secondary}>Profile</Text>
          <Box h="12px" />
          <Flex justify="space-between" align="center" cursor="pointer" onClick={() => setShowSalonSettings(true)}>
            <Flex gridGap="12px" align="center">
              {salon?.photoUrl && (
                <Img h="44px" w="44px" src={salon?.photoUrl} borderRadius="50%" bg={COLORS.lavender_50} />
              )}
              {!salon?.photoUrl && (
                <Circle size="44px" bg="brand.lavender.100">
                  <SalonScaleIcon size="44px" h="44px" w="44px" name="home" />
                </Circle>
              )}
              <Box>
                <Text fontWeight="bold">{salon?.name}</Text>
              </Box>
            </Flex>
            <MaterialIcon size="32px" colorhex={COLORS.text_secondary} name="chevron_right" />
          </Flex>

          <Box h="24px" />
          {!isTrial && (
            <>
              <Divider />
              <Box h="24px" />
              {/* salon settings */}
              {/**/}
              <Text color={COLORS.text_secondary}>Settings</Text>
              <Box h="12px" />

              <Link to={generatePath(ROUTES.permissionSettings, { salonId: salon?.id })}>
                <SalonSettingTile iconName="supervisor_account" label="Permissions" />
              </Link>

              <Flex justify="space-between" align="center" cursor="pointer" onClick={() => setShowDefaultUnit(true)}>
                <SalonSettingTile iconName="supervisor_account" label="Default Unit" />
              </Flex>

              <Box h="24px" />
            </>
          )}
          <Divider />
          <Box h="24px" />

          {/* purchase scales section */}
          {/**/}
          <Text color={COLORS.text_secondary}>Accessories</Text>

          <Box h="12px" />

          <a target="_blank" href="https://salonscale.com/bluetooth-scale/" rel="noreferrer">
            <SalonSettingTile iconName="monitor_weight" label="Purchase SalonScale Scale" />
          </a>

          <Box h="12px" />

          <a
            target="_blank"
            href="https://eightouncecoffee.com/collections/acaia-scales/products/acaia-pearl-coffee-scale-black?rfsn=7136873.0ee803&utm_source=refersion&utm_medium=affiliate&utm_campaign=7136873.0ee803"
            rel="noreferrer"
          >
            <SalonSettingTile iconName="monitor_weight" label="Purchase Acaia Scale" />
          </a>

          <Box h="24px" />
          <Divider />
          <Box h="24px" />

          {squareReleased && (
            <>
              <Text color={COLORS.text_secondary}>App</Text>
              <Box h="12px" />
              <Link to={generatePath(ROUTES.integrationSettings, { salonId: salon?.id })}>
                <SalonSettingTile iconName="power" label="Integrations" />
              </Link>
              <Box h="24px" />
              <Divider />
              <Box h="24px" />
            </>
          )}

          <Text color={COLORS.text_secondary}>Subscription</Text>
          <Box h="12px" />

          {isTrial ? (
            <Link to={url}>
              <SalonSettingTile iconName="local_offer" label="Create Subscription" />
            </Link>
          ) : (
            <>
              {releaseStripe
                ? <ManagePaymentTile />
                : <Box onClick={() => manageChargebeeSubscription()}>
                    <SalonSettingTile iconName="local_offer" label="Manage Subscription" />
                  </Box>
              }
            </>
          )}

          <Gap s="12px" />
          <Box color={COLORS.text_secondary}>
            <CancellationAndRefundPolicies />
          </Box>
        </Box>
      )}
    </>
  )
}

export const ManagePaymentTile = () => {
  const { user, salonId } = UseBaseApiParams()
  const paymentStatus = getCurrentPaymentStatus(user)

  if (paymentStatus && paymentStatus.paymentSystemType === 'chargebee') {
    return (
      <Box onClick={() => manageChargebeeSubscription()}>
        <SalonSettingTile iconName="local_offer" label="Manage Subscription" />
      </Box>
    )
  }

  if (paymentStatus && paymentStatus.paymentSystemType === 'stripe') {
    const stripeManagePaymentUrl = generatePath(ROUTES.managePayment, { salonId })
    return (
      <Link to={stripeManagePaymentUrl}>
        <SalonSettingTile iconName="local_offer" label="Manage Payment" />
      </Link>
    )
  }


  // default to gong to checkout url if no payment status
  const checkoutUrl = generatePath(ROUTES.checkoutV2Subscription, { salonId })
  return (
    <Link to={checkoutUrl}>
      <SalonSettingTile iconName="local_offer" label="Create Subscription" />
    </Link>
  )
}

export const SalonSettingTile = (props: { iconName: string; label: string }) => {
  const { iconName, label } = props
  return (
    <Flex justify="space-between" align="center" w="100%" cursor="pointer">
      <Flex gridGap="8px">
        <MaterialIcon color={COLORS.lavender_500} name={iconName} />
        <Text>{label}</Text>
      </Flex>
      <MaterialIcon cursor="pointer" size="32px" colorhex={COLORS.text_secondary} name="chevron_right" />
    </Flex>
  )
}
