export const sameDay = ( date1: Date, date2: Date ): boolean => {
  return (
    date1.getFullYear() === date2.getFullYear() &&
    date1.getDate() === date2.getDate() &&
    date1.getMonth() === date2.getMonth()
  )
}

export const buildViewDate = ( dateFromServer: string ): string => {
  const date = new Date(dateFromServer)
  return date.toLocaleDateString('en-US')
}

// js is creating a date that might be off by one day depending on timezone magic
// ref - https://stackoverflow.com/a/31732581/10795885
// to fix this i'm currently removing the timezone info

// eg if i'm in Calgary and i make a session on sept 12
// and you are viewing it in saskatoon you dont want it to be moved to your timezone, likely sept 11
export const buildDateFromServer = ( dateFromServer: string ): Date => {
  const dateWithoutTimezone = dateFromServer.slice(0, 10) // first take away server timezone
  const normalizedDateWithTimezone = dateWithoutTimezone + 'T00:00:00' // add default time zone in
  // now we can create an accurate date without js automatically diffing the your local timezone + the server timezone
  return new Date(normalizedDateWithTimezone)
}

export const buildDateYYYYMMDD = ( date: Date ): string => {
  const d = new Date(date)
  let month = '' + ( d.getMonth() + 1 )
  let day = '' + d.getDate()
  let year = d.getFullYear()

  if (month.length < 2) month = '0' + month
  if (day.length < 2) day = '0' + day

  return [year, month, day].join('-')
}
export const extractYYYYMMDDFromServerDateString = (dateString: string): string => {
  return dateString.split('T')[0];
}

export const extractYYYYMMDDFromRawSQLDateString = (dateString: string): string => {
  return dateString.split(' ')[0];
}
export const buildDateTimeAtStartOfDay = ( date: Date ): Date => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 0, 0)
}

export const buildDateTimeAtEndOfDay = ( date: Date ): Date => {
  return new Date(date.getFullYear(), date.getMonth(), date.getDate(), 23, 59)
}

// why have this dumb looking function you ask? immutability
//
// eg
// onChange={selectedDate => {
//   const startDate = selectedDate
//   const endDate = selectedDate
//   endDate.setDate(selectedDate.getDate() + 1) // start date now is date + 1 as well
// }
export const buildNextDay = ( date: Date ): Date => {
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate() + 1,
    date.getHours(),
    date.getMinutes(),
    date.getSeconds(),
    date.getMilliseconds()
  )
}

export const buildLaraServerDateTime = ( date: Date ): string => {
  const year = date.getFullYear().toString().padStart(4, '0');
  const month = ( date.getMonth() + 1 ).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  const hours = date.getHours().toString().padStart(2, '0');
  const minutes = date.getMinutes().toString().padStart(2, '0');
  const seconds = date.getSeconds().toString().padStart(2, '0');
  const timezoneOffset = date.getTimezoneOffset();
  const timezoneOffsetSign = timezoneOffset > 0 ? '-' : '+';
  const timezoneOffsetHours = Math.floor(Math.abs(timezoneOffset) / 60).toString().padStart(2, '0');
  const timezoneOffsetMinutes = Math.abs(timezoneOffset) % 60;

  const formattedDate = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}${timezoneOffsetSign}${timezoneOffsetHours}:${timezoneOffsetMinutes.toString().padStart(2, '0')}`;
  return formattedDate
}


// milliseconds is an easy way to break the cache when we do version updates
export const buildLaraServerDateWithTimeAs0 = ( date: Date, milliseconds = '04' ): string => {
  const year = date.getFullYear().toString().padStart(4, '0');
  const month = ( date.getMonth() + 1 ).toString().padStart(2, '0');
  const day = date.getDate().toString().padStart(2, '0');
  return `${year}-${month}-${day}T00:00:00-00:${milliseconds}`;
}

export const buildMMMDDYYYYDate = (date: Date | null): string => {
  if (!date) {
    return ''
  }
  return date.toLocaleDateString('en-US', {
    month: 'short', // 'short' for 'Feb', 'long' for 'February'
    day: 'numeric',
    year: 'numeric'
  })
}