import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerHeader,
  DrawerOverlay,
  Flex,
  Image,
  Text,
} from '@chakra-ui/react'
import React from 'react'
import { MaterialIcon } from '../../../mini-lib/icons/MaterialIcon'
import { useAppSelector } from '../../../hooks'
import perfectpairingSalonscaleLogo from '../../../assets/perfeectpair-salonscale-logo.png'
import {
  reduceSetIsSignupCartOpen,
  selectIsSignupCartOpen,
  selectSalonType,
  selectScaleQuantities,
  selectSelectedFreeScale,
  selectSubscriptionType,
} from '../../../data/signup/slice'
import { getPlanNameAndDescription, getPlanPrice, getScalePrice, getTotalPrice, } from '../../../data/signup/utils'
import { UseViewSize } from '../../../core/UseViewSize'
import { useDispatch } from 'react-redux'
import { generatePath, Link } from 'react-router-dom'
import { ReleaseSpecialPricing } from "../../../mini-lib/flags/Release";
import { UseBuildSignupQueryParams, UseCanSeeScales } from './signupHooks'
import { Policies } from "./CheckoutPage";
import { COLORS } from "../../../mini-lib/theme/colors";
import { keys } from "lodash";
import {BLACK_MINI_SCALE, ScaleMap} from "../../../data/signup/constants";
import { ROUTES } from "../../../appRoutes";
import {UseBaseApiParams} from "../../../core/UseBaseApiParams";

export const SignupSelectedItems = ( props: {
  includeAccessories?: boolean
  includeDescription?: boolean
  includeSectionTitles?: boolean
  appearDisabled?: boolean
  showIcons?: boolean
} ) => {
  const {
    includeAccessories = false,
    includeSectionTitles = true,
    appearDisabled = true,
    includeDescription = false,
    showIcons = true,
  } = props
  const salonType = useAppSelector(selectSalonType)
  const subscriptionType = useAppSelector(selectSubscriptionType)
  const freeScale = useAppSelector(selectSelectedFreeScale)
  const scaleQuantities = useAppSelector(selectScaleQuantities)

  const releaseSpecialPricing = ReleaseSpecialPricing()
  const canSeeScales = UseCanSeeScales()
  const { name, description } = getPlanNameAndDescription(salonType, subscriptionType, releaseSpecialPricing)
  const planPrice = getPlanPrice(salonType, subscriptionType, releaseSpecialPricing)
  // const scalesPrice = getScalesPrice(scaleQuantities, releaseSpecialPricing)
  const subscriptionImg =
    subscriptionType === 'perfectpairing'
      ? perfectpairingSalonscaleLogo
      : 'https://storage.googleapis.com/salonscale-prod-webapp-assets/SalonScale-AbstractMark-Midnight.png'
  return (
    <Box>
      {includeSectionTitles && <Text>Your Selected Plan</Text>}
      <Box h="12px"/>
      {salonType && subscriptionType && (
        <ItemBox
          itemImgUrl={subscriptionImg}
          itemName={name}
          itemDescription={includeDescription ? description : null}
          itemPrice={planPrice}
          isFreeTrial={subscriptionType === 'monthly'}
          appearDisabled={appearDisabled}
          itemQuantity={includeDescription ? 1 : null}
          showIcons={showIcons}
        />
      )}

      {/*everyone gets free scales now if they can see them*/}
      {/*(subscriptionType === 'yearly' || subscriptionType === 'perfectpairing' || scalesPrice > 0) && (*/}
      {includeAccessories &&
          <>
            {includeSectionTitles && (
              <>
                <Box h="24px"/>
                <Text>Your Selected Accessories</Text>
              </>
            )}

            {/*{(subscriptionType === 'yearly' || subscriptionType === 'perfectpairing') && (*/}
            {/*everyone gets free scales now if they can see them*/}
              <>
                  <Box h="12px"/>
                {canSeeScales && freeScale ? <ItemBox
                    itemName={`${freeScale.label} SCALE - FREE GIFT`}
                    itemPrice={getScalePrice(releaseSpecialPricing)}
                    itemDescription="Included with subscription"
                    itemDescriptionSubtitle="*scales only ship to USA and Canada"
                    isFree={true}
                    itemImgUrl={freeScale.imgUrl}
                    appearDisabled={appearDisabled}
                    itemQuantity={includeDescription ? 1 : null}
                    showIcons={showIcons}
                  />
                  : <ItemBox
                    itemName={`Scale unavailable`}
                    itemDescription=''
                    itemPrice={null}
                    itemDescriptionSubtitle="*scales only ship to USA and Canada"
                    isFree={false}
                    itemImgUrl={freeScale? freeScale.imgUrl : BLACK_MINI_SCALE.imgUrl}
                    appearDisabled={appearDisabled}
                    itemQuantity={null}
                    showIcons={showIcons}
                  />
                }
              </>
            {/*)}*/}
            {canSeeScales && keys(scaleQuantities).map(( scaleId ) => {
              const scale = ScaleMap[scaleId]
              const quantity = scaleQuantities[scaleId]
              const total = getScalePrice(releaseSpecialPricing) * quantity
              return (
                <Box key={scaleId}>
                  {quantity > 0 ? (
                    <>
                      <Box h="12px"/>
                      <ItemBox
                        itemName={`${scale.label} SCALE`}
                        itemDescription={includeDescription ? 'Accessory' : null}
                        itemDescriptionSubtitle="*scales only ship to USA and Canada"
                        itemImgUrl={scale.imgUrl}
                        itemPrice={total}
                        itemQuantity={quantity}
                        appearDisabled={appearDisabled}
                        showIcons={showIcons}
                        canRemove={total > 0}
                      />
                    </>
                  ) : (
                    <></>
                  )}
                </Box>
              )
            })}
          </>
      }
    </Box>
  )
}

export const SignupTotal = ( props: { showPolicies?: boolean } ) => {
  const { showPolicies = false } = props
  const salonType = useAppSelector(selectSalonType)
  const subscriptionType = useAppSelector(selectSubscriptionType)
  const scaleQuantities = useAppSelector(selectScaleQuantities)
  const releaseSpecialPricing = ReleaseSpecialPricing()
  // const planPrice = getPlanPrice(salonType, subscriptionType, releaseSpecialPricing)
  const total = getTotalPrice(salonType, subscriptionType, scaleQuantities, releaseSpecialPricing)
  return (
    <>
      <Box borderTop="1px solid" borderBottom="1px solid" borderColor="shades.neutral.200" p="12px 0">
        <Flex justify="space-between" align="center" pr="4px">
          <Text>Total Before Tax</Text>
          <Text>${total}</Text>
        </Flex>
      </Box>
      <Box h="12px"/>
      {showPolicies && <Policies/>}
    </>
  )
}

export const ItemBox = ( props: {
  itemName: string
  itemDescription?: string | null
  itemDescriptionSubtitle?: string | null
  itemPrice: number | null
  itemImgUrl?: string
  itemQuantity?: number | null
  isFreeTrial?: boolean
  isFree?: boolean
  iconName?: 'checkmark' | 'radio_button_unchecked' | 'radio_button_checked'
  showIcons: boolean
  appearDisabled: boolean
  canRemove?: boolean
} ) => {
  const { isMobile } = UseViewSize()
  const {salonId} = UseBaseApiParams()
  const {
    itemName,
    itemDescription,
    itemDescriptionSubtitle,
    itemPrice,
    showIcons ,
    itemImgUrl,
    itemQuantity,
    isFree = false,
    // isFreeTrial = false,
    iconName = 'checkmark',
    appearDisabled,
    canRemove = false
  } = props

  return (
    <Box
      w="100%"
      maxW="500px"
      p={isMobile ? '24px 12px' : '24px'}
      borderColor="shades.neutral.200"
      borderWidth="1px"
      borderRadius="15px"
    >
      <Flex justify="space-between" align="center">
        <Flex align="center" gridGap={isMobile ? '6px' : '24px'}>
          {showIcons && (
            <Flex w="48px" justify="center">
              {itemImgUrl ? (
                <Image h="24px" maxW="48px" src={itemImgUrl}/>
              ) : (
                <MaterialIcon name={iconName} size="24px"/>
              )}
            </Flex>
          )}
          <Box>
            <Text
              variant="title2"
              fontSize={isMobile ? '16px' : '20px'}
              fontWeight="regular"
              textTransform="uppercase"
              color={appearDisabled ? 'shades.neutral.500' : ''}
            >
              {itemName}
            </Text>
            {itemDescription && <Text color={appearDisabled ? 'shades.neutral.500' : ''}
                                      fontSize={isMobile ? '12px' : '14px'}>{itemDescription}</Text>}
            {itemDescriptionSubtitle &&
                <Text color={appearDisabled ? 'shades.neutral.500' : ''} fontSize={isMobile ? '10px' : '12px'}
                      fontStyle='italic'>{itemDescriptionSubtitle}</Text>}
          </Box>
        </Flex>
        <Box>
          <Flex align="center" justify='flex-end'>
            <Text
              variant={isMobile ? 'title3' : 'title2'}
              textTransform="uppercase"
              color={isFree || appearDisabled ? 'shades.neutral.400' : ''}
              textDecoration={isFree ? 'line-through' : ''}
            >
              {itemPrice ? `$${itemPrice}` : ''}
            </Text>
            {isFree && (
              <Text ml="4px" variant="title2" color={appearDisabled ? 'shades.neutral.500' : ''}>
                FREE
              </Text>
            )}
          </Flex>
          {itemQuantity && (
            <Text textAlign="end" fontSize="12px" color={appearDisabled ? 'shades.neutral.500' : ''}>
              Qty: {itemQuantity}
            </Text>
          )}
          {canRemove && (
            <Link
              to={{
                pathname: generatePath(ROUTES.checkoutV1Cart, { salonId }),
                search: UseBuildSignupQueryParams({ scaleQuantities: { blackmini: 0 } }),
              }}
            >
            <Text color={COLORS.lavender_500}>remove</Text>
            </Link>
          )}
        </Box>
      </Flex>
    </Box>
  )
}

export const CartSideSheet = ( props: {} ) => {
  const dispatch = useDispatch()
  const { isMobile } = UseViewSize()
  const isSignupCartOpen = useAppSelector(selectIsSignupCartOpen)
  return (
    <Drawer
      size="md"
      isOpen={isSignupCartOpen}
      placement="right"
      onClose={() => dispatch(reduceSetIsSignupCartOpen(false))}
    >
      <DrawerOverlay/>
      <DrawerContent>
        <DrawerCloseButton/>
        <DrawerHeader>
          <Text variant={isMobile ? 'largetitle' : 'gigantictext'} textTransform="uppercase">
            My Cart
          </Text>
        </DrawerHeader>

        <DrawerBody>
          <Flex direction="column" align="center">
            <Box w="100%">
              <SignupSelectedItems
                includeAccessories={true}
                appearDisabled={false}
                includeDescription={true}
                includeSectionTitles={false}
              />
              <Box h="24px"/>
              <SignupTotal/>
              <Box h="48px"/>
            </Box>
            <Link
              style={{ width: '100%' }}
              to={{ pathname: generatePath(ROUTES.signupCheckout), search: UseBuildSignupQueryParams() }}
            >
              <Button
                onClick={() => dispatch(reduceSetIsSignupCartOpen(false))}
                w="100%"
                variant="round-large"
                colorScheme="brand.midnight"
                bg={COLORS.midnight_900}
              >
                {isMobile ? 'check out' : 'review order and check out'}
              </Button>
            </Link>
          </Flex>
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}
