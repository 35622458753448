import React, { useCallback, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Button, Flex, Spinner } from '@chakra-ui/react'

import { useAppSelector } from '../../hooks'
import {
  reduceResetSessionsLara,
  reduceSessionsDateSelection,
  selectSessionsDateSelection,
  selectSessionsLara,
  selectSessionsPaginationLara,
} from '../../data/sessions/slice'
import { dispatchListSessionsLara } from '../../data/sessions/api'

import { PageHeader } from '../../mini-lib/page-header/PageHeader'
import { PageLayout } from '../../mini-lib/layouts/PageLayout'
import { SessionList } from './SessionList'
import { ClientAutocomplete } from '../clients/ClientAutocomplete'
import { StylistAutocompleteDeprecated } from '../stylists/StylistAutocompleteDeprecated'
import { filterSessionsLara } from '../../data/sessions/utils'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { UseViewSize } from '../../core/UseViewSize'
import { selectLoadingState } from '../../core/loading/slice'
import { generatePath, Link } from 'react-router-dom'
import { reduceSetSelectedClientId } from '../../data/clients/slice'
import { SessionCreateButton } from './SessionCreateSheet'
import { UseQueryParams } from '../../mini-lib/utils/basic'
import { hasMore } from '../../mini-lib/lara/lara-utils'
import { ROUTES } from '../../appRoutes'
import { HelpDrawer } from '../start-guide/common-components/HelpDrawer'
import { ReleaseOpenDay, ReleasePageHelp } from '../../mini-lib/flags/Release'
import { VIDEO_MAP } from '../../mini-lib/video/constants'
import { MaterialIcon } from '../../mini-lib/icons/MaterialIcon'
import { COLORS } from '../../mini-lib/theme/colors'
import { Gap } from '../../mini-lib/gap/Gap'
import { DateInput, SelectInput } from '../../mini-lib'
import { values } from 'lodash'
import {
  LOADING_SESSIONS,
  LOADING_SESSIONS_PAGE,
} from '../../data/sessions/constants'
import { DATE_RANGE_OPTION_MAP } from '../home/HomePage'
import { CustomDateRangeModal } from '../../mini-lib/dates-and-times/CustomDateRangeModal'
import { buildDateTimeAtEndOfDay, buildDateTimeAtStartOfDay, buildDateYYYYMMDD } from '../../core/dates'
import { convertDateRangeTypeToDates } from '../../mini-lib/dates-and-times/utils'
import { dispatchListUsersMetaLara } from '../../data/user/api'
import { DateRangeType } from '../../mini-lib/dates-and-times/constants'
import { FilterBar } from "../../mini-lib";
import { SessionStatusFilter } from "./SessionFilters";
import {SessionLimitBanner} from "../../mini-lib/payment-status/PaymentStatus";

export const SessionsPage = () => {
  const { user, salonId } = UseBaseApiParams()

  const dispatch = useDispatch()

  const breadcrumbs = [ { label: 'Home', url: generatePath(ROUTES.home, { salonId }) }, { label: 'Sessions' } ]

  const releasePageHelp = ReleasePageHelp()
  const loadingSessions = useAppSelector((state) => selectLoadingState(state, LOADING_SESSIONS))
  const loadingSessionsPage = useAppSelector((state) => selectLoadingState(state, LOADING_SESSIONS_PAGE))
  const paginationLara = useAppSelector(selectSessionsPaginationLara)
  const modelsLara = useAppSelector(selectSessionsLara)
  const dateSelection = useAppSelector(selectSessionsDateSelection)

  const releaseOpenDay = ReleaseOpenDay()

  const [ statusFilter, setStatusFilter ] = useState<string | null>(null)
  const [ selectedClientId, setSelectedClientId ] = useState<number | null>(null)
  const [ selectedUserId, setSelectedUserId ] = useState<number | null>(null)
  const [ startDate, setStartDate ] = useState<Date | null>(null)
  const [ endDate, setEndDate ] = useState<Date | null>(null)
  const [ isCustomDateModalOpen, setIsCustomDateModalOpen ] = useState(false)
  const [ localPageNumber, setLocalPageNumber ] = useState(paginationLara?.currentPage || 1)


  useEffect(() => {
    dispatch(dispatchListUsersMetaLara({ token: user.token, salonId }))
    dispatch(reduceSessionsDateSelection({type: null, endDate: null, startDate: null}))
    // eslint-disable-next-line
  }, [])

  const filteredModelsLara = modelsLara
    ? filterSessionsLara({
      models: modelsLara,
      clientId: selectedClientId,
      userId: selectedUserId,
      startDate: releaseOpenDay ? dateSelection.startDate : startDate,
      endDate: releaseOpenDay ? dateSelection.endDate : endDate,
      status: statusFilter || undefined,
    })
    : []

  const startDateString = dateSelection.startDate ? buildDateYYYYMMDD(dateSelection.startDate) : null
  const endDateString = dateSelection.endDate ? buildDateYYYYMMDD(dateSelection.endDate) : null

  useEffect(() => {
    if (releaseOpenDay) {
      dispatch(
        dispatchListSessionsLara({
          token: user.token,
          salonId,
          pageNumber: localPageNumber,
          pageSize: 30,
          startDateTime: dateSelection.startDate || undefined,
          endDateTime: dateSelection.endDate || undefined,
          loadingName: localPageNumber === 1 ? LOADING_SESSIONS : LOADING_SESSIONS_PAGE,
        }),
      )
    }
    if (!releaseOpenDay) {
      dispatch(
        dispatchListSessionsLara({
          token: user.token,
          salonId,
          pageNumber: localPageNumber,
          pageSize: 30,
          loadingName: LOADING_SESSIONS_PAGE
        }),
      )
    }
    // note: disabling this because passing date objects breaks the use effect, i could memoize them but that feels worse than this
    // eslint-disable-next-line
  }, [
    dispatch,
    dateSelection.type,
    user.token,
    salonId,
    startDateString,
    endDateString,
    localPageNumber,
    releaseOpenDay,
  ])

  return (
    <PageLayout
      variant="full"
      top={<SessionLimitBanner/>}
      header={
        <PageHeader
          title="Sessions"
          breadcrumbs={breadcrumbs}
          actions={
            <Flex justify="end" align="center">
              {releaseOpenDay && (
                <>
                  <Link to={generatePath(ROUTES.sessionsByMember, {salonId})}>
                    <MaterialIcon
                      cursor="pointer"
                      colorhex={COLORS.shades_neutral_400}
                      name="view_column"
                    />
                  </Link>
                  <Gap s="6px"/>
                  <MaterialIcon
                    cursor="pointer"
                    colorhex={COLORS.lavender_500}
                    name="list"
                  />
                  <Gap s="12px"/>
                </>
              )}
              <SessionCreateButton/>
            </Flex>
          }
          showHelp={true}
        />
      }
      content={
        <>
          {isCustomDateModalOpen && (
            <CustomDateRangeModal
              setIsOpen={setIsCustomDateModalOpen}
              isOpen={isCustomDateModalOpen}
              startDateValue={dateSelection.startDate || undefined}
              endDateValue={dateSelection.endDate || undefined}
              onSelect={({ startDate, endDate }) => {
                dispatch(reduceResetSessionsLara())
                dispatch(
                  reduceSessionsDateSelection({
                    type: 'custom',
                    startDate: startDate,
                    endDate: endDate,
                  }),
                )
              }}
            />
          )}
          {releasePageHelp && (
            <HelpDrawer
              video={VIDEO_MAP.creatingSessions}
              title="Let’s take a look inside Sessions"
              content={`A color session is the backbone of how SalonScale works. Every gram of hair color from your back bar needs to go through a color session so all the other features work properly. Sessions are 99% preformed on the mobile app, but also can be preformed on the Business Centre if needed. Your front staff can be added in teams and view sessions this way as well instead of using a phone at the front desk. `}
            />
          )}
          {modelsLara && paginationLara && (
            <>
              <Filters
                statusFilter={statusFilter}
                setStatusFilter={setStatusFilter}
                setIsCustomDateModalOpen={setIsCustomDateModalOpen}
                setLocalPageNumber={setLocalPageNumber}
                selectedClientId={selectedClientId}
                setSelectedClientId={setSelectedClientId}
                selectedUserId={selectedUserId}
                setSelectedUserId={setSelectedUserId}
                startDate={startDate}
                setStartDate={setStartDate}
                endDate={endDate}
                setEndDate={setEndDate}
              />
              <Gap s="12px"/>
              <SessionList sessions={filteredModelsLara} sessionsLoading={loadingSessions}/>
            </>
          )}
          {!loadingSessions && paginationLara && hasMore({ pagination: paginationLara }) && (
              <Flex justify="center" p="12px">
                <Button
                  colorScheme="brand.lavender"
                  variant="round-ghost"
                  w="200px"
                  onClick={() => {
                    setLocalPageNumber(paginationLara.currentPage + 1)
                  }}
                >
                  {loadingSessionsPage ? <Spinner/> : 'View More'}
                </Button>
              </Flex>
          )}

          {(loadingSessions || !paginationLara || !modelsLara || !filteredModelsLara) && (
            <Flex h="500px" align="center" justify="center">
              <Spinner/>
            </Flex>
          )}
        </>
      }
    />
  )
}

export const Filters = (props: {
  setLocalPageNumber: (page: number) => void
  setIsCustomDateModalOpen: (isOpen: boolean) => void,

  statusFilter: string | null,
  setStatusFilter: (filter: string) => void,

  selectedClientId: number | null,
  setSelectedClientId: (filter: number | null) => void,

  selectedUserId: number | null,
  setSelectedUserId: (filter: number | null) => void,

  startDate: Date | null,
  setStartDate: (filter: Date | null) => void,

  endDate: Date | null,
  setEndDate: (filter: Date | null) => void,

}) => {
  const {
    setIsCustomDateModalOpen,
    setLocalPageNumber,
    statusFilter,
    setStatusFilter,
    setSelectedClientId,
    setSelectedUserId,
    startDate,
    setStartDate,
    setEndDate,
  } = props
  const { user, salonId } = UseBaseApiParams()
  const dispatch = useDispatch()
  const { isMobile } = UseViewSize()
  const releaseOpenDay = ReleaseOpenDay()
  const dateSelection = useAppSelector(selectSessionsDateSelection)

  const onUserSelected = (userId: number | null) => {
    if (userId) {
      dispatch(
        dispatchListSessionsLara({
          token: user.token,
          salonId,
          pageNumber: 1,
          userId,
          pageSize: 100,
        }),
      )
      setSelectedUserId(userId)
    } else {
      setSelectedUserId(null)
    }
  }
  // this just makes the function static, it will not be updated unless any of its deps change
  // eslint-disable-next-line
  const stylistSelectedCallback = useCallback(onUserSelected, [ dispatch, salonId, user.token ])
  const queryParams = UseQueryParams()
  const userId = queryParams.get('userId')

  useEffect(() => {
    if (userId) {
      const normalizedUserId = parseInt(userId)
      stylistSelectedCallback(normalizedUserId)
    }
  }, [ stylistSelectedCallback, userId ])

  return (
    <FilterBar
      search={
        <ClientAutocomplete
            width={isMobile ? '100%' : '280px'}
            isClearable={true}
            onSelect={(client) => {
              if (client) {
                const allDates = DATE_RANGE_OPTION_MAP.all.value as DateRangeType
                const dates = convertDateRangeTypeToDates(allDates)
                dispatch(reduceSessionsDateSelection({ type: allDates, startDate: dates.start, endDate: dates.end }))
                dispatch(reduceSetSelectedClientId(client.id))
                dispatch(
                  dispatchListSessionsLara({
                    token: user.token,
                    salonId,
                    pageNumber: 1,
                    pageSize: 100,
                    clientId: client.id,
                  }),
                )
                setSelectedClientId(client.id)
              } else {
                setSelectedClientId(null)
              }
            }}
          />
      }
      filters={
      <>
        {!releaseOpenDay && (
            <DateInput
              label="Date"
              w={isMobile ? '47%' : '140px'}
              value={startDate}
              isClearable={true}
              onChange={(date) => {
                if (date) {
                  const localStartDate = buildDateTimeAtStartOfDay(date)

                  // the lara filters are not inclusive and will not accept a start/end date of the same date
                  // adding a day to the end date gets the desired results
                  // note: i dislike mutating the variable
                  const endDateTime = date
                  const localEndDate = buildDateTimeAtEndOfDay(endDateTime)
                  setStartDate(localStartDate)
                  setEndDate(localEndDate)
                  dispatch(
                    dispatchListSessionsLara({
                      token: user.token,
                      salonId,
                      pageSize: 100,
                      startDateTime: localStartDate,
                      endDateTime: localEndDate,
                    }),
                  )
                } else {
                  setStartDate(null)
                }
              }}
            />
          )}

          {releaseOpenDay && <SessionStatusFilter isMobile={isMobile} setStatusFilter={setStatusFilter} statusFilter={statusFilter}/>}

          <StylistAutocompleteDeprecated
            width={isMobile ? '47%' : '140px'}
            onSelect={(salonMember) => {
              if (salonMember && salonMember.userId) {
                onUserSelected(salonMember.userId)
              } else {
                onUserSelected(null)
              }
            }}
          />

          {releaseOpenDay && (
            <SelectInput
              w={isMobile ? '47%' : '140px'}
              variant="material"
              label="Date Range"
              value={dateSelection.type}
              options={values(DATE_RANGE_OPTION_MAP)}
              onChange={(value) => {
                // reset pagination
                setLocalPageNumber(1)
                if (value === 'custom') {
                  setIsCustomDateModalOpen(true)
                } else {
                  const dates = convertDateRangeTypeToDates(value)
                  dispatch(reduceResetSessionsLara())
                  dispatch(reduceSessionsDateSelection({ type: value, startDate: dates.start, endDate: dates.end }))
                  setIsCustomDateModalOpen(false)
                }
              }}
              allowFocus={true}
              isClearable={true}
            />
          )}
      </>
      }
    />
  )
}