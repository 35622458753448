import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { generatePath } from 'react-router-dom'
import React, { useEffect } from 'react'
import { PageLayout } from '../../../mini-lib/layouts/PageLayout'
import { PageHeader } from '../../../mini-lib/page-header/PageHeader'

import { Box, Table, TableContainer } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../../hooks'
import { ReportTableContent, ReportTableHeaders } from '../ReportTable'
import {
    selectColorLaborReports,
    selectCustomEndDate,
    selectCustomStartDate,
    selectReportsDateRangeType,
    selectSelectedBrand,
} from '../../../data/reports-v2/slice'
import { dispatchGetColorLaborReport } from '../../../data/reports-v2/api'
import { ReportSectionHeader } from '../ReportSectionHeader'
import { COLORS } from '../../../mini-lib/theme/colors'
import { EmptyBox } from '../../../mini-lib/empty/EmptyBox'
import { Loading } from '../../../mini-lib/loading/Loading'
import { REPORTS_LOADING_CONSTANT } from '../../../data/reports-v2/constants'
import { selectLoadingState } from '../../../core/loading/slice'
import { convertDateRangeTypeToDates } from '../../../mini-lib/dates-and-times/utils'
import { buildDateYYYYMMDD } from '../../../core/dates'
import { buildCsv } from '../../../mini-lib/csv/buildCsv'
import { ColorBrandReport } from '../../../data/reports-v2/interfaces'
import { COLOR_BRAND_DETAILS_REPORTS_TABS, ColorLineDetailsReportsTabs } from './ColorLineDetailsReportsTabs'
import { UseViewSize } from '../../../core/UseViewSize'
import { ROUTES } from "../../../appRoutes";
import { orderBy } from 'lodash'

export const ColorLaborReportsPage = () => {
    const dispatch = useDispatch()
    const { isMobile, isTablet } = UseViewSize()

    const {
        user: { token },
        salonId,
    } = UseBaseApiParams()
    const breadcrumbs = [
        { label: 'Reports', url: generatePath(ROUTES.colorLaborReports, { salonId }) },
        { label: isMobile ? 'Service' : 'Service Report' },
    ]
    const models = useAppSelector(selectColorLaborReports)
    const orderedModels = orderBy(models, ['laborName'])
    const currentlyLoading = useAppSelector((state) => selectLoadingState(state, REPORTS_LOADING_CONSTANT))

    const dateFilter = useAppSelector(selectReportsDateRangeType)
    const dates = dateFilter ? convertDateRangeTypeToDates(dateFilter) : null
    const customStartDate = useAppSelector(selectCustomStartDate)
    const customEndDate = useAppSelector(selectCustomEndDate)
    const currentBrand = useAppSelector(selectSelectedBrand)
    const sDate = dateFilter === 'custom' ? customStartDate : dates ? buildDateYYYYMMDD(dates.start) : null
    const eDate = dateFilter === 'custom' ? customEndDate : dates ? buildDateYYYYMMDD(dates.end) : null

    useEffect(() => {
        dispatch(dispatchGetColorLaborReport({ token, salonId, dateStart: sDate, dateEnd: eDate }))
    }, [dispatch, token, salonId, sDate, eDate, currentBrand])

    const tableHeaders = [{ label: 'Service' }, { label: 'Menu' }, { label: 'Sessions' }, { label: 'Avg Color' }]
    const tableProperties = [
        { name: 'laborName', variant: 'text', mainText: true, size: 'm' },
        { name: 'laborCategory', variant: 'text', size: 'm' },
        { name: 'totalSessionsCount', variant: 'text', size: isMobile || isTablet ? 'sm' : 'm' },
        { name: 'averageColorCost', variant: 'money', size: isMobile || isTablet ? 'sm' : 'm' },
    ]
    const onCsvExport = () => {
        const formattedModels: ColorBrandReport[] | any[] = orderedModels
            ? orderedModels.map((model) => ({
                laborName: model.laborName,
                laborCategory: model.laborCategory,
                totalSessionsCount: model.totalSessionsCount,
                averageColorCost: '$' + model.averageColorCost,
            }))
            : []
        const rows: any = formattedModels ?? []
        const headers = tableHeaders.map((header) => header.label)
        buildCsv('color service report', headers, rows)
    }

    return (
        <PageLayout
            variant="full"
            header={<PageHeader title="Service Reports" breadcrumbs={breadcrumbs} />}
            content={
                <>
                    {!isMobile && <Box h="24px" />}
                    <ColorLineDetailsReportsTabs
                        selectedTab={COLOR_BRAND_DETAILS_REPORTS_TABS.unusedColors}
                        onCsvExport={onCsvExport}
                    />
                    <Box h="24px" />
                    <ReportSectionHeader
                        label={'Services'}
                        bgHex={COLORS.lavender_100}
                    />

                    {!currentlyLoading && !!orderedModels && orderedModels.length > 0 && (
                        <TableContainer>
                            <Table>
                                <ReportTableHeaders headers={tableHeaders} />
                                <ReportTableContent properties={tableProperties} models={orderedModels} />
                            </Table>
                        </TableContainer>
                    )}
                    {!currentlyLoading && !!models && models.length === 0 && (
                        <EmptyBox content="No services were used in this time period" />
                    )}
                    <Box h="24px" />
                    {(!orderedModels || currentlyLoading) && <Loading />}
                </>
            }
        />
    )
}
