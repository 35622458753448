import { buildLaraConfig } from "../../mini-lib/lara/lara-utils";
import { GetServerBaseUrl } from "../../env";
import axios from "axios";
import { Dispatch } from "@reduxjs/toolkit";
import {APIStripeCheckout} from "./interfaces";
import {generatePath} from "react-router-dom";
import {ROUTES} from "../../appRoutes";
import {getEnvConfig} from "../../config";
import {reduceSetLoadingState} from "../../core/loading/slice";
import {STRIPE_CREATE, STRIPE_LOADING} from "./constants";

export const apiStripeCheckout = ( params: { token: string, salonId: number, userId: number, body: APIStripeCheckout } ): Promise<string> => {
  const { token, salonId, userId, body } = params
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/checkout?impersonate_user_id=${userId}`

  return axios
    .post(url, body, config)
    .then(resp => {
      return resp.data.url
    })
    .catch(( error ) => {
      throw error
    })
}

export const dispatchStripeCheckout = ( params: { token: string, salonId: number, userId: number, body: APIStripeCheckout, loadingId?: string } ) => {
  const {loadingId = STRIPE_CREATE} = params
  return ( dispatch: Dispatch ) => {
    dispatch(reduceSetLoadingState({ name: loadingId, state: true }))
    return apiStripeCheckout(params).then(stripeUrl => {
      window.location.href = stripeUrl
      dispatch(reduceSetLoadingState({ name: loadingId, state: true }))
    })
  }
}


export const apiStripeManage = ( params: { token: string, salonId: number, userId: number, } ): Promise<string> => {
  const { token, salonId, userId } = params
  const envConfig = getEnvConfig()
  const body = {
    return_url: envConfig.reactHost + generatePath(ROUTES.managePayment, {salonId})
  }
  const config = buildLaraConfig({ token })
  const url = `${GetServerBaseUrl('v3', 'lara')}/salons/${salonId}/billing?impersonate_user_id=${userId}`

  return axios
    .post(url, body, config)
    .then(resp => {
      return resp.data.url
    })
    .catch(( error ) => {
      throw error
    })
}

export const dispatchStripeManage = ( params: { token: string, salonId: number, userId: number } ) => {
  return ( dispatch: Dispatch ) => {
    dispatch(reduceSetLoadingState({ name: STRIPE_LOADING, state: true }))
    return apiStripeManage(params).then(stripeUrl => {
      window.location.href = stripeUrl
      // leaving this here in case we open it in a new tab or something later
      dispatch(reduceSetLoadingState({ name: STRIPE_LOADING, state: false }))
    })
  }
}
