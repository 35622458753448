import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Text,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { useAppSelector } from '../../../hooks'

import { ColorForMode } from '../../../theme'
import { Loading } from '../../../mini-lib/loading/Loading'
import { EmptyBox } from '../../../mini-lib/empty/EmptyBox'
import { MaterialIcon } from '../../../mini-lib/icons/MaterialIcon'
import { COLORS } from '../../../mini-lib/theme/colors'
import { StickySearch } from '../../../mini-lib/search/StickySearch'
import {Labor} from "../../../data/labor/interfaces";
import {selectLaborsForUser} from "../../../data/labor/slice";

export const LaborSelectSheet = (props: {
  show: boolean
  onHide: () => void
  selectedLaborIds: number[]
  onLaborsSelected: (labors: Labor[]) => void
}) => {
  const [searchText, setSearchText] = useState('')
  const { show, onHide, onLaborsSelected, selectedLaborIds } = props
  return (
    <Drawer isOpen={show} placement="right" size="md" onClose={onHide}>
      <DrawerOverlay />
      <DrawerCloseButton />
      <DrawerContent p="0">
        <DrawerBody p="0">
          <Box p="24px">
            <Flex justify="space-between">
              <Text variant="title1" fontWeight="bold">
                Services
              </Text>
              <Button
                className="cy-labor-sheet-cancel"
                colorScheme="brand.skylight"
                variant="round-outline"
                minW="100px"
                onClick={onHide}
              >
                Cancel
              </Button>
            </Flex>
            <Box h="24px" />
            <StickySearch
              isSticky={false}
              iconColor="brand.skylight.500"
              variant="clear"
              placeholder={'Search Services'}
              searchText={searchText || ''}
              setSearchText={(t: string) => setSearchText(t)}
            />
          </Box>
          <LaborSheetList
            searchText={searchText}
            selectedLaborIds={selectedLaborIds}
            onLaborsSelected={onLaborsSelected}
          />
        </DrawerBody>
      </DrawerContent>
    </Drawer>
  )
}

export const LaborSheetList = (props: {
  searchText: string
  selectedLaborIds: number[]
  onLaborsSelected: (labors: Labor[]) => void
}) => {
  const labors = useAppSelector(selectLaborsForUser)
  const laborsLoaded = !!labors
  const { searchText, selectedLaborIds, onLaborsSelected } = props
  const [numLaborsToShow, setNumLaborsToShow] = useState(50)

  const initialIds = selectedLaborIds ? selectedLaborIds : []
  const [localSelectedLaborIds, setLocalSelectedLaborIds] = useState<number[]>(initialIds)

  const toggleLaborSelected = (laborId: number) => {
    if (localSelectedLaborIds.includes(laborId)) {
      setLocalSelectedLaborIds(localSelectedLaborIds.filter(id => id !== laborId))
    } else {
      setLocalSelectedLaborIds([...localSelectedLaborIds, laborId])
    }
  }

  const filteredLabors: Labor[] = labors ? labors.filter((labor) => labor.name.toLowerCase().includes(searchText)) : []
  const selectedLabors: Labor[] = labors ? labors.filter(labor => localSelectedLaborIds.includes(labor.id)) : []
  return (
    <>
      {!laborsLoaded && <Loading variant="clear" />}
      {laborsLoaded && labors.length > 0 && (
        <>
          {filteredLabors && filteredLabors.length > 0 ? (
            filteredLabors.slice(0, numLaborsToShow).map((labor, index) => {
              return (
                <LaborRow
                  toggleLaborSelected={toggleLaborSelected}
                  index={index}
                  isSelected={localSelectedLaborIds.includes(labor.id)}
                  labor={labor}
                  key={labor.id}
                />
              )
            })
          ) : (
            <EmptyBox content="No labors match that filter" />
          )}
          {numLaborsToShow < labors.length && (
            <Flex justify="center" m="12px 0">
              <Button
                colorScheme="brand.skylight"
                variant="ghost"
                onClick={() => setNumLaborsToShow(numLaborsToShow + 50)}
              >
                View More Services
              </Button>
            </Flex>
          )}
          <Box h="60px" />
          <Box position="absolute" p="" m="0">
            <Flex position="fixed" bottom="0" justify="center" p="0" w="100%" h="60px">
              <Button
                colorScheme="brand.skylight"
                variant="round"
                maxW="100%"
                w="350px"
                p="24px"
                onClick={() => onLaborsSelected(selectedLabors)}
              >
                <Box w="100%">
                  <Box w="100%">Select Services</Box>
                  <Box w="100%">
                    <SelectedLabors labors={selectedLabors} />
                  </Box>
                </Box>
              </Button>
            </Flex>
          </Box>
        </>
      )}
      {laborsLoaded && labors.length === 0 && (
        <EmptyBox title="You don't have any services yet!" content="Please add some services and come back" />
      )}
    </>
  )
}

export const LaborRow = ({
  index,
  labor,
  isSelected,
  toggleLaborSelected,
}: {
  index: number
  labor: Labor
  isSelected: boolean
  toggleLaborSelected: (laborId: number) => void
}) => {
  return (
    <Box
      p="12px 24px"
      bg={(index + 1) % 2 === 0 ? ColorForMode('secondary-bg') : ''}
      maxW="100%"
      onClick={() => toggleLaborSelected(labor.id)}
    >
      <Flex align="center" gridGap="12px" cursor="pointer">
        {isSelected ? (
          <MaterialIcon colorhex={COLORS.skylight_500} name="check_circle" />
        ) : (
          <MaterialIcon colorhex={COLORS.skylight_500} name="radio_button_unchecked" />
        )}
        <Flex align="center" gridGap="12px">
          <Box isTruncated={true}>
            <Text>{labor.name}</Text>
          </Box>
        </Flex>
      </Flex>
    </Box>
  )
}

export const SelectedLabors = (props: { labors: Labor[] }) => {
  const { labors } = props
  return (
    <Flex align="center" justify="center" isTruncated={true}>
      {labors.map((labor, index) => {
        return (
          <Flex key={index} align="center">
            <Text fontSize="10px">{labor?.name}</Text>
            {index + 1 !== labors.length && <Box m="0 8px">+</Box>}
          </Flex>
        )
      })}
    </Flex>
  )
}
