
import {createSlice, PayloadAction} from '@reduxjs/toolkit'
import {assign, keyBy, keys, map, omit, orderBy, uniqBy, values} from 'lodash'

import { RootState } from '../../store'
import {CategoryAndColor, Labor} from "./interfaces";
import {groupLaborsByCategory} from "./utils";
import {COLORS} from "../../mini-lib/theme/colors";

// state
//
interface LaborState {
  laborsById: { [key: string]: Labor } | null,
  laborsForUser: Labor[] | null,
  selectedCategoryAndColor: CategoryAndColor | null
  editableLaborCategoryAndColor: CategoryAndColor
  selectedLabor: Labor | null
}

const initialState: LaborState = {
  laborsById: null,
  laborsForUser: null,
  selectedCategoryAndColor: null,
  editableLaborCategoryAndColor: {category: '', colorhex: COLORS.midnight_500},
  selectedLabor: null
}

// reducer
//
export const LaborSlice = createSlice({
  name: 'labors',
  initialState,
  reducers: {
    // labor item state
    //
    reduceListLabors: (state, action: PayloadAction<Labor[]>) => {
      state.laborsById = assign({}, state.laborsById, keyBy(action.payload, 'id'))
    },
    reduceListLaborsForUser: (state, action: PayloadAction<Labor[]>) => {
      state.laborsForUser = action.payload
    },
    reduceResetLabors: (state, action: PayloadAction<Labor[]>) => {
      state.laborsById = {}
    },
    reduceDeleteLabors: (state, action: PayloadAction<Labor[]>) => {
      const idsToRemove = action.payload.map((item) => item.id)
      state.laborsById = omit(state.laborsById, idsToRemove)
    },
    reduceSelectedLabor: (state, action: PayloadAction<Labor | null>) => {
      state.selectedLabor = action.payload
    },
    reduceSelectedLaborCategory: (state, action: PayloadAction<CategoryAndColor>) => {
      state.selectedCategoryAndColor = action.payload
    },
    reduceEditableLaborCategory: (state, action: PayloadAction<CategoryAndColor>) => {
      state.editableLaborCategoryAndColor = action.payload
    },
  },
})

// actions
//
export const {
  reduceListLabors,
  reduceListLaborsForUser,
  reduceResetLabors,
  reduceDeleteLabors,
  reduceSelectedLabor,
  reduceSelectedLaborCategory,
  reduceEditableLaborCategory
} = LaborSlice.actions

// selectors
//

export const selectLaborsById = (state: RootState) => state.labors.laborsById;

export const selectLabors = (state: RootState): Labor[] | null => {
  if (state.labors.laborsById && keys(state.labors.laborsById).length === 0) {
    return []
  }
  const items = values(state.labors.laborsById) || []

  if (items.length > 0) {
    const property1Sorter = (labor) => labor?.name.toLowerCase()
    return orderBy(items, [property1Sorter], ['asc'])
  }
  return null
}

export const selectLaborsForUser = (state: RootState): Labor[] | null => {
  const items = state.labors.laborsForUser

  if (items && items.length > 0) {
    const property1Sorter = (labor) => labor?.name.toLowerCase()
    return orderBy(items, [property1Sorter], ['asc'])
  }
  return null
}

export const selectAssociatedLabors = (state: RootState, labor: Labor): Labor[] => {
  const items = state.labors.laborsById ? values(state.labors.laborsById): []

  const filteredItems = items.filter(item => item.name === labor.name && item.category === labor.category)
  if (filteredItems && filteredItems.length > 0) {
    const property1Sorter = (labor) => labor?.name.toLowerCase()
    return orderBy(filteredItems, [property1Sorter], ['asc'])
  }
  return []
}

export const selectSelectedLaborCategoryAndColor = (state: RootState): CategoryAndColor | null => {
  return state.labors.selectedCategoryAndColor
}

export const selectSelectedLabor = (state: RootState): Labor | null => {
  return state.labors.selectedLabor
}
export const selectEditableLaborCategoryAndColor = (state: RootState): CategoryAndColor => {
  return state.labors.editableLaborCategoryAndColor
}
export const selectAllLaborCategoryAndColors = (state: RootState): CategoryAndColor[] => {
  const labors = values(state.labors.laborsById)
  const all = map(labors, (labor) => {return {category: labor.category, colorhex: labor.colorhex}})
  return uniqBy(all, 'category')
}
export const selectLaborsByCategory = (state: RootState): { [category: string]: Labor[] } | null => {
  return state.labors.laborsById
    ? groupLaborsByCategory(values(state.labors.laborsById))
    : null
}

// export
//
export default LaborSlice.reducer
