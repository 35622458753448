// used for grouping laborless session items and session supplies

import {COLORS} from "../../../mini-lib/theme/colors";

export const buildDefaultSessionLabor = (params: {
  salonId: number, sessionId: number
}) => ({
  id: -1,
  salonId: params.salonId,
  sessionId: params.sessionId,
  laborId: -1,
  stampedName: 'Hair Color',
  stampedCategory: 'Hair Color',
  stampedColorhex: COLORS.lavender_500,
  stampedDurationSeconds: 0,
})
