import { SessionMetaLara } from '../../../data/sessions/interfaces'
import { useDispatch } from 'react-redux'
import React from 'react'
import { useAppSelector } from '../../../hooks'
import {
  reduceIsExtensionSheetVisible,
  reduceSelectedLaborId,
  selectActiveSessionExtensions,
} from '../../../data/sessions/slice'
import {Box, Button, Divider, Flex, Text} from '@chakra-ui/react'
import { COLORS } from '../../../mini-lib/theme/colors'
import { SESSION_STATUS_OPTIONS } from '../../../data/sessions/constants'

import { formatCentsToDollars } from '../../../mini-lib/units/money'
import { Loading } from '../../../mini-lib/loading/Loading'
import { Gap } from '../../../mini-lib/gap/Gap'
import { getTotalSessionExtensionsCostLara } from '../../../data/sessions/utils'

export const SessionExtensions = (props: { sessionMeta: SessionMetaLara; laborId: number | null, isClickable?: boolean }) => {
  const dispatch = useDispatch()
  const { sessionMeta, laborId, isClickable = false } = props

  // lara session apis
  //
  //
  const sessionExtensions = useAppSelector((state) => selectActiveSessionExtensions(state, laborId))

  const extensionsTotal = sessionExtensions ? getTotalSessionExtensionsCostLara(sessionExtensions) : null
  return (
    <>
      {sessionExtensions && sessionExtensions.length > 0 && (
        <>
          <Gap s="12px" />
          <Flex
            cursor={sessionMeta.status === 'open' ? 'pointer' : ''}
            direction="column"
            p="12px"
            border={`1px solid ${COLORS.shades_neutral_300}`}
            borderRadius="8px"
            onClick={() => {
              if (sessionMeta.status === 'open') {
                dispatch(reduceIsExtensionSheetVisible(true))
                dispatch(reduceSelectedLaborId(laborId))
              }
            }}
          >
            <Flex justify="space-between">
              <Text fontWeight="bold">Extensions</Text>
              <Text fontWeight="bold">{extensionsTotal ? '$' + formatCentsToDollars(extensionsTotal) : ''}</Text>
            </Flex>
            <Gap s="8px" />
            <Divider />
            <Gap s="12px" />
            {sessionExtensions?.map((extension) => {
              return (
                <Box
                  key={extension.id}
                  onClick={() => {
                    if (isClickable && sessionMeta.status === SESSION_STATUS_OPTIONS.open) {
                      dispatch(reduceIsExtensionSheetVisible(true))
                      dispatch(reduceSelectedLaborId(laborId))
                    }
                  }}
                  cursor={isClickable && sessionMeta.status === SESSION_STATUS_OPTIONS.open ? 'pointer' : ''}
                  className="cy-session-extension"
                >
                  <Text color="text.secondary" fontSize="12px">
                    {extension.quantity} • {extension?.product?.size}
                    {'"'} • {extension?.product?.category} • {extension?.product?.type}
                  </Text>
                </Box>
              )
            })}
          </Flex>
        </>
      )}

      {!sessionExtensions && <Loading />}
    </>
  )
}

export const AddSessionExtensions = (props: { sessionMeta: SessionMetaLara; laborId: number | null }) => {
  const dispatch = useDispatch()
  const { sessionMeta, laborId } = props
  return (
    <>
      {sessionMeta.status === SESSION_STATUS_OPTIONS.open && (
        <Button
          variant="grey"
          onClick={() => {
            dispatch(reduceIsExtensionSheetVisible(true))
            dispatch(reduceSelectedLaborId(laborId))
          }}
        >
          + Add Extensions
        </Button>
      )}
    </>
  )
}
