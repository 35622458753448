import { Box, Button, Flex } from '@chakra-ui/react'
import React from 'react'
import { useCSVReader } from 'react-papaparse'
import { ThemeType } from '../../theme'

export const CsvUpload = (props: {
  buttonLabel?: string
  theme?: ThemeType
  variant?: 'round' | 'round-outline' | string
  onFileUpload: (rows: string[][]) => void
}) => {
  const { theme = 'lavender', variant = 'round', onFileUpload , buttonLabel = 'Upload CSV'} = props
  const { CSVReader } = useCSVReader()
  const config = { header: true, skipEmptyLines: true }
  return (
    <CSVReader
      config={config}
      onUploadAccepted={(results: { data: string[][]; errors: any; meta: any }) => {
        onFileUpload(results.data)
      }}
    >
      {({ getRootProps, acceptedFile, getRemoveFileProps }: any) => (
        <Flex align="center">
          {/*<Text>{acceptedFile && acceptedFile.name}</Text>*/}
          <Box h="12px" />
          <Flex align="center" gridGap="12px">
            <Button colorScheme={`brand.${theme}`} variant={variant} {...getRootProps()}>
              {buttonLabel}
            </Button>
            {/*{acceptedFile && <MaterialIcon cursor="pointer" {...getRemoveFileProps()} name="close" />}*/}
          </Flex>
        </Flex>
      )}
    </CSVReader>
  )
}
