import { UseBaseApiParams } from '../../../core/UseBaseApiParams'
import { PageLayout } from '../../../mini-lib/layouts/PageLayout'
import { PageHeader } from '../../../mini-lib/page-header/PageHeader'
import { Box, Button, Flex, Text } from '@chakra-ui/react'
import React from 'react'
import { UseBuildSignupQueryParams, UseCanSeeScales, UseInitializeSignupState } from '../../signup/v1/signupHooks'
import { SignupSelectedItems, SignupTotal } from '../../signup/v1/SignupCart'
import { UseViewSize } from '../../../core/UseViewSize'
import { useAppSelector } from '../../../hooks'
import {
  selectSalonType,
  selectScaleQuantities,
  selectSelectedFreeScale,
  selectSubscriptionType,
} from '../../../data/signup/slice'
import {
  SoloMonthlySubscriptionCard,
  SoloYearlySubscriptionCard,
  TeamMonthlySubscriptionCard,
  TeamYearlySubscriptionCard,
} from './CheckoutSubscriptionPage'
import { COLORS } from '../../../mini-lib/theme/colors'
import { launchChargebeePortal } from '../../../integrations/chargebee/service'
import { UseUtmQueryParams } from '../../../integrations/utm'
import { UseGrinQueryParams } from '../../../integrations/grin/utils'
import { Gap } from '../../../mini-lib/gap/Gap'
import { ReleaseSpecialPricing } from '../../../mini-lib/flags/Release'
import { generatePath, Link } from 'react-router-dom'
import { ROUTES } from '../../../appRoutes'

export const CheckoutCartPage = () => {
  const { user, salonId } = UseBaseApiParams()
  const { isMobile } = UseViewSize()
  UseInitializeSignupState()
  const canSeeScales = UseCanSeeScales()
  const freeScale = useAppSelector(selectSelectedFreeScale)
  const scaleQuantities = useAppSelector(selectScaleQuantities)
  const salonType = useAppSelector(selectSalonType)
  const subscriptionType = useAppSelector(selectSubscriptionType)
  const utmParams = UseUtmQueryParams()
  const grinParams = UseGrinQueryParams()
  const isSpecialPricing = ReleaseSpecialPricing()

  const planId = `${salonType}-${subscriptionType}`
  const breadcrumbs = []

  const checkout = () => {
    launchChargebeePortal({
      salonId: salonId,
      userId: user.userId,
      token: user.token,
      email: user.email,
      firstName: user.firstName,
      lastName: user.lastName,
      phone: user.phone ? user.phone : '',
      salonscaleCustomerId: user.currentSalonContext?.customerId ?? -1,
      salonType: salonType,
      subscriptionType: subscriptionType,
      freeScale: freeScale,
      scaleQuantities: scaleQuantities,
      heardAboutSalonscale: null,
      heardAboutSalonscaleExtraDetails: null,
      utmParams,
      grinParams,
      isSpecialPricing,
      canSeeScales,
    })
  }
  return (
    <>
      <PageLayout
        variant="full"
        header={<PageHeader title="" breadcrumbs={breadcrumbs} />}
        content={
          <>
            {isMobile && <><BackButton/><Gap/></>}
            <Flex justify="space-between">
              <Box>
                {!isMobile && (
                  <>
                    <BackButton/>
                    <Gap/>
                    <Text variant="title2">YOUR ORDER</Text>
                    <Gap/>
                    <Flex justify="center">
                      {planId === 'solo-monthly' && <SoloMonthlySubscriptionCard minH={'0'} showButton={false} />}
                      {planId === 'solo-yearly' && <SoloYearlySubscriptionCard minH={'0'} showButton={false} />}
                      {planId === 'team-monthly' && <TeamMonthlySubscriptionCard minH={'0'} showButton={false} />}
                      {planId === 'team-yearly' && <TeamYearlySubscriptionCard minH={'0'} showButton={false} />}
                    </Flex>
                  </>
                )}
              </Box>
              <Flex w={isMobile ? '100%' : '50%'} direction="column" align="center">
                <Box width="100%" maxW="500px" p={isMobile ? '0 12px' : ''}>
                  <Box
                    bg={COLORS.shades_neutral_0}
                    border={`1px solid ${COLORS.shades_neutral_300}`}
                    p={isMobile ? '12px' : '24px'}
                    borderRadius="20px"
                  >
                    <Text variant={isMobile ? 'largetitle' : 'default/regular/title3'}>Order Summary</Text>
                    <Gap />
                    <SignupSelectedItems
                      includeAccessories={true}
                      includeSectionTitles={false}
                      includeDescription={true}
                      appearDisabled={false}
                      showIcons={false}
                    />

                    <Gap />
                    <SignupTotal showPolicies={true} />

                    <Gap s="48px" />
                    <Button
                      variant="round-large"
                      bg={COLORS.midnight_900}
                      w="100%"
                      onClick={() => {
                        checkout()
                      }}
                    >
                      Secure Checkout
                    </Button>
                  </Box>

                  <Gap s="48px" />
                  <a href="https://salonscale.com/contact-sales/" target="_blank" rel="noreferrer">
                    <Flex justify="center">
                      Questions?{' '}
                      <Box ml="4px" fontWeight="bold" textDecoration="underline">
                        {' '}
                        Contact Customer Service
                      </Box>
                    </Flex>
                  </a>
                  <Gap s="12px" />
                </Box>
              </Flex>
            </Flex>
          </>
        }
      />
    </>
  )
}

const BackButton = () => {
  const { salonId } = UseBaseApiParams()
  const canSeeScales = UseCanSeeScales()
  return (
    <>
      <Flex justify="start" w="100%">
        <Link
          to={{
            pathname: canSeeScales
              ? generatePath(ROUTES.checkoutV1PaidScale, { salonId })
              : generatePath(ROUTES.checkoutV1Subscription, { salonId }),
            search: UseBuildSignupQueryParams(),
          }}
        >
          <Button variant="round-outline" colorScheme="brand.midnight">
            Back
          </Button>
        </Link>
      </Flex>
    </>
  )
}
