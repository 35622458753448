import React from 'react'
import * as Yup from "yup";
import { TextInput, SelectInput } from '../../mini-lib'
import { Formik } from "formik";
import { buildFormikProps } from "../../mini-lib/form/utils";
import { Box, Flex } from '@chakra-ui/react'
import { HUMAN_READABLE_COUNTRY_NAME } from '../../mini-lib/time/utils'
import { useAppSelector } from '../../hooks'
import { selectOrdersLara } from '../../data/orders/slice'

interface FormProps {
  address_line_1: string
  address_line_2?: string
  city: string
  state: string
  country: string
  zip: string
}

export const OrderAddressForm = (props: any) => {
  const { formRef, onSubmit } = props
  const ordersLara = useAppSelector(selectOrdersLara) ?? [];
  const shippingAddress = ordersLara.length ? ordersLara[0].shippingAddress : null

  React.useEffect(() => formRef.current.resetForm(), [formRef])

  const initialValues: FormProps = {
    address_line_1: shippingAddress?.address_line_1 ?? '',
    address_line_2: shippingAddress?.address_line_2 ?? '',
    city: shippingAddress?.city ?? '',
    state: shippingAddress?.state ?? '',
    country: shippingAddress?.country ?? '',
    zip: shippingAddress?.zip ?? ''
  }

  const validationSchema = Yup.object({
    address_line_1: Yup.string().required('Required'),
    city: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    country: Yup.string().required('Required'),
    zip: Yup.string().required('Required')
  })

  const countryList = Object.keys(HUMAN_READABLE_COUNTRY_NAME).map((countryKey) => ({ label: HUMAN_READABLE_COUNTRY_NAME[countryKey], value: HUMAN_READABLE_COUNTRY_NAME[countryKey] }))

  return (
    <>
      <Formik innerRef={formRef} onSubmit={(values: FormProps) => onSubmit(values)} initialValues={initialValues} validationSchema={validationSchema}>
        {({ handleSubmit, resetForm, ...formikParams }) => {
          const addressLineOneParams = buildFormikProps({ name: 'address_line_1', formikParams })
          const addressLineTwoParams = buildFormikProps({ name: 'address_line_2', formikParams })
          const cityParams = buildFormikProps({ name: 'city', formikParams })
          const stateParams = buildFormikProps({ name: 'state', formikParams })
          const countryParams = buildFormikProps({ name: 'country', formikParams })
          const zipParams = buildFormikProps({ name: 'zip', formikParams })

          return (
            <Box>
               <Flex justify='space-between' flexDirection={'column'} gridGap='24px'>
                <TextInput
                  variant='material'
                  label="*Address Line 1"
                  value={addressLineOneParams.value}
                  errorText={addressLineOneParams.errorText}
                  onBlur={addressLineOneParams.onBlur}
                  onChange={addressLineOneParams.onChange}
                  border='rounded'
                  h='50px'
                />
                <TextInput
                  variant='material'
                  label="Address Line 2"
                  value={addressLineTwoParams.value}
                  errorText={addressLineTwoParams.errorText}
                  onBlur={addressLineTwoParams.onBlur}
                  onChange={addressLineTwoParams.onChange}
                  border='rounded'
                  h='50px'
                />
                <TextInput
                  variant='material'
                  label="*City"
                  value={cityParams.value}
                  errorText={cityParams.errorText}
                  onBlur={cityParams.onBlur}
                  onChange={cityParams.onChange}
                  border='rounded'
                  h='50px'
                />
                <TextInput
                  variant='material'
                  label="*State"
                  value={stateParams.value}
                  errorText={stateParams.errorText}
                  onBlur={stateParams.onBlur}
                  onChange={stateParams.onChange}
                  border='rounded'
                  h='50px'
                />
                <Box>
                  <Flex justify='space-between' flexDirection='row' gridGap='24px'>
                    <SelectInput
                      w="50%"
                      variant='material'
                      placeholder='select an option'
                      label="*Country"
                      options={countryList}
                      value={countryParams.value}
                      errorText={countryParams.errorText}
                      onBlur={countryParams.onBlur}
                      onChange={countryParams.onChange}
                      border='rounded'
                      h='50px'
                    />
                    <TextInput
                      w="50%"
                      variant='material'
                      label="*Zip/Postal"
                      value={zipParams.value}
                      errorText={zipParams.errorText}
                      onBlur={zipParams.onBlur}
                      onChange={zipParams.onChange}
                      border='rounded'
                      h='50px'
                    />
                  </Flex>
                </Box>
              </Flex>
            </Box>
          )
        }}
      </Formik>
    </>
  )
}
