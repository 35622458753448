import React from "react";
import {useDispatch} from "react-redux";
import {Button, Drawer, DrawerBody, DrawerContent, Flex, Text} from "@chakra-ui/react";
import {DisableAutofocus} from "../../../mini-lib/disable-autofocus/DisableAutofocus";
import {COLORS} from "../../../mini-lib/theme/colors";
import {SessionMetaLara} from "../../../data/sessions/interfaces";
import {formatCentsToDollars} from "../../../mini-lib/units/money";
import {dispatchCompleteSessionLara} from "../../../data/sessions/api";
import {UseBaseApiParams} from "../../../core/UseBaseApiParams";
import {generatePath, Link} from "react-router-dom";
import {ROUTES} from "../../../appRoutes";

export const SessionCompleteDrawer = ( props: { sessionMeta: SessionMetaLara } ) => {
  const dispatch = useDispatch()
  const {salonId, user} = UseBaseApiParams()
  const {sessionMeta} = props

  return (
    <>
        <Drawer
          variant="alwaysOpen"
          isOpen={true}
          placement="bottom"
          onClose={() => {}}
          blockScrollOnMount={false}
          trapFocus={false}
        >
          <DrawerContent>
            <DisableAutofocus/>

              <DrawerBody borderTop={`2px solid ${COLORS.shades_neutral_300}`} p='12px'>
                <Flex justify='center' align='center' direction='column' gridGap='24px'>
                    <Flex justify='space-between' align='center' w='100%'>
                      <Text color={COLORS.text_secondary}>Total</Text>
                      <Text fontWeight='bold'>${formatCentsToDollars(sessionMeta.totalClientPurchasePriceCents)}</Text>
                    </Flex>
                    <Link to={generatePath(ROUTES.sessionsByMember, { salonId })}>
                      <Button
                        variant="basic"
                        colorScheme='brand.midnight'
                        onClick={() =>dispatch(dispatchCompleteSessionLara({ token: user.token, sessionId: sessionMeta.id, salonId }))}
                      >
                        Complete Session
                      </Button>
                    </Link>
                  </Flex>
              </DrawerBody>
          </DrawerContent>
        </Drawer>
    </>
  )
}
