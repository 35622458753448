import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../hooks'
import { selectLoggedInUserSalonPermissions } from '../../data/user/slice'
import { LoggedInUser } from '../../data/user/interfaces'
import { redirectToAngularAdmin } from '../../data/user/utils'
import { Redirect } from 'react-router-dom'
import { selectChecklistItemsByCode } from '../../data/start-guide/slice'
import { dispatchListUserChecklistItems } from '../../data/start-guide/api'
import { selectActiveSalonContext } from '../../data/salon/slice'
import { selectLoadingState } from '../../core/loading/slice'
import { LOADING_SALON } from '../../data/salon/constants'
import { dispatchLaraGetSalon } from '../../data/salon/api'
import { CHECKLIST_CODES } from '../../data/start-guide/constants'
import {getRedirectUrl, getRedirectUrlFromPaymentStatus} from './utils'
import {ReleaseQuickTrial, ReleaseStripe} from "../../mini-lib/flags/Release";
import { getEnvConfig } from "../../config";

export const AuthRedirect = (props: { user: LoggedInUser; redirectUrl?: string | null }) => {
  const dispatch = useDispatch()
  const { user, redirectUrl } = props

  const activeSalon = useAppSelector(selectActiveSalonContext)
  const userPermissions = useAppSelector(selectLoggedInUserSalonPermissions)
  const loadingSalon = useAppSelector((state) => selectLoadingState(state, LOADING_SALON))
  const checklistItems = useAppSelector(selectChecklistItemsByCode)
  const checklistLoaded = !!checklistItems
  const salonId = user?.currentSalonContext?.salonId || null
  const salonLoaded = !!activeSalon
  const userLoaded = !!user
  const releaseQuickTrial = ReleaseQuickTrial()
  const stripeReleased = ReleaseStripe()
  const config = getEnvConfig()

  const isChecklistCompleted = checklistLoaded
    ? checklistItems[CHECKLIST_CODES.addPricing]?.completed || checklistItems[CHECKLIST_CODES.autoPricing]?.completed
    : null
  const hasPricingData =
    salonLoaded &&
    !!activeSalon.colorsCount &&
    activeSalon.colorsCount > 0 &&
    !!activeSalon.colorsPricedCount &&
    activeSalon.colorsPricedCount > 0
  const finishedGuide = isChecklistCompleted || hasPricingData

  useEffect(() => {
    if (user?.userId && user?.token && !salonLoaded && !loadingSalon && salonId) {
      dispatch(dispatchLaraGetSalon({ token: user.token, userId: user.userId, salonId }))
    }
  }, [dispatch, user?.token, user?.userId, loadingSalon, salonLoaded, salonId])

  useEffect(() => {
    if (!checklistLoaded) {
      dispatch(dispatchListUserChecklistItems(user.token, user.userId))
    }
  }, [dispatch, checklistLoaded, user.token, user.userId])

  // todo: integrate payment status into this, maybe make a separate function and feature flag for redundancy
  //
  const url = checklistLoaded && salonLoaded && userLoaded
      ? stripeReleased
        ? getRedirectUrlFromPaymentStatus({user, userPermissions, finishedGuide, redirectUrl})
        : getRedirectUrl({user, userPermissions, finishedGuide, redirectUrl, releaseQuickTrial})
      : null

  if (url === 'admin') {
    redirectToAngularAdmin(user.token, user.userId, config.angularHost)
  } else if (url) {
    return <Redirect to={url} />
  }
  return <></>
}