import {
  APIPaymentStatus, APIStripeOrder, PaymentFeature,
  PaymentStatus
} from './interfaces'


export const mapAPIPaymentStatusToPaymentStatus = ( api: APIPaymentStatus ): PaymentStatus => {
  return {
    id: api.id,
    planId: api.plan_id,
    salonId: api.salon_id,
    customerId: api.customer_id,
    customerEmail: api.customer_email,
    paymentSystemType: api.payment_system_type,
    status: api.status,
    hasInventory: api.has_inventory,
    sessionLimit: api.session_limit,
    teamLimit: api.team_limit,
    subscriptionExpiresAt: api.subscription_expires_at,
    trialExpiresAt: api.trial_expires_at,
    limitIntervalSessionCount: api.limit_interval_session_count,
    limitIntervalAt: api.limit_interval_at,
    paymentInterval: api.payment_interval
  }
}


// note: each APIStripeOrder contains multiple APIStripeOrderItems
// a single salon might have multiple separate orders
//
export const mapAPIStripeOrdersToPaymentFeatures = (api: APIStripeOrder[]): PaymentFeature[] => {
  const paymentFeatures: PaymentFeature[] = []
  api.forEach(order => {
    order.order_lines.forEach(item => {
      const paymentFeature: PaymentFeature = {
        salonscaleOrderId: order.id,
        stripeCustomerId: order.customer_id,
        stripeOrderItemId: item.id,
        stripeOrderItemProductId: item.product_id,
        name: item.description,
        costCents: item.price_in_cents,
        quantity: item.quantity,
        currency: null,
      }
      paymentFeatures.push(paymentFeature)
    })
  })
  return paymentFeatures
}

