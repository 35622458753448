import { differenceInDays } from 'date-fns'
import { buildDateFromServer } from '../../core/dates'
import { LoggedInUserSalonContext } from '../user/interfaces'

// lara reference: https://github.com/SalonScaleTechnology/salonscale-lara/blob/master/app/Models/Salon.php#L228
export const hasActiveQuickTrialAndNoSubscription = (salonContext: LoggedInUserSalonContext): boolean => {
    const paymentStatus = salonContext.paymentStatus
    if (paymentStatus && paymentStatus.paymentSystemType === 'trial' && paymentStatus.status === 'active') {
        return true
    }
    if (!paymentStatus && salonContext.subscription?.subscriptionPlanId === 'trial-plan') {
        const expiryDate = buildDateFromServer(salonContext.subscription.subscriptionStatus)
        return differenceInDays(expiryDate, Date.now()) > 0
    }
    return false
}

// lara reference: https://github.com/SalonScaleTechnology/salonscale-lara/blob/master/app/Models/Salon.php#L228
export const hasExpiredQuickTrialAndNoSubscription = (salonContext: LoggedInUserSalonContext): boolean => {
    const paymentStatus = salonContext.paymentStatus
    if (paymentStatus && paymentStatus.paymentSystemType === 'trial' && paymentStatus.status !== 'active') {
        return true
    }

    // note: currently lara returns null if the trial is expired when it should return the expired trial date here
    if (!paymentStatus && salonContext.subscription?.subscriptionPlanId === 'trial-plan') {
        const expiryDate = buildDateFromServer(salonContext.subscription.subscriptionStatus)
        return differenceInDays(expiryDate, Date.now()) <= 0
    }
    return false
}

export const hasNoQuickTrialAndNoSubscription = (salonContext: LoggedInUserSalonContext): boolean => {
    const paymentStatus = salonContext.paymentStatus
    if (!paymentStatus) {
        return true
    }

    // simulate user with no trial and no sub
    // return true

    // django returns null here if there is no cb attached to the salon context
    // ref: https://github.com/SalonScaleTechnology/django-server/blob/master/products/models.py#L938
    //
    // note: if quick trial is released and they have an old subscription this might end up in a weird state on their first login
    // they will have no sub but also no quick trial state
    const djangoResponseHasNoSubscription = salonContext && salonContext.subscription && salonContext.subscription.subscriptionStatus === null
    const laraResponseHasNoSubscription = salonContext && !salonContext.subscription
    if (laraResponseHasNoSubscription || djangoResponseHasNoSubscription) {
        return true
    }
    return false
}