import React, { useEffect } from 'react'
import { Box, Divider, Drawer, DrawerBody, DrawerContent, DrawerOverlay, Flex, Img, Text } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { useAppSelector } from '../../hooks'
import { reduceIsMultiSalonSideSheetVisible, selectIsMultiSalonSideSheetVisible } from '../../data/multi-salon/slice'
import { COLORS } from '../../mini-lib/theme/colors'
import { SALONSCALE_LOGO_URL } from '../../data/constants'
import { MaterialIcon } from '../../mini-lib/icons/MaterialIcon'
import { generatePath, useLocation } from 'react-router-dom'
import { selectActiveSalonContext, selectSalons } from '../../data/salon/slice'
import { dispatchLaraListSalons } from '../../data/salon/api'
import { UseBaseApiParams } from '../../core/UseBaseApiParams'
import { Salon } from '../../data/salon/interfaces'
import { ROUTES } from "../../appRoutes";
import { UseIsInTrialV2 } from "../trial-v1/utils";

export const MultiSalonButton = () => {
  const dispatch = useDispatch()
  const activeSalon = useAppSelector(selectActiveSalonContext)

  return (
    <>
      <Flex justify="center" align="center" m="24px 0">
        <Flex justify="center" align="center" w="48px" h="48px" p="4px" borderRadius="12px" bg="brand.lavender.500">
          <img alt="logo" style={{ height: '100%', width: '100%', padding: '4px' }} src={SALONSCALE_LOGO_URL} />
        </Flex>
      </Flex>
      <Box onClick={() => dispatch(reduceIsMultiSalonSideSheetVisible(true))}>
        {activeSalon && <MultiSalonTile salonRow={activeSalon} iconName="chevron_right" />}
      </Box>
      <Divider />
      <Box h="24px" />
      <MultiSalonSideSheet />
    </>
  )
}

export const MultiSalonSideSheet = () => {
  const dispatch = useDispatch()
  const navVisible = useAppSelector(selectIsMultiSalonSideSheetVisible)

  return (
    <>
      <Drawer placement="left" onClose={() => dispatch(reduceIsMultiSalonSideSheetVisible(false))} isOpen={navVisible}>
        <DrawerOverlay />
        <DrawerContent padding={0}>
          <DrawerBody padding={0}>
            <MultiSalonList />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
    </>
  )
}

export const MultiSalonList = () => {
  const dispatch = useDispatch()
  const location = useLocation()

  const { user } = UseBaseApiParams()
  const salons = useAppSelector(selectSalons)
  const loaded = !!salons
  const isTrial = UseIsInTrialV2()
  useEffect(() => {
    if (user.token && !loaded) {
      dispatch(dispatchLaraListSalons({ token: user.token, userId: user.userId }))
    }
  }, [dispatch, user.token, user.userId, loaded])

  return (
    <Box>
      <Box p="24px">
        <Text variant="title1" fontWeight="bold">
          Locations
        </Text>
      </Box>
      {salons &&
        salons.map((salon) => {
          const currentSalonId = user.currentSalonContext?.salonId || ''

          // note: if their current salon is in trial but their other salons arent
          // and they select a different salon
          // this is going to send them to the other salon in a trial context which is a minor bug

          // todo: have the salon api return the current plan id in the response
          const link = isTrial
            ? generatePath(ROUTES.trialIndex, { salonId: salon.id })
            : salon.role?.role === 'owner' && currentSalonId
                ? location.pathname.replace(currentSalonId.toString(), salon.id.toString())
                : generatePath(ROUTES.sessions, { salonId: salon.id })

          const iconLink = isTrial
            ? generatePath(ROUTES.trialSalonSettings, { salonId: salon.id })
            : generatePath(ROUTES.salonSettings, { salonId: salon.id })

          return (
            <Box key={salon.id}>
              <MultiSalonTile
                salonRow={salon}
                showIcon={salon.role?.role === 'owner'}
                iconLink={iconLink}
                link={link}
                iconName="settings"
                isSelected={salon.id === currentSalonId}
              />
            </Box>
          )
        })}
    </Box>
  )
}

export const MultiSalonTile = (props: {
  salonRow: Salon
  isSelected?: boolean
  showIcon?: boolean
  iconName?: string
  link?: string
  iconLink?: string
}) => {
  const { salonRow, showIcon = true, isSelected = false, iconName = 'chevron_right', link, iconLink } = props
  const dispatch = useDispatch()

  const [isHover, setIsHover] = React.useState(false)
  const logoUrl = salonRow.photoUrl || SALONSCALE_LOGO_URL

  return (
    <Flex
      p="0 24px"
      cursor="pointer"
      justify="space-between"
      align="center"
      onMouseOver={() => setIsHover(true)}
      onMouseOut={() => setIsHover(false)}
      _hover={{ bg: COLORS.hover }}
      bg={isSelected ? COLORS.midnight_50 : ''}
      color={isSelected ? COLORS.lavender_500 : ''}
    >
      <Flex
        padding="12px 0"
        align="center"
        flex="1"
        gridGap="12px"
        onClick={() => {
          if (link) {
            window.location.href = link
            dispatch(reduceIsMultiSalonSideSheetVisible(false))
          }
        }}
      >
        <Flex justify="center" align="center">
          <Flex
            justify="center"
            align="center"
            w="38px"
            h="38px"
            p="2px"
            borderRadius="50px"
            bg={COLORS.lavender_100}
            border={isSelected || isHover ? `1px solid ${COLORS.lavender_500}` : ''}
            overflow='hidden'
          >
            <Img h="100%" w="100%" src={logoUrl} borderRadius="50%" bg={COLORS.lavender_50} />
          </Flex>
        </Flex>

        <Flex direction="column" justify="center">
          <Text fontWeight="bold" fontSize="14px">
            {salonRow.name}
          </Text>
        </Flex>
      </Flex>
      {showIcon && (
        <Flex
          pl='12px'
          _hover={{ bg: COLORS.hover }}
          borderRadius="100%"
          onClick={(e) => {
            if (iconLink) {
              e.preventDefault()
              window.location.href = iconLink
              dispatch(reduceIsMultiSalonSideSheetVisible(false))
            }
          }}
        >
          <MaterialIcon name={iconName} colorhex={COLORS.text_secondary} />
        </Flex>
      )}
    </Flex>
  )
}
