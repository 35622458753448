import {Text} from "@chakra-ui/react";
import React from "react";
import {Bullet, CheckoutSubscriptionCard} from "./CheckoutSubscriptionCard";
import {UseCanSeeScales} from "../../signup/v1/signupHooks";
import {PLANS} from "../../../integrations/stripe/constants";

export const EssentialMonthlySubscriptionCard = (props: { minH?: string; showButton?: boolean }) => {
  const { minH = '530px', showButton = true } = props
  return (
    <CheckoutSubscriptionCard
      minH={minH}
      topTitle=""
      isMostPopular={false}
      title={PLANS.essential_monthly.title}
      price={
        <>
          ${PLANS.essential_monthly.per_month}
          <Text as="span" fontSize="20px" fontWeight="normal">
            /MONTH
          </Text>
        </>
      }
      priceSubtitle={`$${PLANS.essential_monthly.per_year} per year`}
      priceBulletPoints={[
        <Bullet text="Up to 3 stylists" iconName={'dot'} />,
        <Bullet text="50 color appointments per month" iconName={'dot'} />,
      ]}
      bulletPoints={[
        <Bullet text="Includes" iconName={null} bold={true} />,
        <Bullet text="Color bar stylist app" />,
        <Bullet text="Backbar Reporting" />,
        <Bullet text="Stylists Insights" />,
        <Bullet text="Backbar Management" />,
        <Bullet text="Service Menu Tracking" />,
        <Bullet text="POS Integrations" />,
        <Bullet text="Limited Support" />,
      ]}
      theme="midnight"
      isSelected={false}
      billingInterval={PLANS.essential_monthly.interval}
      planType={PLANS.essential_monthly.id}
      showButton={showButton}
    />
  )
}

export const EssentialYearlySubscriptionCard = (props: { minH?: string; showButton?: boolean }) => {
  const { minH = '530px', showButton = true } = props
  return (
    <CheckoutSubscriptionCard
      minH={minH}
      isMostPopular={false}
      topTitle=""
      title={PLANS.essential_yearly.title}
      price={
        <>
          ${PLANS.essential_yearly.per_month}
          <Text as="span" fontSize="20px" fontWeight="normal">
            /MONTH
          </Text>
        </>
      }
      priceSubtitle={`$${PLANS.essential_yearly.per_year} per year`}
      priceBulletPoints={[
        <Bullet text="Up to 3 stylists" iconName={'dot'} />,
        <Bullet text="50 color appointments per month" iconName={'dot'} />,
      ]}
      bulletPoints={[
        <Bullet text="Includes" iconName={null} bold={true} />,
        <Bullet text="Color bar stylist app" />,
        <Bullet text="Backbar Reporting" />,
        <Bullet text="Stylists Insights" />,
        <Bullet text="Backbar Management" />,
        <Bullet text="Service Menu Tracking" />,
        <Bullet text="POS Integrations" />,
        <Bullet text="Limited Support" />,
      ]}
      theme="midnight"
      isSelected={false}
      billingInterval={PLANS.essential_yearly.interval}
      planType={PLANS.essential_yearly.id}
      showButton={showButton}
    />
  )
}


export const SignatureYearlySubscriptionCard = (props: { minH?: string; showButton?: boolean }) => {
  const { minH = '530px', showButton = true } = props
  const canSeeScales = UseCanSeeScales()
  return (
    <CheckoutSubscriptionCard
      minH={minH}
      topTitle="Most Popular"
      isMostPopular={true}
      title="Signature"
      price={
        <>
          ${PLANS.signature_yearly.per_month}
          <Text as="span" fontSize="20px" fontWeight="normal">
            /MONTH
          </Text>
        </>
      }
      priceSubtitle={`$${PLANS.signature_yearly.per_year} per year`}
      priceBulletPoints={[
        <Bullet text="Up to 7 stylists" iconName={'dot'} />,
        <Bullet text="150 color appointments per month" iconName={'dot'} />,
      ]}
      bulletPoints={[
        <Bullet text="Everything in Essentials, plus:" iconName={null} bold={true} />,
        <Bullet text="Live Support" />,
        canSeeScales && <Bullet text="1 Free Smart Scale" />
      ]}
      theme="midnight"
      isSelected={false}
      billingInterval={PLANS.signature_yearly.interval}
      planType={PLANS.signature_yearly.id}
      showButton={showButton}
    />
  )
}

export const SignatureMonthlySubscriptionCard = (props: { minH?: string; showButton?: boolean }) => {
  const { minH = '530px', showButton = true } = props
  const canSeeScales = UseCanSeeScales()
  return (
    <CheckoutSubscriptionCard
      minH={minH}
      topTitle="Most Popular"
      isMostPopular={true}
      title="Signature"
      price={
        <>
          ${PLANS.signature_monthly.per_month}
          <Text as="span" fontSize="20px" fontWeight="normal">
            /MONTH
          </Text>
        </>
      }
      priceSubtitle={`$${PLANS.signature_monthly.per_year} per year`}
      priceBulletPoints={[
        <Bullet text="Up to 7 stylists" iconName={'dot'} />,
        <Bullet text="150 color appointments per month" iconName={'dot'} />,
      ]}
      bulletPoints={[
        <Bullet text="Everything in Essentials, plus:" iconName={null} bold={true} />,
        <Bullet text="Live Support" />,
        canSeeScales && <Bullet text="1 Free Smart Scale" />
      ]}
      theme="midnight"
      isSelected={false}
      billingInterval={PLANS.signature_monthly.interval}
      planType={PLANS.signature_monthly.id}
      showButton={showButton}
    />
  )
}

export const LuxeYearlySubscriptionCard = (props: { minH?: string; showButton?: boolean }) => {
  const { minH = '530px', showButton = true } = props
  const canSeeScales = UseCanSeeScales()
  return (
    <CheckoutSubscriptionCard
      minH={minH}
      topTitle=""
      isMostPopular={false}
      title="Luxe"
      price={
        <>
          ${PLANS.luxe_yearly.per_month}
          <Text as="span" fontSize="20px" fontWeight="normal">
            /MONTH
          </Text>
        </>
      }
      priceSubtitle={`$${PLANS.luxe_yearly.per_year} per year`}
      priceBulletPoints={[
        <Bullet text="Unlimited stylists" iconName={'dot'} />,
        <Bullet text="Unlimited color appointments" iconName={'dot'} />,
      ]}
      bulletPoints={[
        <Bullet text="Everything in Signature, plus:" iconName={null} bold={true} />,
        <Bullet text="Implementation service" />,
        <Bullet text="Inventory and Orders" />,
        canSeeScales && <Bullet text="2 Free Smart Scales (2 total)" />,
      ]}
      theme="midnight"
      isSelected={false}
      billingInterval={PLANS.luxe_yearly.interval}
      planType={PLANS.luxe_yearly.id}
      showButton={showButton}
    />
  )
}
export const LuxeMonthlySubscriptionCard = (props: { minH?: string; showButton?: boolean }) => {
  const { minH = '530px', showButton = true } = props
  const canSeeScales = UseCanSeeScales()
  return (
    <CheckoutSubscriptionCard
      minH={minH}
      topTitle=""
      isMostPopular={false}
      title="Luxe"
      price={
        <>
          ${PLANS.luxe_monthly.per_month}
          <Text as="span" fontSize="20px" fontWeight="normal">
            /MONTH
          </Text>
        </>
      }
      priceSubtitle={`$${PLANS.luxe_monthly.per_year} per year`}
      priceBulletPoints={[
        <Bullet text="Unlimited stylists" iconName={'dot'} />,
        <Bullet text="Unlimited color appointments" iconName={'dot'} />,
      ]}
      bulletPoints={[
        <Bullet text="Everything in Signature, plus:" iconName={null} bold={true} />,
        <Bullet text="Implementation service" />,
        <Bullet text="Inventory and Orders" />,
        canSeeScales && <Bullet text="2 Free Smart Scales (2 total)" />,
      ]}
      theme="midnight"
      isSelected={false}
      billingInterval={PLANS.luxe_monthly.interval}
      planType={PLANS.luxe_monthly.id}
      showButton={showButton}
    />
  )
}